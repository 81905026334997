<template>
  <div>
    <v-overlay :model-value="loading || save" class="align-center justify-center">
      <v-progress-circular
        indeterminate
        size="64">
      </v-progress-circular>
    </v-overlay>
  </div>
    <div>
    <v-container grid-list-md text-xs-center fluid align="center">
      <v-card class= "pa-2" max-width="600" align ="left">
        <v-card-title>Adicionar Pergunta</v-card-title>
        <v-card-text>Inserir um exercício para a MathBlitz do Mathack <br> LaTex é suportados diretamente. HTML não é suportado.</v-card-text>
      
      <v-spacer></v-spacer>
      
      <v-card-title> Pergunta </v-card-title>
        <v-container fluid >
          <v-textarea solo name="question" v-model="question"></v-textarea>
        </v-container>

      <v-container fluid >
          <div class="dropdown">
            <button class="btn btn-light dropdown-toggle" 
            type="button" 
            id="dropdownMenuButton1" 
            data-bs-toggle="dropdown" 
            aria-expanded="false">
            Resposta Correcta: {{value_opt}}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" role="menu">
              <li v-for="option in options" :key="option">
                <a class="dropdown-item" @click="value_opt = option">{{option}}</a>
              </li>
            </ul>
          </div>
        </v-container>

      <v-container fluid >
          <v-card-title> Resposta A </v-card-title>
          <v-textarea solo name="questA" v-model="respA"></v-textarea>
        </v-container>
        <v-container fluid >
          <v-card-title> Resposta B </v-card-title>
          <v-textarea solo name="questB" v-model="respB"></v-textarea>
        </v-container>
        <v-container fluid >
          <v-card-title> Resposta C </v-card-title>
          <v-textarea solo name="questC" v-model="respC"></v-textarea>
        </v-container>
        <v-container fluid >
          <v-card-title> Resposta D </v-card-title>
          <v-textarea solo name="questD" v-model="respD"></v-textarea>
        </v-container>

        <v-card-title> Categoria</v-card-title>
        <v-container fluid >
          <div class="dropdown">
            <button class="btn btn-light dropdown-toggle" 
            type="button" 
            id="dropdownMenuCategory" 
            data-bs-toggle="dropdown" 
            aria-expanded="false">
            {{value_cat.name}}
            </button>
            <ul class="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuCategory" role="menu">
              <li v-for="cat in categories" :key="cat.id">
                <a class="dropdown-item" @click="value_cat = cat">{{cat.name}}</a>
              </li>
            </ul>
          </div>
        </v-container>

        <v-card-title> Dificuldade </v-card-title>

        <v-container fluid >
          <div class="dropdown" data-bs-spy="scroll">
            <button class="btn btn-light dropdown-toggle" 
            type="button" 
            id="dropdownMenuDif" 
            data-bs-toggle="dropdown" 
            aria-expanded="false"
            aria-haspopup="true">
            {{value_dif}}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuDif" role="menu">
              <li v-for="dif in difficulty_list" :key="dif">
                <a class="dropdown-item" @click="value_dif = dif">{{dif}}</a>
              </li>
            </ul>
          </div>
        </v-container>

        <v-container fluid >
          <SimilarityQuestion :question="question" :answerA="respA" :answerB="respB" :answerC="respC" :answerD="respD"></SimilarityQuestion>
        </v-container>

        <v-container fluid >
          <QuestionPreview :question="question" :answerA="respA" :answerB="respB" :answerC="respC" :answerD="respD"></QuestionPreview>
        </v-container>

        <v-container fluid >
          <v-btn @click="submit_exercise" color = "primary" elevation = "2"> Guardar </v-btn>
          <v-btn @click="go_exercises" color = "light" elevation = "2"> Voltar Exercícios </v-btn>
        </v-container>

      </v-card>
        
    </v-container>

    
  </div>
</template>

<script>
import { onMounted, ref, reactive} from "vue";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";
import QuestionPreview from "@/components/QuestionPreview.vue";
import SimilarityQuestion from "@/components/SimilarityQuestion.vue";

export default {
  name: "CreateQuestion",
  components: {
    QuestionPreview,
    SimilarityQuestion
  },
  data () {
    return {
      save: false,
      question: '',
      options: ['A', 'B', 'C', 'D'],
      value_opt: 'A',
      radioGroup: "a",
      respA: '',
      respB: '',
      respC: '',
      respD: '',
      value_cat: {'id': '-1', 'name': 'Selecione uma opção'},
      difficulty_list: ['1', '2', '3'],
      value_dif: 'Selecione uma opção',
      test_debug: false,
      preview: false
    }
  },
  setup() {
    const router = useRouter();
    let loading = ref(true);
    //const question = reactive([]);
    const answers = reactive([]);
    const categories = reactive([]);
    const store = useStore();
    const { cookies } = useCookies();
    let siteUrl = store.state.projectConfigs.siteUrl;
    onMounted(async () => {
      // /wp-json/jwt-auth/v1/token/validate
      try {
        //----- Authentication
        const response = await fetch(
          siteUrl + "/wp-json/jwt-auth/v1/create",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
          }
        );
        //-----------------
          if (response.status == 200) {
            await store.dispatch("setAuth", true);
          }
          else if (response.status == 403) {
            await store.dispatch("setAuth", false);
            await router.push("/");
          }
      } 
      catch (e) {
        await store.dispatch("setAuth", false);
        await router.push("/login");
      }

      const config_response = await fetch(
        store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/user-category-config",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + cookies.get("jwt"),
          },  
        }
      );

      const config_content = await config_response.json();

      if (config_response.status == 200){
        loading.value = false;
        for (let i=0; i < config_content.length; i++){
          let namev = config_content[i]['name'];
          categories.push({id: config_content[i]['idCategory'], name: namev});
        }
      }
      else if (config_response.status == 500) {
        console.log("Couldn't load the category configurations.");
      }
      
    });
    return {
      loading,
      answers,
      categories,
      siteUrl,
      cookies,
      router
    };
  },
  methods: {
    
    async submit_exercise(){
        this.save = true;
        
        let req_body = {
          "idExercise": -1,
          "question": this.question.toString(),
          "answer": this.value_opt.toString(),
          "a": this.respA.toString(),
          "b": this.respB.toString(),
          "c": this.respC.toString(),
          "d": this.respD.toString(),
          "difficulty": this.value_dif.toString(),
          "idCategory": this.value_cat.id.toString()
        };

        if (this.test_debug){
          this.save = false;
          return;
        }
        const config_response = await fetch(
            this.siteUrl + "/wp-json/jwt-auth/v1/insert-exercise",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.cookies.get("jwt"),
              },
              body: JSON.stringify(req_body)
            }
          );

          if (config_response.status == 200) {
            console.log("Exercised Saved"); // TO DELETE - Diagnose Purposes
            this.question = '';
            this.value_opt = 'A';
            this.respA = '';
            this.respB = '';
            this.respC = '';
            this.respD = '';
            this.value_dif = 'Selecione uma opção';
            this.value_cat = {'id': '-1', 'name': 'Selecione uma opção'};

          }
          else{
            console.log("Failed Saving");
          }

        this.save = false;
    },
    async go_exercises(){
      await this.router.push("/questions");
    }
  }
    
};
</script>
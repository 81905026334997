<template>
      <v-dialog v-model="dialog">
        
        <template v-slot:activator="{ attrs }">
          <v-btn
            color="primary"
            variant="outlined"
            elevation = "2"
            v-bind="attrs"
            @click.stop="load_scores(store); dialog = true;"
          >
          Ver Questões Similares
          </v-btn>
        </template>
        
        <div v-if="dialog"> 
        <v-container fluid align="center">
            <v-card class="pa-2" max-width="750" align="center">
              <v-row class = "pa-2">
                <v-expansion-panels>
                  <v-expansion-panel v-for="ex in exercises" v-bind:key="ex.id">
                    <v-expansion-panel-title @click="$render_mathjax()">
                      <b> ID: </b> {{ex.id}} | <b> Tema: </b> {{ex.categoryCode}} | <b> Similar: </b> {{ex.score}}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <b>Questão Similar: </b> <br>
                      <p>{{ex.question}}</p>
                      <b>Questão Nova: </b> <br>
                      <p>{{question}}</p>
                      <b>Resposta A Similar: </b> <br>
                      <p>{{ex.answerA}}</p>
                      <b>Resposta A Nova: </b> <br>
                      <p>{{answerA}}</p>
                      <b>Resposta B Similar: </b> <br>
                      <p>{{ex.answerB}}</p>
                      <b>Resposta B Nova: </b> <br>
                      <p>{{answerB}}</p>
                      <b>Resposta C Similar: </b> <br>
                      <p>{{ex.answerC}}</p>
                      <b>Resposta C Nova: </b> <br>
                      <p>{{answerC}}</p>
                      <b>Resposta D Similar: </b> <br>
                      <p>{{ex.answerD}}</p>
                      <b>Resposta D Nova: </b> <br>
                      <p>{{answerD}}</p>                      
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
        </div>
      </v-dialog>
</template>

<script>
import { reactive} from "vue";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";

export default {
  name: "SimilarityQuestion",
  props: {
    question: String,
    answerA: String,
    answerB: String,
    answerC: String,
    answerD: String,
  },
  data: () => ({
    dialog: false,
    exercises: reactive([]),
  }),
  setup(){
    const store = useStore();
    return { store};
  },
  methods: {
    /*******Change this to render mathjax as above */
    mathjax_loader(){
      window.MathJax = {
        tex: {
          inlineMath: [['$', '$'], ['\\(', '\\)'],['!!', '!!']]
        }
      };
      
      (function () {
        var script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js';
        script.async = true;
        document.head.appendChild(script);
      })();
    },
    $render_mathjax(){
          window.MathJax = {
        tex: {
          inlineMath: [['$', '$'], ['\\(', '\\)'],['!!', '!!']]
        }
      };
      
      (function () {
        var script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js';
        script.async = true;
        document.head.appendChild(script);
      })();
    },
    async load_scores(store){
      //const store = useStore();
      const { cookies } = useCookies();
    //store.dispatch('setCurrentScreen', 'Editar Exercícios');
      let siteUrl = store.state.projectConfigs.siteUrl;
      this.exercises = reactive([]);
      try {
          let similar_req_body = {
            "question": this.question
          };

          const similar_response = await fetch(
            siteUrl + "/wp-json/jwt-auth/v1/get-similar-score",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + cookies.get("jwt"),
              },
              body: JSON.stringify(similar_req_body)  
            }
          );
          const similar_content = await similar_response.json();

          if (similar_response.status == 200){
            //Now we got the exercise response we need the exercise info
            for (let i=0; i < 5; i++){
              let idExercise = similar_content[i]['idExercise'];
              let score = similar_content[i]['score'];

              const exercise_response = await fetch(
                siteUrl + "/wp-json/jwt-auth/v1/get-exercise-id",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + cookies.get("jwt"),
                  },
                  body: JSON.stringify({"id": idExercise})  
                }
              );

                if (exercise_response.status == 200) {
                  const exs_content = await exercise_response.json();
                  //loading.value = false;
                  
                  /* -------------------------------------------------------------------------- */
                  let question = exs_content[0]['question'];
                  let respA = exs_content[0]['a'];
                  let respB = exs_content[0]['b'];
                  let respC = exs_content[0]['c'];
                  let respD = exs_content[0]['d'];
                  let categoryCode = exs_content[0]['description'];
                  let ans = exs_content[0]['answer'];

                  this.exercises.push({id: idExercise, question: question, categoryCode: categoryCode, correct: ans, answerA: respA, answerB: respB, answerC: respC, answerD: respD, score: score});
                }
                else{
                  console.log('failed');
                }
            }    
          }
          else{
            console.log('failed-mid');
          }
     
      } catch (e) {
        console.log('failed-big');
      }

    }
  },
};
</script>

<style>
</style>
import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import CategoryUserConfig from '@/views/CategoryUserConfig.vue'
import RapidRound from '@/views/RapidRound.vue'
import CreateQuestion from '@/views/CreateQuestion.vue'
import CreateExamQuestion from '@/views/CreateExamQuestion.vue'
import LoadQuestions from '@/views/LoadQuestions.vue'
import EditQuestion from '@/views/EditQuestion.vue'
import Register from '@/views/Register.vue'
import Validate from '@/views/Validate.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'
import PasswordReset from '@/views/PasswordReset.vue'
import TrialSurvey from '@/views/TrialSurvey.vue'
import ClarificationView from '@/views/ClarificationView.vue'
import ContactUs from '@/views/ContactUs.vue'
import ExamConstructedResponse from '@/views/ExamConstructedResponse.vue'
import ShopMathackSuper from '@/views/ShopMathackSuper.vue'

const routes = [

  {path: '/', component: Home, meta: {title: "Mathack"}},
  {path: '/login', component: Login, meta: {title: "Entrar no Mathack"}},
  {path: '/config', component: CategoryUserConfig, meta: {title: "Configurações - Mathack"}},
  {path: '/mathblitz', component: RapidRound, meta: {title: "MathBlitz - Mathack"}},
  {path: '/create', component: CreateQuestion},
  {path: '/create-exam', component: CreateExamQuestion},
  {path: '/questions', component: LoadQuestions},
  {path: '/edit/:id', name: "edit", component: EditQuestion, props: true},
  {path: '/register', name: "register", component: Register, meta: {title: "Criar Conta no Mathack"}},
  {path: '/validate', name: "validate", component: Validate, meta: {title: "Validar Conta no Mathack"}},
  {path: '/forgotpassword', name: "forgotpassword", component: ForgotPassword, meta: {title: "Esqueci-me da Password - Mathack"}},
  {path: '/passwordreset', name: "passwordreset", component: PasswordReset, meta: {title: "Gerar nova Password - Mathack"}},
  {path: '/trialsurvey', name: "trialsurvey", component: TrialSurvey, meta: {title: "Dá a tua opinião - Mathack"}},
  {path: '/clarificationview', name: "clarificationview", component: ClarificationView, meta: {title: "Ajuda Exercício - Mathack"}},
  {path: '/contactus', name: "contactus", component: ContactUs, meta: {title: "Fala Connosco - Mathack"}},
  {path: '/examconstructedresponse', name: "examconstructedresponse", component: ExamConstructedResponse, meta: {title: "Pregunta de Exame - Mathack"}},
  {path: '/shopmathacksuper', name: "shopmathacksuper", component: ShopMathackSuper, meta: {title: "Upgrade Mathack Super"}}
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
})

export default router

<template>
      <v-dialog v-model="dialog">
        
        <template v-slot:activator="{ attrs }">
          <v-btn
            color="primary"
            variant="outlined"
            elevation = "2"
            v-bind="attrs"
            @click.stop="dialog = true; mathjax_loader()"
          >
          Pré-Visualizar Exercício
          </v-btn>
        </template>
        
        <div>
        <v-container fluid align="center">
            <v-card max-width="600" align="center">
              <v-card-text>{{ question }}</v-card-text>
              <v-row>
                <v-container class="answer_button">
                  <v-btn block min-height="50px" >
                    <v-card-text> {{ answerA }} </v-card-text>
                  </v-btn>
                </v-container>
                <v-container class="answer_button">
                  <v-btn block min-height="50px" >
                    <v-card-text> {{ answerB }} </v-card-text>
                  </v-btn>
                </v-container>
                <v-container class="answer_button">
                  <v-btn block min-height="50px" >
                    <v-card-text> {{ answerC }} </v-card-text>
                  </v-btn>
                </v-container>
                <v-container class="answer_button">
                  <v-btn block min-height="50px">
                    <v-card-text> {{ answerD }} </v-card-text>
                  </v-btn>
                </v-container>
              </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
        </div>
      </v-dialog>
</template>

<script>
export default {
  name: "QuestionPreview",
  props: {
    question: String,
    answerA: String,
    answerB: String,
    answerC: String,
    answerD: String,
  },
  data: () => ({
    dialog: false,
    setpOne: false,
    stepTwo: false
  }),
  methods: {
    mathjax_loader(){
      window.MathJax = {
        tex: {
          inlineMath: [['$', '$'], ['\\(', '\\)'],['!!', '!!']]
        }
      };
      
      (function () {
        var script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js';
        script.async = true;
        document.head.appendChild(script);
      })();
    }
  },
};
</script>

<style>
</style>
<template>
    <ExamSearch  v-if="currentExamData.ongoingExerciseId===null && (currentExamData.ongoingSession===null || currentExamData.ongoingSession ===false) && showMathClassicMultiChoiceAnswerReview===null" @selected-exercise-value="selectedExamExercise" />
    <ExamQuestionImage v-if="currentExamData.ongoingExerciseId!=null && showExamExerciseAnswer===null && currentExamData.ongoingExerciseMultiChoiceDynamic !== true && showMathClassicMultiChoiceAnswerReview===null" :questionData="currentExamData" @goto-exercise-answer="gotoExamExerciseAnswer" @new-mathclassic="newMathClassic"/>
    <MathClassicMultiChoice v-if="currentExamData.ongoingExerciseId!=null && showExamExerciseAnswer===null && currentExamData.ongoingExerciseMultiChoiceDynamic === true && showMathClassicMultiChoiceAnswerReview===null" :questionData="currentExamData" @new-mathclassic="newMathClassic" @mathclassic-multi-choice-answer-review="mathclassicMultiChoiceAnswerReview"/>
    <MathClassicMultiChoiceAnswerReview v-if="showMathClassicMultiChoiceAnswerReview===true" :answerReviewnData="mathClassicMultiChoiceAnswerReview" @new-mathclassic="newMathClassic"/>
    <ExamAnswerReview v-if="showExamExerciseAnswer===true" :questionData="currentExamData" :time="exerciseResolutionTime" :isValidatedAnswer="isValidatedAnswer" @new-mathclassic="newMathClassic"/>
    <!-- /!\ - Change questionData above to recieve the necessary parameters to POST the close of the exercise -->
    <!-- Create Panels for Multiple Choice similar to Rapid Round -->
</template>

<script>
import { onMounted, ref, reactive } from "vue";
import { useStore } from "vuex";
import { useCookies } from "vue3-cookies";

import ExamSearch from "@/components/ExamSearch.vue"
import ExamQuestionImage from "@/components/ExamQuestionImage.vue";
import ExamAnswerReview from '@/components/ExamAnswerReview.vue';
import MathClassicMultiChoice from '@/components/MathClassicMultiChoice.vue';
import MathClassicMultiChoiceAnswerReview from '@/components/MathClassicMultiChoiceAnswerReview.vue';
export default {
  name: "ExamConstructedResponse",
  components: {
    ExamSearch,
    ExamQuestionImage,
    ExamAnswerReview,
    MathClassicMultiChoice,
    MathClassicMultiChoiceAnswerReview
  },
  data() {
    return {
      selectedExamExerciseId: null,
      showExamExerciseAnswer: null,
      showMathClassicMultiChoiceAnswerReview: null,
      exerciseResolutionTime: null,
      isValidatedAnswer: null,
    };
  },
  setup() {
    const currentExamData = reactive({
      ongoingSession: null,
      ongoingSessionId: null,
      ongoingExerciseId: null,
      ongoingInitExamExerciseTime: null,
      ongoingExerciseMultiChoiceDynamic: null,
      category: null,
      categorysec: null,
    });

    const mathClassicMultiChoiceAnswerReview = reactive({
      statusCode: null,
      message: null,
      questionId: null,
      choosenAnswer: null,
      correctAnswer: null,
      choosenOption: null,
      evaluation: null,
      timeDurationSec: null,
    });

    const loading = ref(true);
    const store = useStore();
    const { cookies } = useCookies();

    onMounted(async () => {
      try {
        const response = await fetch(
          store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/examquestion",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
          }
        );

        const responseContent = await response.json ();

        if (response.status == 200) {
          await store.dispatch("setAuth", true);
          
          //console.log(responseContent); // TO DELETE - Diagnose Purposes
          //console.log(responseContent["mathclassic-home"]["ongoingSession"]); // TO DELETE - Diagnose Purposes
          currentExamData.ongoingSession = responseContent["mathclassic-home"]["ongoingSession"];
          //console.log("Ongoing Session: " + currentExamData.ongoingSession);  // TO DELETE - Diagnose Purposes
          if(currentExamData.ongoingSession == true)
          {
            currentExamData.ongoingSessionId = responseContent["mathclassic-home"]["ongoingSessionId"];
            currentExamData.ongoingExerciseId = responseContent["mathclassic-home"]["ongoingExerciseId"];
            currentExamData.ongoingInitExamExerciseTime = responseContent["mathclassic-home"]["initExamExerciseTime"];
            currentExamData.ongoingExerciseMultiChoiceDynamic = responseContent["mathclassic-home"]["multiplechoiceDnamic"]; 
            //console.log("Ongoing Exercise Data on Main: " + currentExamData); // TO DELETE - Diagnose Purposes
            //console.log("Ongoing Exercise Data on Main: " + currentExamData.ongoingSessionId); // TO DELETE - Diagnose Purposes
            //console.log("Ongoing Exercise Data on Main: " + currentExamData.ongoingExerciseId); // TO DELETE - Diagnose Purposes
            //console.log("Ongoing Exercise Data on Main: " + currentExamData.ongoingInitExamExerciseTime); // TO DELETE - Diagnose Purposes
            //console.log("Ongoing Exercise Data on Main (Multiple Choice Dynamic): " + currentExamData.ongoingExerciseMultiChoiceDynamic); // TO DELETE - Diagnose Purposes
            //console.log(this.showExamExerciseAnswer); // TO DELETE - Diagnose Purposes
          }         
          loading.value = false;
        }

      } catch (e) {
        await store.dispatch("setAuth", false);
      }
    });

    return {
      currentExamData,
      mathClassicMultiChoiceAnswerReview
    };
  },
  methods: {
    selectedExamExercise(value) {
      // Do something with the value
      // TODO - /!\ Check if selected exercise is multiplechoice dynamic:
      // Two Options: Ask here for that info, or come from search exercise component
      //console.log("Selected Exercise Data: " + value);  // TO DELETE - Diagnose Purposes
      //console.log("Selected Exercise Category: " + value.category);  // TO DELETE - Diagnose Purposes
      //console.log("Selected Exercise Category: " + value.categorysec);  // TO DELETE - Diagnose Purposes
      //console.log("Selected Exercise: " + value.id);  // TO DELETE - Diagnose Purposes
      //console.log("Multiple Choice: " + value.multiplechoice);  // TO DELETE - Diagnose Purposes
      //console.log("Multiple Choice Dynamic: " + value.multiplechoiceDnamic);  // TO DELETE - Diagnose Purposes
      

      // Set variable for Multiple Choice Dynamic
      // /!\ - Check other places where ongoingExerciseMultiChoiceDynamic must be set
      this.currentExamData.ongoingExerciseMultiChoiceDynamic = value.multiplechoiceDnamic;
      // Set variable for Exercise Id
      this.currentExamData.ongoingExerciseId = value.id;
      //this.selectedExamExerciseId = value;

      // Set variable for Exercise Category
      this.currentExamData.category = value.category;
      // Set variable for Exercise Secondary Category
      this.currentExamData.categorysec = value.categorysec;

      //console.log(this.currentExamData.ongoingExerciseMultiChoiceDynamic); // TO DELETE - Diagnose Purposes
      //console.log(this.currentExamData.ongoingExerciseId); // TO DELETE - Diagnose Purposes
      //console.log(this.showExamExerciseAnswer); // TO DELETE - Diagnose Purposes
    },
    gotoExamExerciseAnswer(value) {
      //console.log("gotoExamExerciseAnswer");
      // Recieve here the details of the exercise and session
      //this.showExamExerciseAnswer = value;
      
      //console.log("Object: " + value);  // TO DELETE - Diagnose Purposes
      // Bug fix in the backend the creation of new exercise sessions and ids at 0
      //console.log("gotoExamExerciseAnswer returned data " + value["mathclassiccessionId"]);  // TO DELETE - Diagnose Purposes
      //console.log("gotoExamExerciseAnswer returned data " + value["initExamExerciseTime"]);  // TO DELETE - Diagnose Purposes
      //console.log("Ongoing Exercise Session: " + this.currentExamData.ongoingSession);  // TO DELETE - Diagnose Purposes
      //console.log("Ongoing Exercise Session ID: " + this.currentExamData.ongoingExerciseId); // TO DELETE - Diagnose Purposes
      //console.log("Ongoing Exercise Session ID: " + this.currentExamData.ongoingSessionId); // TO DELETE - Diagnose Purposes
      //console.log("Ongoing Exercise Session init Time: " + this.currentExamData.ongoingInitExamExerciseTime); // TO DELETE - Diagnose Purposes
      this.currentExamData.ongoingInitExamExerciseTime = value["initExamExerciseTime"];
      //console.log("Ongoing Exercise Session init Time: " + this.currentExamData.ongoingInitExamExerciseTime); // TO DELETE - Diagnose Purposes

      if(this.currentExamData.ongoingSession == false)
      {
        this.currentExamData.ongoingSessionId = value["mathclassiccessionId"];
        this.currentExamData.ongoingInitExamExerciseTime = value["initExamExerciseTime"];
        //console.log("Ongoing Exercise Data: " + this.currentExamData.value);
      }

      // Calculate seconds of the exercise: since initExerciseTime until now
      //this.exerciseResolutionTime = value["initExamExerciseTime"]; //- Math.floor(Date.now() / 1000);
      // Assuming value["initExamExerciseTime"] is in the format "YYYY-MM-DD HH:mm:ss"
      let startTime = new Date(value["initExamExerciseTime"].replace(' ', 'T') + 'Z');
      let currentTime = new Date();

      // Calculate the difference in milliseconds
      let differenceInMilliseconds = currentTime - startTime;

      // Convert the difference to seconds
      this.exerciseResolutionTime = Math.floor(differenceInMilliseconds / 1000);

      this.showExamExerciseAnswer = true;  
    },
    newMathClassic() {
      //console.log("New MathClassic: " + this.selectedExamExerciseId + " | " + this.currentExamData.ongoingSession + " | " + this.showExamExerciseAnswer); // TO DELETE - Diagnose Purposes

      // Reset all MathClassic UI flow control variables
      this.showExamExerciseAnswer = null;
      this.currentExamData.ongoingSession = null;
      this.currentExamData.ongoingSessionId = null;
      this.currentExamData.ongoingExerciseId = null;
      this.currentExamData.ongoingInitExamExerciseTime = null;

      this.mathClassicMultiChoiceAnswerReview.statusCode = null;
      this.mathClassicMultiChoiceAnswerReview.message = null;
      this.mathClassicMultiChoiceAnswerReview.questionId = null;
      this.mathClassicMultiChoiceAnswerReview.choosenAnswer = null;
      this.mathClassicMultiChoiceAnswerReview.correctAnswer = null;
      this.mathClassicMultiChoiceAnswerReview.choosenOption = null;
      this.mathClassicMultiChoiceAnswerReview.evaluation = null;
      this.mathClassicMultiChoiceAnswerReview.timeDurationSec = null;

      this.exerciseResolutionTime = null;
      this.isValidatedAnswer = null;

      
      this.ongoingExerciseMultiChoiceDynamic = null;
      this.showMathClassicMultiChoiceAnswerReview = null;

      

      this.checkOngoingExamExercise();
    },
    async checkOngoingExamExercise() {
      
      const { cookies } = useCookies();

      this.loading = true;

      // Reset all MathClassic UI flow control variables
      this.selectedExamExerciseId = null;
      this.showExamExerciseAnswer = null;
      this.showMathClassicMultiChoiceAnswerReview = null;
      
      this.currentExamData.ongoingSession = null;
      this.currentExamData.ongoingSessionId = null;
      this.currentExamData.ongoingExerciseId = null;
      this.currentExamData.ongoingInitExamExerciseTime = null;
      this.currentExamData.ongoingExerciseMultiChoiceDynamic = null;

      this.mathClassicMultiChoiceAnswerReview.statusCode = null;
      this.mathClassicMultiChoiceAnswerReview.message = null;
      this.mathClassicMultiChoiceAnswerReview.questionId = null;
      this.mathClassicMultiChoiceAnswerReview.choosenAnswer = null;
      this.mathClassicMultiChoiceAnswerReview.correctAnswer = null;
      this.mathClassicMultiChoiceAnswerReview.choosenOption = null;
      this.mathClassicMultiChoiceAnswerReview.evaluation = null;
      this.mathClassicMultiChoiceAnswerReview.timeDurationSec = null;

      this.exerciseResolutionTime = null;
      this.isValidatedAnswer = null;

      try {
        const response = await fetch(
          this.$store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/examquestion",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
          }
        );

        const responseContent = await response.json ();

        if (response.status == 200) {
          await this.$store.dispatch("setAuth", true);
          
          //console.log(responseContent); // TO DELETE - Diagnose Purposes
          //console.log(responseContent["mathclassic-home"]["ongoingSession"]); // TO DELETE - Diagnose Purposes
          this.currentExamData.ongoingSession = responseContent["mathclassic-home"]["ongoingSession"];
          //console.log("Ongoing Session: " + currentExamData.ongoingSession);  // TO DELETE - Diagnose Purposes
          // If there is an ongoing session, set the MathClassic UI flow control variables with the corresponging values
          if(this.currentExamData.ongoingSession == true)
          {
            this.currentExamData.ongoingSessionId = responseContent["mathclassic-home"]["ongoingSessionId"];
            this.currentExamData.ongoingExerciseId = responseContent["mathclassic-home"]["ongoingExerciseId"];
            this.currentExamData.ongoingInitExamExerciseTime = responseContent["mathclassic-home"]["initExamExerciseTime"];
            this.currentExamData.ongoingExerciseMultiChoiceDynamic = responseContent["mathclassic-home"]["multiplechoiceDnamic"];  
            //console.log("Ongoing Exercise: " + this.currentExamData); // TO DELETE - Diagnose Purposes
          }         
          this.loading.value = false;
        }

      } catch (e) {
        await this.$store.dispatch("setAuth", false);
      }
      
    },
    mathclassicMultiChoiceAnswerReview(payload){
      const { answerReviewValue, mathclassiccessionId } = payload;

      //console.log("mathclassicMultiChoiceAnswerReview: " + answerReviewValue.statusCode); // TO DELETE - Diagnose Purposes
      this.mathClassicMultiChoiceAnswerReview.statusCode = answerReviewValue.statusCode;
      this.mathClassicMultiChoiceAnswerReview.message = answerReviewValue.message;
      this.mathClassicMultiChoiceAnswerReview.questionId = answerReviewValue.questionId;
      this.mathClassicMultiChoiceAnswerReview.choosenAnswer = answerReviewValue.choosenAnswer;
      this.mathClassicMultiChoiceAnswerReview.correctAnswer = answerReviewValue.correctAnswer;
      this.mathClassicMultiChoiceAnswerReview.choosenOption = answerReviewValue.choosenOption;
      this.mathClassicMultiChoiceAnswerReview.evaluation = answerReviewValue.evaluation;
      this.mathClassicMultiChoiceAnswerReview.timeDurationSec = answerReviewValue.timeDurationSec;
      this.exerciseResolutionTime = answerReviewValue.timeDurationSec;
      this.isValidatedAnswer = true;

      // Set variable for Exercise Id since it is null when is a new exercise multiple choice dynamic
      this.currentExamData.ongoingSessionId = mathclassiccessionId;

      // Set variable for Multiple Choice Dynamic to show the answer review view
      this.showMathClassicMultiChoiceAnswerReview = true;
      this.showExamExerciseAnswer = true;  
    }
  },  
};
</script>

<style scoped>

</style>
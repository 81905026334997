<template>
    <v-chart class="chart" :option="option" autoresize/> 

    <v-container grid-list-md text-xs-center fluid align="center">         
        <v-table>
            <thead>
                <tr>
                    <th class="text-left">
                        Nome
                    </th>
                    <th class="text-left">
                        Descrição
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="item in radarLegendData"
                    :key="item.name"
                    >
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                </tr>
            </tbody>
        </v-table>
    </v-container>


</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { RadarChart } from "echarts/charts";
import {   TitleComponent, TooltipComponent, GridComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";

use([CanvasRenderer, RadarChart, GridComponent, TitleComponent, TooltipComponent]);

export default defineComponent({
    name: "StudentGradeChart",
    props: ['radarChartData'],
    components: {
        VChart,
    },
    provide: {
        [THEME_KEY]: "light",
    },
    setup(props) {  // Include props argument in Setup to be able to acces the props value inside setup
        let radarLegendData = [];
        let radarIndicators = [];
        
        // Add Grades to Radar Names Labels
        for (let i = 0; i < props.radarChartData['indicator'].length; i++) {
            let auxArray = '{"name": "' + props.radarChartData['indicator'][i]['name'] + '\\n' + props.radarChartData['value'][i] + '", "max":' + props.radarChartData['indicator'][i]['max'] + '}';
            auxArray  = JSON.parse(auxArray);
            radarIndicators.push(auxArray);
        }
        
        // Create the Array with legend information to show below the radar chart
        radarLegendData = [];
        for (let i = 0; i < props.radarChartData['indicator'].length; i++){
            let auxArray = ['name', 'description'];
            auxArray['name'] = '' + props.radarChartData['indicator'][i]['name'];
            auxArray['description'] = String(props.radarChartData['legend'][i]);
            radarLegendData.push(auxArray);
        }

        const option = ref({
            //tooltip: {},
            // legend: {
            //     data: ['Allocated Budget', 'Actual Spending']
            // },
            radar: {
                // shape: 'circle',
                indicator: radarIndicators
            },
            series: [
                {
                name: 'Budget vs spending',
                type: 'radar',
                areaStyle: {},  // Chart filled
                data: [
                    {
                    value: props.radarChartData['value'],
                    name: 'Notas Categorias'
                    }
                ]
                }
            ]
        });
        return { 
            option,
            radarLegendData 
        };
    },
});

</script>

<style scoped>
.chart {
  height: 400px;
}
</style>

<template>
  <div v-if="data.loading === false">
    <div v-if="!data.isAnswerReview && !data.isSessionReview && !data.isConfigRequired">
      <v-container grid-list-md text-xs-center fluid align="center">
      <v-card max-width="600" align="left">
        <div class="progress">
          <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" v-bind:style="{width: data.countdownPerc + '%'}">
            <span class="sr-only">{{data.countdown}} seg.</span>
          </div>
        </div> 
        <v-card-text>{{ data.question }}</v-card-text>
      </v-card>
    </v-container>
    <v-container grid-list-md text-xs-center fluid align="center">
      <v-card class="my-2" max-width="600" align="left">
        <v-card-title>
          <v-row>
            <v-container class="mt-2">
              <v-btn @click="submitFirstAnswer" block :min-height="data.buttonHeight">
                <span id='firstAnswerText' style="hyphens: none;">{{data.firtsAnswer}}</span>
              </v-btn>
            </v-container>
            <v-container>
              <v-btn @click="submitSecondAnswer" block :min-height="data.buttonHeight">
                <span id='secondAnswerText' style="hyphens: none;">{{data.secondAnswer}}</span>
              </v-btn>
            </v-container>
            <v-container>
              <v-btn @click="submitThirdAnswer" block :min-height="data.buttonHeight">
                <span id='thirdAnswerText' style="hyphens: none;">{{data.thirdAnswer}}</span>
              </v-btn>
            </v-container>
            <v-container class = "mb-2">
              <v-btn @click="submitForthAnswer" block :min-height="data.buttonHeight">
              <span id='forthAnswerText' style="hyphens: none;">{{data.forthAnswer}}</span>
              </v-btn>
            </v-container>
          </v-row>
        </v-card-title>
      </v-card>
    </v-container>
    <v-container grid-list-md text-xs-center fluid align="center">
      <v-btn @click="submitSkipQuestion" color="#D2D2D2" elevation="2">
        Saltar Pergunta <v-icon>mdi-skip-next</v-icon></v-btn
      >
    </v-container>
    </div>
    <!-- SPLIT BY COMPONENTS -->
    <!-- Answer Review -->
    <div v-if="data.isAnswerReview && !data.isSessionReview && !data.isConfigRequired">
      <v-container grid-list-md text-xs-center fluid align="center">
      <v-card max-width="600" align="left">
        <v-card-text>
          {{data.question}}
        </v-card-text>
        <div class="container">
          <table class="table">
            <thead>
              <!-- Table Head is only used to create black line on top of first row -->
              <tr><th colspan="2">Solução do Exercício</th></tr>
            </thead>
            <tbody>
              <tr>
                <td style="vertical-align: middle">Resposta Escolhida</td>
                <td style="vertical-align: middle">{{ data.answerReviewChoosenAnswer }}</td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Resposta Correta</td>
                <td style="vertical-align: middle">{{ data.answerReviewCorrectAnswer }}</td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Avaliação</td>
                <td style="vertical-align: middle"><v-btn v-if="data.answerReviewEvaluation" color="success" elevation="0">Correto</v-btn>
                                                    <v-btn v-if="!data.answerReviewEvaluation" color="error" elevation="0">Incorreto</v-btn></td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Tempo de Resposta:</td>
                <td style="vertical-align: middle">{{ data.answerReviewTimeDuration }} segundos</td>
              </tr>
              <tr>
                <td style="vertical-align: middle" colspan="2">O que achaste deste exercício?</td>
              </tr>
              <tr>
                <th colspan="2"><Clarify :questionId="data.answerReviewQuestionId"/></th>
              </tr>
              <tr>
                <td style="vertical-align: middle" colspan="2" class="text-center">
                  <v-btn @click="submitQuestionLike" v-bind:color="data.likeExercise == true?'primary':'#D2D2D2'" elevation="2" class="mx-4">
                    <v-icon>mdi-thumb-up</v-icon>
                  </v-btn>
                  <v-btn @click="submitQuestionDislike" v-bind:color="data.disLikeExercise == true?'primary':'#D2D2D2'" elevation="2" class="mx-4">
                    <v-icon>mdi-thumb-down</v-icon>
                  </v-btn>
                  <!-- <v-rating
                    v-model="rating"
                    background-color="indigo lighten-3"
                    color="indigo"
                    large
                  ></v-rating> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </v-container>   
      <v-container grid-list-md text-xs-center fluid align="center">
      <v-btn @click="nextQuestion" color="primary" elevation="2">
        Próxima Pergunta <v-icon>mdi-skip-next</v-icon></v-btn
      >
    </v-container>
    </div>

    <!-- Session Review -->
    <div v-if="!data.isAnswerReview && data.isSessionReview && !data.isConfigRequired">
      <v-container grid-list-md text-xs-center fluid align="center">
      <v-card max-width="600" align="left">
        <v-card-title>Resumo do MathBlitz</v-card-title>
        <v-card-text
          > {{ data.endOfSessionMessage }}</v-card-text
        >
      <div style="text-align: center">
        <v-btn @click="nextQuestion" color="primary" elevation="2">
        Novo MathBlitz <v-icon>mdi-skip-next</v-icon></v-btn
      ><p></p>
      </div>
        <div class="container">
          <table class="table">
            <thead>
              <!-- Table Head is only used to create black line on top of first row -->
              <tr></tr>
            </thead>
            <tbody>
              <tr>
                <td style="vertical-align: middle">Total de Exercicios</td>
                <td style="vertical-align: middle">{{ data.totalExercices }}</td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Previsão da nota de Exame</td>
                <td style="vertical-align: middle" v-if="data.gradeScore != null">{{ data.gradeScore }} valores</td>
                <td style="vertical-align: middle" v-if="data.gradeScore === null">Indisponivel</td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Tempo de Resposta Médio</td>
                <td style="vertical-align: middle">{{ data.answerTimeSecAvg }} seg.</td>
              </tr>
              <tr>
              </tr>
            </tbody>
          </table>
          <!-- V IF TOTAL EXERCISES IS 0, DON'T SHOW -->
          <RapidRoundAnswerChart :chartdata = "data.chartData"/>
        </div>
      </v-card>
    </v-container>
    </div>

    <!-- Config is required -->
    <div v-if="!data.isAnswerReview && !data.isSessionReview && data.isConfigRequired">
      <v-container grid-list-md text-xs-center fluid align="center">
      <v-card max-width="600" align="left">
        <v-card-title>Ainda não escolheste as matérias que vais estudar</v-card-title>
        <v-card-text> 
          Para praticares ainda melhor, deixamos-te escolher os temas das perguntas que vais fazer. 
          Sempre que quiseres alterar mais tarde é só escolheres a opção <b>Configurações</b> do menu ao lado.
        </v-card-text>
      <div style="text-align: center" class="mb-6 mt-2">
        <v-btn icon @click="$router.push('/config')" color="primary" elevation="2">
        <v-icon>mdi-tools</v-icon></v-btn>
      </div>
      </v-card>
    </v-container>
    </div>
  </div>

  <v-overlay :model-value="data.loading" class="align-center justify-center">
    <v-progress-circular indeterminate size="64"> </v-progress-circular>
  </v-overlay>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import RapidRoundAnswerChart from '@/components/RapidRoundAnswerChart.vue';
import Clarify from '@/components/Clarify.vue'

export default {
  name: "Rapid Round",
  components: {
    RapidRoundAnswerChart,
    Clarify
  },
  setup() {
    const data = reactive({
      loading: true,
      buttonHeight: "50px",
      rapidRoundRequestStatusCode: "",
      rapidRoundRequestStatusMsg: "",
      isSessionReview: false,  
      sessionReviewMsg: "",  
      questionId: "",
      question: "",
      firtsAnswer: "",
      firtsAnswerKey: "",
      secondAnswer: "",
      secondAnswerKey: "",
      thirdAnswer: "",
      thirdAnswerKey: "",
      forthAnswer: "",
      forthAnswerKey: "",
      initExerciseTime: "",
      remainingExerciseTime: "",
      timemax: "",
      totalSessionExercises: "",
      maxsessionexercises: "",
      sessionId: '',
      isAnswerReview: false,
      answerReviewStatusCode: null,
      answerReviewMsg: null,
      answerReviewQuestionId: null,
      answerReviewChoosenAnswer: null,
      answerReviewCorrectAnswer: null,
      answerReviewEvaluation: null,
      answerReviewTimeDuration: null,
      endOfSession: false,
			endOfSessionMessage: null, 
			totalExercices: null,
			totalCorrect: null,
			totalIncorrect: null,
			totalTimeout: null,
			totalSkipped: null,
			answerTimeSecAvg: null,
			tendency: null,
			gradeScore: null,
      chartData: [
        { value: 0, name: 'Corretos' },
        { value: 0, name: 'Incorretos' },
        { value: 0, name: 'Tempo Excedido' },
        { value: 0, name: 'Saltados' }
      ],
      countdown: 60,
      countdownPerc: 100,
      likeExercise: false,
      disLikeExercise: false,
      mathjaxRender: false,
      isConfigRequired: false,
    });

    const { cookies } = useCookies();
    const store = useStore();
    const router = useRouter();

    const siteUrl = store.state.projectConfigs.siteUrl;

    store.dispatch("setCurrentScreen", "MathBlitz");

    onMounted(async () => {
      try {
        const response = await fetch(
          siteUrl + "/wp-json/jwt-auth/v1/rapidroundquestion",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
          }
        );
        const perm_response = await fetch(
          siteUrl + "/wp-json/jwt-auth/v1/mathblitz",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
          }
        );

        const content = await response.json();

        if (response.status == 200 && perm_response.status == 200) {
          await store.dispatch("setAuth", true);
          data.loading = false;
          data.mathjaxRender = true;
          data.buttonHeightChecked = false;
          //-----|STATUS|-----
          data.rapidRoundRequestStatusCode = content["rapidRoundQuestionData"]["status"]["statusCode"];
          data.rapidRoundRequestStatusMsg = content["rapidRoundQuestionData"]["status"]["message"];
          //-----|SESSION|-----
          data.sessionId = content["rapidRoundQuestionData"]["studysessionsummary"]["sessionId"];
          data.isSessionReview = content["rapidRoundQuestionData"]["studysessionsummary"]["finalized"];
          data.sessionReviewMsg = content["rapidRoundQuestionData"]["studysessionsummary"]["sessionMessage"];
          data.endOfSession = content["rapidRoundQuestionData"]["sessionReview"]["endOfSession"];
          data.endOfSessionMessage = content["rapidRoundQuestionData"]["sessionReview"]["endOfSessionMessage"];
          data.totalExercices = content["rapidRoundQuestionData"]["sessionReview"]["totalExercices"];
          data.totalCorrect = content["rapidRoundQuestionData"]["sessionReview"]["totalCorrect"];
          data.totalIncorrect = content["rapidRoundQuestionData"]["sessionReview"]["totalIncorrect"];
          data.totalTimeout = content["rapidRoundQuestionData"]["sessionReview"]["totalTimeout"];
          data.totalSkipped = content["rapidRoundQuestionData"]["sessionReview"]["totalSkipped"];
          data.answerTimeSecAvg = content["rapidRoundQuestionData"]["sessionReview"]["answerTimeSecAvg"];
          data.tendency = content["rapidRoundQuestionData"]["sessionReview"]["tendency"];
          data.gradeScore = content["rapidRoundQuestionData"]["sessionReview"]["gradeScore"];
          if (data.totalExercices != null && data.totalExercices > 0)
          {
            data.chartData = [
            { value: data.totalCorrect, name: 'Corretos' },
            { value: data.totalIncorrect - data.totalSkipped, name: 'Incorretos' },
            { value: data.totalTimeout, name: 'Tempo Excedido' },
            { value: data.totalSkipped, name: 'Saltados' }
            ];
          }          

          //-----|EXERCISE|-----
          data.questionId = content["rapidRoundQuestionData"]["rapidroundExercise"]["questionId"];
          data.question = content["rapidRoundQuestionData"]["rapidroundExercise"]["question"];
          data.firtsAnswer = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][0]["answer"];
          data.firtsAnswerKey = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][0]["key"];
          data.secondAnswer = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][1]["answer"];
          data.secondAnswerKey = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][1]["key"];
          data.thirdAnswer = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][2]["answer"];
          data.thirdAnswerKey = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][2]["key"];
          data.forthAnswer = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][3]["answer"];
          data.forthAnswerKey = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][3]["key"];
          data.initExerciseTime = content["rapidRoundQuestionData"]["rapidroundExercise"]["initExerciseTime"];
          data.remainingExerciseTime = content["rapidRoundQuestionData"]["rapidroundExercise"]["remainingExerciseTime"];
          data.timemax = content["rapidRoundQuestionData"]["rapidroundExercise"]["timemax"];
          data.totalSessionExercises = content["rapidRoundQuestionData"]["rapidroundExercise"]["totalSessionExercises"];
          data.maxsessionexercises = content["rapidRoundQuestionData"]["rapidroundExercise"]["maxsessionexercises"];
          data.isConfigRequired = content["rapidRoundQuestionData"]["rapidroundExercise"]["configurationRequired"];
          // Restart Counter
          data.countdown = content["rapidRoundQuestionData"]["rapidroundExercise"]["remainingExerciseTime"];
          //-----|ANSWER REVIEW|-----
          data.answerReviewStatusCode = null;
          data.answerReviewMsg = null;
          data.answerReviewQuestionId = null;
          data.answerReviewChoosenAnswer = null;
          data.answerReviewCorrectAnswer = null;
          data.answerReviewEvaluation = null;
          data.answerReviewTimeDuration = null;

        } else if (response.status == 403 || perm_response.status == 403) {
          await store.dispatch("setAuth", false);
          await router.push("/login");
        }
      } catch (e) {
        await store.dispatch("setAuth", false);
      }
    });

    return {
      data,
    };
  },
  methods: {
    async submitAnswer(givenAnswer, skippedValue) {
      const { cookies } = useCookies();

      const postData = reactive({
        sessionId: this.data.sessionId,
        questionId: this.data.questionId,
        initExerciseTime: this.data.initExerciseTime,
        answerParameter: givenAnswer,
        skippedValue: skippedValue
      });

      this.data.loading = true;
      try {
        const response = await fetch(
          this.$store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/rapidroundquestion",
          {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
            body: JSON.stringify(postData)
          }
        );

        const content = await response.json();
        if (response.status == 200){
          this.data.loading = false;

          this.data.answerReviewStatusCode = content["rapidRoundQuestionData"]["exerciseAnswerReview"]["statusCode"];
          this.data.answerReviewMsg = content["rapidRoundQuestionData"]["exerciseAnswerReview"]["message"];
          this.data.answerReviewQuestionId = content["rapidRoundQuestionData"]["exerciseAnswerReview"]["questionId"];
          this.data.answerReviewChoosenAnswer = content["rapidRoundQuestionData"]["exerciseAnswerReview"]["choosenAnswer"];
          this.data.answerReviewCorrectAnswer = content["rapidRoundQuestionData"]["exerciseAnswerReview"]["correctAnswer"];
          this.data.answerReviewEvaluation = content["rapidRoundQuestionData"]["exerciseAnswerReview"]["evaluation"];
          this.data.answerReviewTimeDuration = content["rapidRoundQuestionData"]["exerciseAnswerReview"]["timeDurationSec"];          
          
        }
        // /!\ Move this block of code below to the abofe IF 200?
        this.data.isAnswerReview = true;
        this.mathjax_loader();  // Render Latex formulas with Mathjax

        } catch (e) {
        await this.$store.dispatch("setAuth", false);
      }
    },
    submitFirstAnswer() {      
      this.submitAnswer(this.data.firtsAnswerKey, 0);      
    },
    submitSecondAnswer() {
      this.submitAnswer(this.data.secondAnswerKey, 0);    
    },
    submitThirdAnswer() {
      this.submitAnswer(this.data.thirdAnswerKey, 0);    
    },
    submitForthAnswer() {
      this.submitAnswer(this.data.forthAnswerKey, 0);    
    },
    submitSkipQuestion() {
      this.submitAnswer(null, 1); 
    },
    async nextQuestion(){
      const { cookies } = useCookies();

      this.data.loading = true;
      this.data.likeExercise = false;
      this.data.disLikeExercise = false;

      try {
        const response = await fetch(
          this.$store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/rapidroundquestion",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
          }
        );

        const content = await response.json();
        if (response.status == 200){

          //-----|STATUS|-----
          this.data.rapidRoundRequestStatusCode = content["rapidRoundQuestionData"]["status"]["statusCode"];
          this.data.rapidRoundRequestStatusMsg = content["rapidRoundQuestionData"]["status"]["message"];
          //-----|SESSION|-----
          this.data.sessionId = content["rapidRoundQuestionData"]["studysessionsummary"]["sessionId"];
          this.data.isSessionReview = content["rapidRoundQuestionData"]["studysessionsummary"]["finalized"];
          this.data.sessionReviewMsg = content["rapidRoundQuestionData"]["studysessionsummary"]["sessionMessage"];
          this.data.endOfSession = content["rapidRoundQuestionData"]["sessionReview"]["endOfSession"];
          this.data.endOfSessionMessage = content["rapidRoundQuestionData"]["sessionReview"]["endOfSessionMessage"];
          this.data.totalExercices = content["rapidRoundQuestionData"]["sessionReview"]["totalExercices"];
          this.data.totalCorrect = content["rapidRoundQuestionData"]["sessionReview"]["totalCorrect"];
          this.data.totalIncorrect = content["rapidRoundQuestionData"]["sessionReview"]["totalIncorrect"];
          this.data.totalTimeout = content["rapidRoundQuestionData"]["sessionReview"]["totalTimeout"];
          this.data.totalSkipped = content["rapidRoundQuestionData"]["sessionReview"]["totalSkipped"];
          this.data.answerTimeSecAvg = content["rapidRoundQuestionData"]["sessionReview"]["answerTimeSecAvg"];
          this.data.tendency = content["rapidRoundQuestionData"]["sessionReview"]["tendency"];
          this.data.gradeScore = content["rapidRoundQuestionData"]["sessionReview"]["gradeScore"];
          if (this.data.totalExercices != null && this.data.totalExercices > 0)
          {
            this.data.chartData = [
              { value: this.data.totalCorrect, name: 'Corretos' },
              { value: this.data.totalIncorrect - this.data.totalSkipped - this.data.totalTimeout, name: 'Incorretos' },
              { value: this.data.totalTimeout, name: 'Tempo Excedido' },
              { value: this.data.totalSkipped, name: 'Saltados' }
              ];
          }
          //-----|EXERCISE|-----
          this.data.questionId = content["rapidRoundQuestionData"]["rapidroundExercise"]["questionId"];
          this.data.question = content["rapidRoundQuestionData"]["rapidroundExercise"]["question"];
          this.data.firtsAnswer = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][0]["answer"];
          this.data.firtsAnswerKey = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][0]["key"];
          this.data.secondAnswer = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][1]["answer"];
          this.data.secondAnswerKey = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][1]["key"];
          this.data.thirdAnswer = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][2]["answer"];
          this.data.thirdAnswerKey = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][2]["key"];
          this.data.forthAnswer = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][3]["answer"];
          this.data.forthAnswerKey = content["rapidRoundQuestionData"]["rapidroundExercise"]["answers"][3]["key"];
          this.data.initExerciseTime = content["rapidRoundQuestionData"]["rapidroundExercise"]["initExerciseTime"];
          this.data.remainingExerciseTime = content["rapidRoundQuestionData"]["rapidroundExercise"]["remainingExerciseTime"];
          this.data.timemax = content["rapidRoundQuestionData"]["rapidroundExercise"]["timemax"];
          this.data.totalSessionExercises = content["rapidRoundQuestionData"]["rapidroundExercise"]["totalSessionExercises"];
          this.data.maxsessionexercises = content["rapidRoundQuestionData"]["rapidroundExercise"]["maxsessionexercises"];
          this.data.isConfigRequired = content["rapidRoundQuestionData"]["rapidroundExercise"]["configurationRequired"];

          this.data.isAnswerReview = false;
          this.data.loading = false;

          // Restart Counter
          this.data.countdown = content["rapidRoundQuestionData"]["rapidroundExercise"]["remainingExerciseTime"];
          this.countDownTimer();

        }

        } catch (e) {
        await this.$store.dispatch("setAuth", false);
      }
    },
    countDownTimer(){
      // Code to render mathjax exercise if new data arrives
        if(this.data.mathjaxRender == true){
          this.mathjax_loader();  // Render Latex formulas with Mathjax
          let answerElementExists = !!document.getElementById('firstAnswerText');
          if(answerElementExists)
          {
            this.setButtonHeight();
          }
        }

      // TODO (?): STOP TIMER WHEN ANSWER IS GIVEN
      // COUNTDOWN TIMER
      if(this.data.countdown >= 0 && this.data.isAnswerReview == false && this.data.isSessionReview == false && this.data.isConfigRequired == false){
        this.data.countdownPerc = ((this.data.countdown) * 100)/this.data.timemax;
        setTimeout(() => {
          this.data.countdown -= 1;
          this.countDownTimer();
        }, 1000)
      }  
      if(this.data.countdown < 0 && this.data.isAnswerReview == false && this.data.isSessionReview == false && this.data.isConfigRequired == false){
        // Time-Out POST
        this.submitAnswer(null,0);
      }
    },
    async submitFeedback(feedback) {
      const { cookies } = useCookies();

      const postData = reactive({
        idSession: this.data.sessionId,
        exerciseId: this.data.questionId,
        userFeedback: feedback // Dislike: -1
      });

      try {
        /*const response =*/ await fetch(
          this.$store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/exerc-feedback",
          {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
            body: JSON.stringify(postData)
          }
        );

         } catch (e) {
        await this.$store.dispatch("setAuth", false);
      }

    },    
    submitQuestionLike(){
      this.submitFeedback(1);

      this.data.likeExercise = !this.data.likeExercise; 
      if (this.data.likeExercise == true) {
        this.data.disLikeExercise = false;
      }

    },
    submitQuestionDislike(){
      this.submitFeedback(-1);
            this.data.disLikeExercise = !this.data.disLikeExercise; 
      if (this.data.disLikeExercise == true) {
        this.data.likeExercise = false;
      }
    },
    mathjax_loader(){
      window.MathJax = {
        tex: {
          inlineMath: [['$', '$'], ['\\(', '\\)'],['!!', '!!']]
        }
      };
      
      (function () {
        var script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js';
        script.async = true;
        document.head.appendChild(script);
      })();
    },
    setButtonHeight(){
          let firstButtonHeight = document.getElementById('firstAnswerText').offsetHeight;
          let secondButtonHeight = document.getElementById('secondAnswerText').offsetHeight;
          let thirdButtonHeight = document.getElementById('thirdAnswerText').offsetHeight;
          let forthButtonHeight = document.getElementById('forthAnswerText').offsetHeight;
          let maxHeight = Math.max(firstButtonHeight, secondButtonHeight, thirdButtonHeight, forthButtonHeight);
          if(maxHeight >= 25){
            this.data.buttonHeight = (maxHeight+20) + "px";
          }
          else
          {
            this.data.buttonHeight = "50px";
          }    
    }
  },
  created() {
    this.countDownTimer();   
  }
};
</script>

<style scoped>
    .v-btn { 
        text-transform: none !important; 
    }
</style>
<template>
    <div>
        <v-container grid-list-md text-xs-center fluid align="center">
            <v-card class = "pa-2" max-width="600" align ="left">
                <v-card-title>Como podemos ajudar?</v-card-title>
                <v-card-text>Tens uma dúvida sobre o Mathack 🤔 ou alguma coisa nova que gostasses de ver na app? Envia a tua mensagem 📧 . Nós respondemos-te para o teu email.</v-card-text>
                <v-textarea
                    class = "mx-4"
                    name="input-7-1"
                    v-model="message"
                    filled
                    label="Mensagem"
                ></v-textarea>
        <v-row align="center" justify="center">
            <v-btn color="primary" @click="submitMessage">
                Enviar
            </v-btn>
        </v-row>
        <div id='status-msg' class="login-msg my-4" align="center" justify="center">{{statusReplyMessage}}</div>
        <v-card-text></v-card-text>
            </v-card>
        </v-container>
    </div>

    <v-overlay :model-value="loading" class="align-center justify-center">
        <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay> 
</template>

<script>
import { onMounted, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";

export default {
    data () {
        return {
            message: '',
            statusReplyMessage: '',
        }
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const { cookies } = useCookies();

        let loading = ref(true);
        onMounted(async () => {
        try {
                loading.value = true;
                const response = await fetch(
                    store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/contactus",
                    {
                        method: "GET",
                        headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + cookies.get("jwt"),
                        },
                    }
                );         

                // If status unsuccess (different from code 200)
                if (response.status != 200) {
                    await store.dispatch("setAuth", false);
                    loading.value = false;
                    await router.push("/");
                }
                // If status success (code 200)
                else
                {
                    loading.value = false;
                }
            } catch (e) {
                await store.dispatch("setAuth", false);
            }
        });
        return {
            loading
        }      
    },
    methods: {
        async sendMessage(messageToSend) {
            const { cookies } = useCookies();

            this.loading = true;

            const postData = reactive({
                message: messageToSend,
            });

            try {
            const response = await fetch(
                this.$store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/contactus",
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + cookies.get("jwt"),
                    },
                    body: JSON.stringify(postData)
                }
            );


            const content = await response.json();
            
            // If status unsuccess (different from code 200)
            if(response.status != 200){    
                this.loading = false;
                //console.log("Response: " + content); // TO DELETE - Diagnose Purposes
                document.getElementById('status-msg').style.color = "red";
                this.statusReplyMessage = '' + content; 
            }
            // If status success (code 200)
            else
            {
                this.loading = false;
                //console.log("Response: " + content); // TO DELETE - Diagnose Purposes
                document.getElementById('status-msg').style.color = "green";
                this.statusReplyMessage = '' + content; 
            }
            
            } catch (e) {
                await this.$store.dispatch("setAuth", false);
            }
        },
        submitMessage() {
            this.sendMessage(this.message);
        },
    }
}
</script>

<style scoped>
    
</style>

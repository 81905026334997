<template>
    <div class="text-center">
      <ConfirmPrompt ref="confirm" />
    </div>
    <div v-if="loading === false">
    <v-container grid-list-md text-xs-center fluid align="center">
      <v-card max-width="600" align ="left">
        <v-card-title>Escolhe as matérias que queres treinar</v-card-title>
        <v-container fluid >
          <div class="cat-checkbox" v-for="cat in catconfigs" :key="cat.id">
            <v-checkbox v-model="cat.active" :label="$t(cat.name)" hide-details> 
            </v-checkbox>
          </div>
          <div class="text-center">
          <v-btn @click="saveCategories()" :color = "button_color" elevation = "2" class="my-4"> {{save_button}} </v-btn>
          </div>
        </v-container>

       </v-card>
    </v-container>
    </div>

    <v-overlay :model-value="loading || save" class="align-center justify-center">
      <v-progress-circular
        indeterminate
        size="64">
      </v-progress-circular>
    </v-overlay>
  
</template>

<script>
import { onMounted, ref, reactive} from "vue";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";
import ConfirmPrompt from '@/components/ConfirmPrompt.vue';

export default {
  name: "CategoryUserConfig",
  components:{
    ConfirmPrompt
  },
   data: () => ({
      save: false,
      save_button: 'Guardar',
      button_color: 'primary'
  }),
  setup() {
    const router = useRouter();
    let loading = ref(true);
    const title = ref("Hello!");
    const message = ref("You are not logged in!");
    const catconfigs = reactive([]);
    const checkboxs = reactive([]);
    const store = useStore();
    const { cookies } = useCookies();
    store.dispatch('setCurrentScreen', 'Configuração');
    let siteUrl = store.state.projectConfigs.siteUrl;
    onMounted(async () => {
      // /wp-json/jwt-auth/v1/token/validate
      try {
          const config_response = await fetch(
            store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/user-category-config",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + cookies.get("jwt"),
              },  
            }
          );
          const config_content = await config_response.json();

          if (config_response.status == 200) {
            loading.value = false;
            await store.dispatch("setAuth", true);
            for (let i=0; i < config_content.length; i++){
              let namev = config_content[i]['name'];
              let act = true;
              let idcat = config_content[i]['idCategory'];
              if (config_content[i]['active'] == "1"){
                act = true;
              }
              else{
                act = false;
              }
              catconfigs.push({id: idcat, name: namev, active: act});
            }
          }
          else if (config_response.status == 403) {
          await store.dispatch("setAuth", false);
          await router.push("/login");
        }

          

         
      } catch (e) {
        await store.dispatch("setAuth", false);
      }
    });
    return {
      title,
      message,
      loading,
      catconfigs,
      checkboxs,
      siteUrl,
      cookies
    };
  },
  methods: {
    $t(value) {
      return value;
    },
    refesh_checkbox(){
      return this.catconfigs.name;
    },
    
    async save_cat(){

      this.save = true;

       const updated_cats = [];
       for (let i=0; i < this.catconfigs.length; i++){
            let idCat = this.catconfigs[i]['id'];
            let act = this.catconfigs[i]['active'];
            updated_cats.push(JSON.stringify({"idCategory": idCat.toString(), "active": act}));
          }
      
      const config_response = await fetch(
            this.siteUrl + "/wp-json/jwt-auth/v1/user-category-config",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.cookies.get("jwt"),
              },
              body: JSON.stringify(updated_cats)
            }
          );
          if (config_response.status == 200) {
            this.save_button = "Guardado!";
            this.button_color = "light";
            setTimeout(() => { this.save_button = 'Guardar'; this.button_color = "primary";}, 2000);
          }
          else{
            this.save_button = "Erro!";
            this.button_color = "error";
            setTimeout(() => { this.save_button = 'Guardar'; this.button_color = "light";}, 2000);
          }
          this.save = false;
    },
    async saveCategories() {
      for (let i=0; i < this.catconfigs.length; i++){
        if (this.catconfigs[i]['active'] == false){
          if (
            await this.$refs.confirm.open(
              "Confirmar",
              "Não selecionaste todas as matérias. Assim, os exercícios que fizeres não vão contar para a previsão da nota. Queres continuar mesmo assim?"
            )
          ) {
            await this.save_cat();
          }
          else{
              return;
          }
        }
      }
      await this.save_cat();
    },
  }
    
};
</script>
<template>
  <v-container grid-list-md text-xs-center fluid align="center">
    <v-card class="pa-2" max-width="600" align="center"> 
        <v-card-title class="justify-center">
            🌟 Dá o Salto para o Sucesso! 🌟
        </v-card-title>
        Top a tua escolha! 🎉 Estás a dar tudo na preparação para o exame de Matemática A do 12º ano e estamos aqui para te dar aquele boost. Como agradecimento, temos uma surpresa brutal: que tal mais <u><strong>três meses no Mathack à pala</strong></u>? 🤩 Isso mesmo, vais ter <u><strong>acesso total sem pagar mais nada</strong></u> - é um presente nosso para ti, um extra para te ajudar a arrasar nos resultados. Preparado(a) para virar o jogo e fazer acontecer? Bora lá! 🚀✨
    </v-card>
    <v-container>
        <v-btn color="primary" elevation="2" class="my-2" @click="$router.push('/')"> Começar a Praticar <v-icon>mdi-skip-next</v-icon></v-btn>
    </v-container>
  </v-container>

</template>

<script>
export default {
    
}
</script>

<style scoped>

</style>
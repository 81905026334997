<template>

    <v-app-bar v-click-outside="onClickOutside" color="#F1F1F1"> <!-- If clicked outside the menu options, close the drawer -->
      <v-app-bar-nav-icon v-if="auth" @click="drawer = !drawer" style="backgroundColor: #F1F1F1"></v-app-bar-nav-icon>
      <v-app-bar-title>
        <v-img
          :src="require('../assets/mathack_logo_1500x300.png')"
          alt=""
          contain
          width="150"
          height="50"
        /></v-app-bar-title>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        v-if="auth"
        class="Nav"
      >
        <v-list
          nav
          dense
        >
          <v-list-item-group
            v-model="group"
            active-class="text--accent-4"
          >
            <!-- <v-list-item prepend-icon="mdi-home" title="Home"></v-list-item>
  
            <v-list-item prepend-icon="mdi-account" title="Account"></v-list-item> -->
            <v-list-item
                    class= "nav-items"
                    v-for="item in items"
                    :key="item.title"
                    dense
                    router :to="item.route"
                    @click="drawer = !drawer"
                    active-class="active-nav-item"

                >
                    <v-list-item-icon >
                        <v-icon class="ma-2">{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-title>{{ item.title }}</v-list-title>
                    </v-list-item-content>

                </v-list-item>
                <v-list-item class = "nav-items" 
                dense 
                router to="/login"
                @click.stop="logout()" 
                >
                  <v-list-item-icon>
                    <v-icon class="ma-2">mdi-logout</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                          <v-list-title>Logout</v-list-title>
                  </v-list-item-content>
                </v-list-item>

          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useCookies } from 'vue3-cookies';

export default {
    name: "Nav",
    data: () => ({
      drawer: false,
      group: null,
      defaults:[
        'Login.vue'
      ]
    }),
    setup() {
      const store = useStore();
      const { cookies } = useCookies();
      var items = [];
      let siteUrl = store.state.projectConfigs.siteUrl;
      const auth = computed(() => store.state.authenticated);
      const screenName = computed(() => store.state.currentScreen);

      const logout = () => {
        cookies.remove("jwt");
        store.dispatch("setAuth", false);
      }
    return {
        auth,
        cookies,
        items,
        screenName,
        siteUrl,
        logout
      }
    },
    async beforeCreate(){
      var items = [];
      items = [
          {icon: 'mdi-home', title:'Home', route:'/', view:'Home.vue'},
          {icon: 'mdi-lightning-bolt', title:'MathBlitz', route:'/mathblitz', view:'RapidRound.vue'},
          {icon: 'mdi-rename-box', title:'MathClassic', route:'/examconstructedresponse', view:'ExamConstructedResponse.vue'},
          {icon: 'mdi-tools', title:'Configurações MathBlitz', route:'/config', view: 'CategoryUserConfig.vue'},
          {icon: 'mdi-border-color', title:'Criar Pergunta', route:'/create', view: 'CreateQuestion.vue'},
          {icon: 'mdi-clipboard-list-outline', title: 'Editar Perguntas', route: '/questions', view: 'LoadQuestions.vue'},
          //{icon: 'mdi-compass-outline', title:'Ajuda', route:'/help', view: 'Help.vue'},
          {icon: 'mdi-message-processing', title:'Fala Connosco', route:'/contactus', view: 'ContactUs.vue'},
          //{icon: 'mdi-logout', title:'Logout', route:'/login', view: 'Login.vue'}
        ];

        const has_perm = async (r) => { 
            if ( r == '/'){return true;}
            if ( r  == '/login'){return true;}
            if ( r == '/mathblitz'){return true;} //this should be better handled in the future
            if ( r == '/examconstructedresponse'){return true;} //this should be better handled in the future
            if ( r == '/config'){return true;} //this should be better handled in the future
            if ( r == '/contactus'){return true;}

            const config_response = await fetch(
              this.siteUrl + "/wp-json/jwt-auth/v1" + r,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + this.cookies.get("jwt"),
                },
              }
            );

            if (config_response.status == 200){return true;}
            else{return false;}
        }

        let final_items = [];

        for (let i=0; i < items.length; i++){
          let perm = await has_perm(items[i].route);
          if (perm){final_items.push(items[i]);} 
        }

        this.items = final_items;
    },
    methods:{
      onClickOutside () {
        if (this.drawer == true){
          this.drawer = false 
        }
      }      
  }
}
</script>

<style scoped>
.nav-items:hover {
    background: #4780AE;
    color: #F1F1F1;
  }
.active-nav-item{
  background: #4780ae60;
}

.Nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* This ensures the nav stays on top of other elements */
}
</style>

<template>
    <v-dialog v-model="dialog"
      @keydown.esc="cancel"
      >
      <v-container fluid align="center">
      <v-card class="pa-2" max-width="750" align="center">
      <v-card-text
        v-show="!!message"
        class="pa-4 black--text"
        v-html="message"
      ></v-card-text>
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <v-btn
          v-if="!options.noconfirm"
          color="light"
          text
          @click="cancel"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          outlined
          @click="agree"
          >OK</v-btn
        >
      </v-card-actions>
    </v-card>        
    </v-container>
    </v-dialog>
</template>

<script>
export default {
  name: "ConfirmPrompt",
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: "#D2D2D2",
      width: 400,
      zIndex: 200,
      noconfirm:false,
    }
  }),
  methods: {
    open(title, message, options) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        this.options = Object.assign(this.options, options);
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      agree() {
        this.resolve(true);
        this.dialog = false;
      },
      cancel() {
        this.resolve(false);
        this.dialog = false;
      }
  },
};
</script>

<style scoped>
  .my-custom-dialog {
    align-self: flex-end;
  }
</style>
<template>
  <v-chart class="chart" :option="option" autoresize/>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {   TitleComponent, TooltipComponent, GridComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";

use([CanvasRenderer, PieChart, GridComponent, TitleComponent, TooltipComponent, LegendComponent]);

export default defineComponent({
  name: "RapidRoundAnswerChart",
  props: ['chartdata'],
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  setup(props) {  // Include props argument in Setup to be able to acces the props value inside setup
    //console.log(props.chartdata);  // TO DELETE - Test Purposes
    const option = ref({
  tooltip: {
    trigger: 'item'
  },
  legend: {
    top: '5%',
    left: 'center'
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center'
      },
      labelLine: {
        show: false
      },
      color:[ '#67e0e3','#ff9f7f', '#ffdb5c', '#37a2da'], //Set desired colors
      /*
      data: [
        { value: 3, name: 'Correctos' },
        { value: 1, name: 'Incorrectos' },
        { value: 1, name: 'Tempo Excedido' },
        { value: 1, name: 'Saltados' }
      ]
      */
      data: props.chartdata
    }
  ]
});

    return { option };
  },
});
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
<template>

  <div v-if="loading === false" class="text-center">
    <v-overlay :model-value="onboardDialog" class="justify-center">
      <Onboarding @closeOnboarding="onboardDialog = false; onboardingConfirmation()"/>
    </v-overlay>
  </div>

  <div v-if="loading === false">
    <v-container grid-list-md text-xs-center fluid align="center">
      <v-card class = "pa-2" max-width="600" align ="center">
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text v-html="message"></v-card-text>
        <div class="text-center my-6">
        <v-btn @click="$router.push('/mathblitz')" color="primary"> 
          <v-icon left color="white">
            mdi-lightning-bolt
          </v-icon>
          Treinar para o Exame 
        </v-btn>
        </div>
        <v-card-text v-if="!(grade === null)" class="text-center mb-n4">Previsão Exame</v-card-text>
        <v-card-text v-if="!(grade === null)" class="text-center">
          <span style="font-size: 25px;">
            <sup><v-icon v-if="gradeTendency === 1" large color="green darken-2">mdi-arrow-up-bold-circle</v-icon></sup>
            <sup><v-icon v-if="gradeTendency === 0" large color="blue lighten-3">mdi-equal-box</v-icon></sup>
            <sup><v-icon v-if="gradeTendency === -1" large color="red lighten-3">mdi-arrow-down-bold-circle</v-icon></sup>
          </span>
          <span style="font-size: 50px;">{{ grade }}</span>
          <span style="font-size: 20px;">Valores</span>         
        </v-card-text>
        <v-card-text v-html="secondMessage"></v-card-text>
        <div class="text-center my-6">
        <v-btn @click="$router.push('/examconstructedresponse')" color="#D2D2D2"> 
          <v-icon left color="white">
            mdi-rename-box
          </v-icon>
          Praticar com Mathclassic
        </v-btn>
        </div>
        <v-card-text v-if="(accountType === 'trial' || accountType === 'free')" class="text-center">
          <span v-if="accountType === 'trial' && accountDaysToExpire > 1">
            Podes experimentar o Mathack SUPER por mais {{ accountDaysToExpire }} dias.<br><br>
          </span>
          <span v-if="accountType === 'trial' && accountDaysToExpire === 1">
            Tens apenas mais <b>um</b> dia de Mathack SUPER gratuito.<br><br>
          </span>
          <v-badge  v-if="accountType === 'trial'" :content="accountDaysToExpire" color="error">
            <v-icon size="50">mdi-calendar</v-icon>
          </v-badge>
          <span v-if="((accountType === 'trial' && accountDaysToExpire < 2 && accountIsExtendable === true) || (accountType === 'free' && accountIsExtendable === true))">
            <br>Queres ganhar <b>10 dias extra</b> do teu período experimental? Responde ao nosso questionário clicando no botão em baixo.<br><br>
          </span>
        </v-card-text>
        <div v-if="(accountType === 'trial' || accountType === 'free')">
          <v-btn @click="$router.push('/trialsurvey')" v-if="accountDaysToExpire < 2 && accountIsExtendable === true" color="primary">
            Quero mais 10 dias
          </v-btn>
        </div>
        <v-card-text></v-card-text>
      </v-card>
    </v-container>
  </div>

    <div v-if="loading === false">
    <v-container grid-list-md text-xs-center fluid align="center">
    <!-- PERCENTILE CARD -->
      <v-card v-if="(accountType === 'super' || accountType === 'trial' )" max-width="600" align ="center">      
        <v-card-text v-if="percentileTop < 50" class="text-high-emphasis mb-n6">
          <span style="font-size: 30px;">
            <v-icon large color="orange darken-3" class="top-icon"> mdi-trophy </v-icon>
          </span>
          <span style="font-size: 30px;" class="text-high-emphasis">
            Top {{percentileTop}}%
          </span>
        </v-card-text>
        <v-card-text v-if="percentileTop < 50">
          <p>A tua nota é mais alta do que {{percentile}}% de todos os utilizadores do Mathack.</p>
          <p>Muito bom 🥳. Mas agora não podes adormecer, senão vais ficar para trás.</p>
        </v-card-text>

        <v-card-text v-if="percentileTop > 50" class="text-high-emphasis mb-n6">
          <span style="font-size: 20px;" class="text-high-emphasis">
          Ainda não estás entre os <br><b>50% melhores</b> utilizadores do Mathack. <br>
          </span>
          <span style="font-size: 30px;">
            <v-icon color="red lighten-3" class="top-icon my-2" > mdi-download-circle </v-icon>
          </span>
        </v-card-text>
        <v-card-text v-if="percentileTop > 50">
            Continua a treinar. <br>
            Avisamos-te assim que lá chegares 👍
        </v-card-text>
      </v-card>
    </v-container>
    </div>

    <div v-if="loading === false">
    <v-container grid-list-md text-xs-center fluid align="center">

      <!-- GRADE CHART CARD -->
      <v-card v-if="(accountType === 'super' || accountType === 'trial')" class = "pa-2 pl-3" max-width="600" align ="left">
        <v-card-title>Estudo da última semana</v-card-title>
        <Chart :chartdata = "chartData" v-if="chartData != 0"/>
      </v-card>
      <MHSuperAdCard v-if="(accountType === 'free')"/>
    </v-container>

    <!-- RADAR CHART CARD -->
    <v-container v-if="(accountType === 'super' || accountType === 'trial' )" grid-list-md text-xs-center fluid align="center">      
      <v-card class = "pa-2 pl-3" max-width="600" align ="left">
        <v-card-title>Avaliação por Categoria</v-card-title>
        <RadarChart :radarChartData = "radarChartData" v-if="radarChartData != 0"/>      
      </v-card>
    </v-container>
  </div>


<v-overlay :model-value="loading" class="align-center justify-center">
  <v-progress-circular
    indeterminate
    size="64">
  </v-progress-circular>
</v-overlay>
</template>

<script>
import { onMounted, ref, reactive} from "vue";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";
import Chart from '@/components/StudentGradeChart.vue';
import Onboarding from '@/components/Onboarding.vue';
import MHSuperAdCard from '@/components/MHSuperAdCard.vue';
import RadarChart from '@/components/StudentCategoryChart.vue'
//import { computed } from 'vue';

export default {
  name: "Home",
  components: {
    Chart,  //instantiate it here
    Onboarding,
    MHSuperAdCard,
    RadarChart
  },
  setup() {
    const router = useRouter();
    const loading = ref(true);
    const title = ref("Hello!");
    const message = ref("You are not logged in!");
    const secondMessage = ref(null);
    const grade = ref(0);
    const gradeTendency = ref(0);
    const store = useStore();
    const { cookies } = useCookies();
    let percentile = ref(0);
    let percentileTop = ref(0);

    let chartData = ref(0);
    let onboardDialog = ref(false);
    let accountType = ref(null);
    let accountDaysToExpire = ref(null);
    let accountIsExtendable = ref(false);

    let radarChartData = ref(0);

    store.dispatch('setCurrentScreen', 'Home');

    onMounted(async () => {
      try {
        const response = await fetch(
          store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/home",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
          }
        );
        
        const content = await response.json ();

        if (response.status == 200) {
          await store.dispatch("setAuth", true);
          loading.value = false;
          onboardDialog.value = content['home']['welcomemessage']['onboardUser'];
          accountType.value = content['home']['welcomemessage']['accountType'];
          accountDaysToExpire.value = content['home']['welcomemessage']['accountDaysToExpire'];
          accountIsExtendable.value = content['home']['welcomemessage']['accountIsExtendable'];
          title.value = content['home']['welcomemessage']['title'];
          message.value = content['home']['welcomemessage']['message'];
          secondMessage.value = content['home']['welcomemessage']['secondMessage'];
          grade.value = content['home']['welcomemessage']['grade'];
          gradeTendency.value = content['home']['welcomemessage']['gradeTendency'];
          chartData.value = content['home']['gradedata'];
          
          const response_percent = await fetch(
          store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/mh-student-percentile",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
          }
          );

          if (response_percent.status == 200){
            const content = await response_percent.json();
            let percTopNumb = parseFloat(content['percentile']['percentileTop']);
            let percNumb = parseFloat(content['percentile']['percentile']);
            if (percTopNumb < 10 || percNumb > 99)
            {
              percentileTop.value = Math.round(percTopNumb * 10) / 10;
              percentile.value = Math.round(percNumb * 10) / 10;
             
            }

            else{
              percentileTop.value = Math.round(percTopNumb);
              percentile.value = Math.round(percNumb);
              }

          }

          //--------|RADAR CHART-BEGIN|--------
          const response_categories = await fetch(
          store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/mh-student-categories-grade",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
          }
          );

          const content_categories = await response_categories.json ();

          if (response_categories.status == 200){
            // chartData.value = content['home']['gradedata'];
            radarChartData.value = content_categories;
          }
          else{
            radarChartData.value = 0;
          }
          //--------|RADAR CHART-END|--------

        } else if (response.status == 403) {
          await store.dispatch("setAuth", false);
          await router.push("/login");
        }
      } catch (e) {
        await store.dispatch("setAuth", false);
      }
    });

    return {
      title,
      message,
      secondMessage,
      grade,
      gradeTendency,
      loading,
      chartData,
      onboardDialog,
      accountType,
      accountDaysToExpire,
      accountIsExtendable,
      percentile,
      percentileTop,
      radarChartData
    };
  },
  methods: {
   async onboardingConfirmation() {
     // Place here REST POST to confirm user onboard
     this.onboardDialog = false;
     
     const { cookies } = useCookies();

      const postData = reactive({
        onboardingConfirmation: true
      });

      try {
        /*const response = */await fetch(
          this.$store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/onboardingconfirmation",
          {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
            body: JSON.stringify(postData)
          }
        );

        /*
        if (response.status == 200){

        }
        */
        } catch (e) {
          console.log('Failed Onboarding');
          await this.$store.dispatch("setAuth", false);
      }
    
   }
 }
};
</script>

<style scoped>

body{
  background: #F1F1F1;
}

.home-card{
  background: #F1F1F1;
}

.top-icon { vertical-align: middle }

</style>
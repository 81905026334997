<template>
  <div>
    <v-container grid-list-md text-xs-center fluid align="center">
      <v-card max-width="600" align="left">
        <div class="container">
          <table class="table">
            <thead>
              <!-- Table Head is only used to create black line on top of first row -->
              <tr>
                <th colspan="2">Resumo do MathClassic Multipla Escolha</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="vertical-align: middle">Avaliação</td>
                <td style="vertical-align: middle">
                  <v-btn v-if="mathClassicMultiChoiceAnswerReview.evaluation==true" color="success" elevation="0">Correto</v-btn>
                  <v-btn v-if="mathClassicMultiChoiceAnswerReview.evaluation!=true" color="error" elevation="0">Incorreto</v-btn>
                </td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Resposta Escolhida</td>
                <td style="vertical-align: middle">{{ mathClassicMultiChoiceAnswerReview.choosenAnswer }}</td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Resposta Correcta</td>
                <td style="vertical-align: middle">{{ mathClassicMultiChoiceAnswerReview.correctAnswer }}</td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Opção Escolhida</td>
                <td style="vertical-align: middle">{{ mathClassicMultiChoiceAnswerReview.choosenOption }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
export default {
  name: "MathClassicMultiChoiceAnswerReview",
  // Pass in the props the multiple choice question data
  props: {
    answerReviewnData: {
      type: Object, // Define the type of the prop
      required: true, // Set whether the prop is required or optional
    },
  },
  // Your script code here
  data() {
    return {
      overlay: false,
    };
  },
  setup(props) {
    // Include props argument in Setup to be able to acces the props value inside setup
    const mathClassicMultiChoiceAnswerReview = reactive({
      statusCode: null,
      message: null,
      questionId: null,
      choosenAnswer: null,
      correctAnswer: null,
      choosenOption: null,
      evaluation: null,
      timeDurationSec: null,
    });

    // Define the mathjax_loader function
    function mathjax_loader() {
      window.MathJax = {
        tex: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
            ["!!", "!!"],
          ],
        },
      };

      (function () {
        var script = document.createElement("script");
        script.src = "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js";
        script.async = true;
        document.head.appendChild(script);
      })();
    }

    //console.log("Answer Review Data:"); // TO DELETE - Diagnose Purposes
    mathClassicMultiChoiceAnswerReview.choosenAnswer = ref(props.answerReviewnData.choosenAnswer);
    mathClassicMultiChoiceAnswerReview.evaluation = ref(props.answerReviewnData.evaluation);
    mathClassicMultiChoiceAnswerReview.correctAnswer = ref(props.answerReviewnData.correctAnswer);
    mathClassicMultiChoiceAnswerReview.choosenOption = ref(props.answerReviewnData.choosenOption);
    mathClassicMultiChoiceAnswerReview.timeDurationSec = ref(props.answerReviewnData.timeDurationSec);
    //console.log(props.answerReviewnData); // TO DELETE - Diagnose Purposes

    // Call the mathjax_loader function
    mathjax_loader();

    return {
      mathClassicMultiChoiceAnswerReview,
    };
  },
};
</script>

<style scoped>
/* Your CSS code here */
</style>

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

//import colors from 'vuetify/lib/util/colors'

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            //primary: colors.red.darken1, // #E53935
            primary: '#4780AE',
          }
        },
      },
    },
  })

<template>
  <v-overlay :model-value="loading" class="align-center justify-center">
    <v-progress-circular indeterminate size="64"> </v-progress-circular>
  </v-overlay>

  <v-overlay v-model="overlay" class="align-center justify-center">
    <img v-bind:src="answerImg" />
  </v-overlay>

  <v-container grid-list-md text-xs-center fluid align="center">
    <v-card max-width="600" align="left">
      <div class="container">
        <table class="table">
          <thead>
              <!-- Table Head is only used to create black line on top of first row -->
              <tr>
                <th colspan="2">Solução</th>
              </tr>
            </thead>
          <tbody>
            <tr v-if="questionData.category && questionData.category.length > 0">
              <td style="vertical-align: middle">Tipo de Exercício</td>
              <td style="vertical-align: middle"> {{ questionData.category.join(', ') }} </td> <!-- categorysec -->
            </tr>
            <tr v-if="questionData.categorysec && questionData.categorysec.length > 0">
              <td style="vertical-align: middle">Outras Cetegorias</td>
              <td style="vertical-align: middle"> {{ questionData.categorysec.join(', ') }} </td> 
            </tr>
          </tbody>
        </table>
        <v-img
          :width="600"
          aspect-ratio="16/9"
          cover
          v-bind:src="answerImg"
          @click="overlay = !overlay"
        ></v-img>
        <table class="table">
          <thead>
            <!-- Table Head is only used to create black line on top of first row -->
            <tr></tr>
          </thead>
          <tbody>
            <tr>
              <td style="vertical-align: middle">Tempo de Resposta</td>
              <td style="vertical-align: middle"> {{ formattedExerciseDuration }} </td>
            </tr>
            <tr></tr>
          </tbody>
        </table>
        <!-- V IF TOTAL EXERCISES IS 0, DON'T SHOW -->
      </div>
      <!-- -->

      <div>
        <v-row justify="center">
         <v-col cols="5" class="ml-4 mr-2" v-if="!isValidatedAnswerExercise">
            <v-btn @click="submitCorrecAnswer" color="primary" block elevation="2">
              <!-- color="success" -->
              Respondi Correctamente&nbsp;<v-icon>mdi-thumb-up</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="5" class="mr-4 ml-2" v-if="!isValidatedAnswerExercise">
            <v-btn @click="submitWrongAnswer" color="error" block elevation="2">
              Não Acertei&nbsp;<v-icon>mdi-thumb-down</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="5" class="ml-4 mr-2" v-if="isValidatedAnswerExercise">
            <v-btn @click="newMathClassic" color="primary" block elevation="2">
              <!-- color="success" -->
              Novo Mathclassic&nbsp;<v-icon>mdi-skip-next</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <p></p>
      </div>

      <div id='status-msg' class="login-msg my-4">{{message}}</div>

    </v-card>
  </v-container>
</template>

<script>
import { onMounted, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";

export default {
  name: "ExamAnswerReview",
  props: {
    questionData: {
      type: Object, // Define the type of the prop
      required: true, // Set whether the prop is required or optional
    },
    time: {
      type: Object, // Define the type of the prop
      required: true, // Set whether the prop is required or optional
    },
    isValidatedAnswer: {
      type: Boolean, // Define the type of the prop
      required: true, // Set whether the prop is required or optional
    },
  },  
  data() {
    return {
      overlay: false,
    };
  },
  setup(props) {
    // Include props argument in Setup to be able to acces the props value inside setup
    const loading = ref(true);
    const router = useRouter();
    const store = useStore();
    const { cookies } = useCookies();
    store.dispatch("setCurrentScreen", "Pergunta Exame");
    let answerImg = ref(null);
    const message = ref(' ');

    //console.log("Time Duration:"); // TO DELETE - Diagnose Purposes
    //console.log(props.time); // TO DELETE - Diagnose Purposes
    let exerciseDurationSec = ref(props.time);
    //console.log("Is Correct:"); // TO DELETE - Diagnose Purposes
    //console.log(props.isValidatedAnswer); // TO DELETE - Diagnose Purposes
    let isValidatedAnswerExercise = ref(props.isValidatedAnswer); 

    //console.log("Question Data Category:"); // TO DELETE - Diagnose Purposes
    //console.log(props.questionData.category); // TO DELETE - Diagnose Purposes

    let post_data = {
      examquestionid: parseInt(props.questionData.ongoingExerciseId),
      mathClassicSessionId : props.questionData.ongoingSessionId,
    };

    //console.log("Post Data:"); // TO DELETE - Diagnose Purposes
    //console.log(post_data); // TO DELETE - Diagnose Purposes

    onMounted(async () => {
      try {
        const exam_question_response = await fetch(
          store.state.projectConfigs.siteUrl +
            "/wp-json/jwt-auth/v1/examanswer",
          {
            //method: "GET",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
            body: JSON.stringify(post_data),
          }
        );
        const exam_question_content = await exam_question_response.json();

        answerImg.value = exam_question_content["examdata"]["examAnswerImage"];

        if (exam_question_response.status == 200) {
          loading.value = false;
          await store.dispatch("setAuth", true);
        } else if (exam_question_response.status == 403) {
          await store.dispatch("setAuth", false);
          await router.push("/login");
        }
      } catch (e) {
        await store.dispatch("setAuth", false);
      }
    });

    return {
      loading,
      answerImg,
      message,
      exerciseDurationSec,
      isValidatedAnswerExercise,
    };
  },
  computed: {
    formattedExerciseDuration() {
      const hours = Math.floor(this.exerciseDurationSec / 3600);
      const minutes = Math.floor((this.exerciseDurationSec % 3600) / 60);
      const seconds = this.exerciseDurationSec % 60;

      let formattedTime = '';

      if (hours > 0) {
        formattedTime += `${hours} horas `;
      }
      if (minutes > 0) {
        formattedTime += `${minutes} minutos `;
      }
      formattedTime += `${seconds} segundos`;

      return formattedTime.trim();
    }
  },
  methods: {
    newMathClassic() {
      // Emit a custom event without a payload
      this.$emit("new-mathclassic");
    },
    submitCorrecAnswer() {
      this.submitAnswer(1);
    },
    submitWrongAnswer() {
      this.submitAnswer(0);
    },
    async submitAnswer(answer) {
      //console.log(answer);  // TO DELETE - Diagnose Purposes
      
      const { cookies } = useCookies();

            this.loading = true;

            //console.log('mathClassicSessionId: ' + this.$props.questionData.ongoingSessionId);  // TO DELETE - Diagnose Purposes
            const postData = reactive({
                'mathClassicSessionId': this.$props.questionData.ongoingSessionId,
                'imageBasedAnswerParameter': parseInt(answer),
                'answerParameter': null,
                'initExerciseTime': this.$props.questionData.ongoingInitExamExerciseTime,
                'examquestionid': this.$props.questionData.ongoingExerciseId,
            });

            //console.log(postData);  // TO DELETE - Diagnose Purposes

            try {
            const response = await fetch(
                this.$store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/examanswerreview",
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + cookies.get("jwt"),
                    },
                    body: JSON.stringify(postData)
                }
            );


            const content = await response.json();
            
            // If status success (different from code 200)
            if(response.status == 200){    
                this.loading = false;
                document.getElementById('status-msg').style.color = "green";
                this.message = content;
                this.newMathClassic(); 
            }
            // If status unsuccess (code 200)
            else
            {
              document.getElementById('status-msg').style.color = "red";              
              this.message = 'Erro: ' + content;
              this.loading = false;
            }
            
            } catch (e) {
                await this.$store.dispatch("setAuth", false);
            }
      
    }
  },
};
</script>

<style scoped></style>

<template>
  <div>
    <v-overlay
      :model-value="loading || save"
      class="align-center justify-center"
    >
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay>
  </div>
  <div>
    <v-container grid-list-md text-xs-center fluid align="center">
      <v-card class="pa-2" max-width="600" align="left">
        <v-card-title>Adicionar Pergunta</v-card-title>
        <v-card-text
          >Inserir a imagem de um exercício do exame nacional para o modo de estudo do Mathack <br />
          Se pretendido a pregunta pode ser transcrita no campo de texto. LaTex é suportados diretamente. HTML não é suportado.</v-card-text
        >

        <v-spacer></v-spacer>

        <v-container>
          <v-text-field label="Ano Exame" v-model="yearexercise" truncate-length="5"></v-text-field>
          <v-text-field label="Fase" v-model="phaseexercise" truncate-length="5"></v-text-field>
          <v-text-field label="Nº Exercicio" v-model="numberexercise" truncate-length="5"></v-text-field>
          <v-text-field label="Alínea" v-model="paragraphexercise" truncate-length="5"></v-text-field>
        </v-container>

        <v-card-title> Pergunta </v-card-title>
        
        <!-- Image preview goes here -->
        <v-container v-if="questionImageUpload !== null">
          <v-img v-bind:src="questionImageUpload"></v-img>
          <!-- Clipboard capture event -->
          <!-- <b-form-textarea @paste="pasteFunction"></b-form-textarea> -->
          <v-btn @click="removeQuestion" color="primary" elevation="2">
            Remover
          </v-btn>
        </v-container>

        <!-- Question Image load goes here -->
        <v-container v-if="questionImageUpload === null">
          <!--<v-file-input truncate-length="15" @change="previewFiles"></v-file-input>-->
          <v-text-field label="Paste image here" prepend-icon="mdi-content-paste" @paste="onPasteQuestion"></v-text-field>
          <v-file-input label="Upload Image from disk" prepend-icon="mdi-file-image" truncate-length="15" @change="resizeQuestionBeforeUpload"></v-file-input>
        </v-container>

        <v-container fluid>
          <v-textarea solo name="question" v-model="question"></v-textarea>
        </v-container>

        <v-container fluid>
          <v-card-title> Resposta </v-card-title>
        </v-container>

        <!-- Answer Image preview goes here -->
        <v-container v-if="answerImageUpload !== null">
          <v-img v-bind:src="answerImageUpload"></v-img>
          <!-- Clipboard capture event -->
          <!-- <b-form-textarea @paste="pasteFunction"></b-form-textarea> -->
          <v-btn @click="removeAnswer" color="primary" elevation="2">
            Remover
          </v-btn>
        </v-container>

        <!-- Answer Image load goes here -->
        <v-container v-if="answerImageUpload === null">
          <!--<v-file-input truncate-length="15" @change="previewFiles"></v-file-input>-->
          <v-text-field label="Paste image here" prepend-icon="mdi-content-paste" @paste="onPasteAnswer"></v-text-field>
          <v-file-input label="Upload Image from disk" prepend-icon="mdi-file-image" truncate-length="15" @change="resizeAnswerBeforeUpload"></v-file-input>
        </v-container>

        <v-container fluid>
          <v-textarea solo name="answer" v-model="answer"></v-textarea>
        </v-container>

        <v-container fluid>
          <v-card-title> Resolução </v-card-title>
        </v-container>

        <!-- Image preview goes here -->
        <v-container v-if="solutionImageUpload !== null">
          <v-img v-bind:src="solutionImageUpload"></v-img>
          <!-- Clipboard capture event -->
          <!-- <b-form-textarea @paste="pasteFunction"></b-form-textarea> -->
          <v-btn @click="removeSolution" color="primary" elevation="2">
            Remover
          </v-btn>
        </v-container>

        <!-- Resolution Image load goes here -->
        <v-container v-if="solutionImageUpload === null">
          <!--<v-file-input truncate-length="15" @change="previewFiles"></v-file-input>-->
          <v-text-field label="Paste image here" prepend-icon="mdi-content-paste" @paste="onPasteSolution"></v-text-field>
          <v-file-input label="Upload Image from disk" prepend-icon="mdi-file-image" truncate-length="15" @change="resizeSolutionBeforeUpload"></v-file-input>
        </v-container>

        <v-container fluid>
          <v-textarea solo name="resolution" v-model="resolution"></v-textarea>
        </v-container>

        <v-card-title> Categoria</v-card-title>
        <v-container fluid>
          <div class="dropdown">
            <button
              class="btn btn-light dropdown-toggle"
              type="button"
              id="dropdownMenuCategory"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ value_cat.name }}
            </button>
            <ul
              class="dropdown-menu scrollable-menu"
              aria-labelledby="dropdownMenuCategory"
              role="menu"
            >
              <li v-for="cat in categories" :key="cat.id">
                <a class="dropdown-item" @click="value_cat = cat">{{
                  cat.name
                }}</a>
              </li>
            </ul>
          </div>
        </v-container>

        <v-card-title> Dificuldade </v-card-title>

        <v-container fluid>
          <div class="dropdown" data-bs-spy="scroll">
            <button
              class="btn btn-light dropdown-toggle"
              type="button"
              id="dropdownMenuDif"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              aria-haspopup="true"
            >
              {{ value_dif }}
            </button>
            <ul
              class="dropdown-menu"
              aria-labelledby="dropdownMenuDif"
              role="menu"
            >
              <li v-for="dif in difficulty_list" :key="dif">
                <a class="dropdown-item" @click="value_dif = dif">{{ dif }}</a>
              </li>
            </ul>
          </div>
        </v-container>
        <v-container>
          <v-text-field label="Cotação Exercício" v-model="scoreExercise" truncate-length="5"></v-text-field>
          <v-text-field label="Cotação Total Exame" v-model="totalScoreExam" truncate-length="5"></v-text-field>
        </v-container>

        <v-container fluid>
          <v-btn @click="submit_exercise" color="primary" elevation="2">
            Guardar
          </v-btn>
          <v-btn @click="go_exercises" color="light" elevation="2">
            Voltar Exercícios
          </v-btn>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { onMounted, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";
//import QuestionPreview from "@/components/QuestionPreview.vue";

export default {
  name: "CreateQuestion",
  //components: {
  //  QuestionPreview,
  //},
  data() {
    return {
      yearexercise: 0,
      phaseexercise: 0,
      numberexercise: 0,
      paragraphexercise: "",
      save: false,
      question: "",
      answer: "",
      resolution: "",
      value_cat: { id: "-1", name: "Selecione uma opção" },
      difficulty_list: ["1", "2", "3"],
      value_dif: "Selecione uma opção", 
      test_debug: false,
      preview: false,
      questionImageUpload: null,
      answerImageUpload: null,
      solutionImageUpload: null,
      scoreExercise: 0,
      totalScoreExam: 0,
    };
  },
  setup() {
    const router = useRouter();
    let loading = ref(true);
    //const question = reactive([]);
    const answers = reactive([]);
    const categories = reactive([]);
    const store = useStore();
    const { cookies } = useCookies();
    let siteUrl = store.state.projectConfigs.siteUrl;
    onMounted(async () => {
      // Check if user is autenticated
      // /wp-json/jwt-auth/v1/token/validate
      try {
        //----- Authentication
        const response = await fetch(siteUrl + "/wp-json/jwt-auth/v1/create", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + cookies.get("jwt"),
          },
        });
        //-----------------
        if (response.status == 200) {
          await store.dispatch("setAuth", true);
        } else if (response.status == 403) {
          await store.dispatch("setAuth", false);
          await router.push("/");
        }
      } catch (e) {
        await store.dispatch("setAuth", false);
        await router.push("/login");
      }

      // GET list of existing categories
      const config_response = await fetch(
        store.state.projectConfigs.siteUrl +
          "/wp-json/jwt-auth/v1/user-category-config",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + cookies.get("jwt"),
          },
        }
      );

      const config_content = await config_response.json();

      // Process results into categories variable
      if (config_response.status == 200) {
        loading.value = false;
        for (let i = 0; i < config_content.length; i++) {
          let namev = config_content[i]["name"];
          categories.push({ id: config_content[i]["idCategory"], name: namev });
        }
      } else if (config_response.status == 500) {
        console.log("Couldn't load the category configurations.");
      }
    });
    return {
      loading,
      answers,
      categories,
      siteUrl,
      cookies,
      router,
    };
  },
  methods: {
    // Submit Exercise
    async submit_exercise() {
      this.save = true;

      // Payload sent in the request
      let req_body = {
        idExercise: -1,
        exerciseyear: this.yearexercise.toString(),
        exercisephase: this.phaseexercise.toString(),
        exercisenumber: this.numberexercise.toString(),
        exerciseparagraph: this.paragraphexercise.toString(),
        question: this.question.toString(),
        answer: this.answer.toString(),
        resolution: this.resolution.toString(),
        difficulty: this.value_dif.toString(),
        idCategory: this.value_cat.id.toString(),
        questionImage: this.questionImageUpload,
        answerImage: this.answerImageUpload,
        solutionImage: this.solutionImageUpload,
        scoreExercise: this.scoreExercise.toString(),
        totalScoreExam: this.totalScoreExam.toString(),
      };

      // ???
      if (this.test_debug) {
        this.save = false;
        return;
      }
      // POST Payload
      const config_response = await fetch(
        this.siteUrl + "/wp-json/jwt-auth/v1/archive-exam-exercise",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.cookies.get("jwt"),
          },
          body: JSON.stringify(req_body),
        }
      );

      // If result success clear all variables of form
      if (config_response.status == 200) {
        //console.log("Exercised Saved"); // TO DELETE - Diagnose Purposes
        // Clear Fields in UI
        this.paragraphexercise = "";
        this.phaseexercise = "";
        this.numberexercise = "";
        this.paragraphexercise = "";
        this.question = "",
        this.answer = "",
        this.resolution = "",
        this.value_dif = 'Selecione uma opção';
        this.value_cat = {'id': '-1', 'name': 'Selecione uma opção'};
        this.questionImageUpload = null,
        this.answerImageUpload = null,
        this.solutionImageUpload = null
      } else {
        console.log("Failed Saving");
      }

      this.save = false;
    },
    async go_exercises() {
      await this.router.push("/questions");
    },
    previewFiles(event) {
      console.log(event.target.files);
    },
    resizeQuestionBeforeUpload(event){
      this.resizeImageBeforeUpload(event, 1)
    },
    resizeAnswerBeforeUpload(event){
      this.resizeImageBeforeUpload(event, 2)
    },
    resizeSolutionBeforeUpload(event){
      this.resizeImageBeforeUpload(event, 3)
    },
    async resizeImageBeforeUpload(event, section) {
      // Code Here
      // from an input element

      //console.log("Event:"); // TO DELETE - Diagnose Purposes
      //console.log(event);  // TO DELETE - Diagnose Purposes
      
      var filesToUpload = event.target.files;
      var file = filesToUpload[0];
    
      var img = document.createElement("img");
      var reader = new FileReader();
      reader.onload = function (e)  {
        //console.log("Event e:"); // TO DELETE - Diagnose Purposes
        //console.log(e);  // TO DELETE - Diagnose Purposes
        img.src = e.target.result;
        //console.log("img:"); // TO DELETE - Diagnose Purposes
        //console.log(img);  // TO DELETE - Diagnose Purposes
      };
      reader.readAsDataURL(file);

      // Delay to allow image to load to variables of Javascript
      // /!\ - This must be fixed by other way 
      await new Promise(r => setTimeout(r, 2000));

      //console.log("Reader:"); // TO DELETE - Diagnose Purposes
      //console.log(reader);  // TO DELETE - Diagnose Purposes

      const canvas = document.createElement('canvas');

      //console.log("Canvas:"); // TO DELETE - Diagnose Purposes
      //console.log(canvas);  // TO DELETE - Diagnose Purposes

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var MAX_WIDTH = 800;
      var MAX_HEIGHT = 600;
      var width = img.width;
      var height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      //console.log("canvas width:"); // TO DELETE - Diagnose Purposes
      //console.log(canvas.width);  // TO DELETE - Diagnose Purposes
      //console.log("canvas height:"); // TO DELETE - Diagnose Purposes
      //console.log(canvas.height);  // TO DELETE - Diagnose Purposes
      //console.log("width:"); // TO DELETE - Diagnose Purposes
      //console.log(width);  // TO DELETE - Diagnose Purposes
      //console.log("height:"); // TO DELETE - Diagnose Purposes
      //console.log(height);  // TO DELETE - Diagnose Purposes
      
      var dataurl = canvas.toDataURL("image/png");

      //Post dataurl to the server with AJAX
      //console.log("Data URL:"); // TO DELETE - Diagnose Purposes
      //console.log(dataurl); // TO DELETE - Diagnose purposes
      
      switch(section) {
        case 1:
          this.questionImageUpload = dataurl;
          break;
        case 2:
          this.answerImageUpload = dataurl;
          break;
        case 3:
          this.solutionImageUpload = dataurl;
          break;
        default:
          this.questionImageUpload = dataurl;
      }
    },
    onPasteQuestion(evt) {
      this.onPaste(evt, 1);
    },
    onPasteAnswer(evt) {
      this.onPaste(evt, 2);
    },
    onPasteSolution(evt) {
      this.onPaste(evt, 3);
    },
    async onPaste (evt, section) {
      //console.log('on paste', evt);
      let items = evt.clipboardData.items;
      //console.log(items);
      //console.log(items[0]);
      //console.log(items.type);
      let i = 0;
      var img = document.createElement("img");
      var reader = null;
      if (items[i].type.indexOf("image") != -1){
        //console.log("Is image.");
        var blob = items[i].getAsFile();
        //console.log(blob);  // TO DELETE - Diagnose Purposes
        reader = new FileReader();
        reader.onload = function(event) {
            img.src = event.target.result;
        };
 
        reader.readAsDataURL(blob);
        // Delay to allow image to load to variables of Javascript
        // /!\ - This must be fixed by other way 
        await new Promise(r => setTimeout(r, 2000));

        //console.log("Reader:"); // TO DELETE - Diagnose Purposes
        //console.log(reader);  // TO DELETE - Diagnose Purposes

        const canvas = document.createElement('canvas');

        //console.log("Canvas:"); // TO DELETE - Diagnose Purposes
        //console.log(canvas);  // TO DELETE - Diagnose Purposes

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var MAX_WIDTH = 800;
        var MAX_HEIGHT = 600;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        //console.log("canvas width:"); // TO DELETE - Diagnose Purposes
        //console.log(canvas.width);  // TO DELETE - Diagnose Purposes
        //console.log("canvas height:"); // TO DELETE - Diagnose Purposes
        //console.log(canvas.height);  // TO DELETE - Diagnose Purposes
        //console.log("width:"); // TO DELETE - Diagnose Purposes
        //console.log(width);  // TO DELETE - Diagnose Purposes
        //console.log("height:"); // TO DELETE - Diagnose Purposes
        //console.log(height);  // TO DELETE - Diagnose Purposes
        
        var dataurl = canvas.toDataURL("image/png");

        //Post dataurl to the server with AJAX
        //console.log("Data URL:"); // TO DELETE - Diagnose Purposes
        //console.log(dataurl); // TO DELETE - Diagnose purposes
        switch(section) {
          case 1:
            this.questionImageUpload = dataurl;
            break;
          case 2:
            this.answerImageUpload = dataurl;
            break;
          case 3:
            this.solutionImageUpload = dataurl;
            break;
          default:
            this.questionImageUpload = dataurl;
        }
      }
    },
    removeQuestion(){
      this.questionImageUpload = null;
    },
    removeAnswer(){
      this.answerImageUpload = null;
    },
    removeSolution(){
      this.solutionImageUpload = null;
    },
    async resizeImageBlob(img, reader, blob) {
       reader.readAsDataURL(blob);
        // Delay to allow image to load to variables of Javascript
      // /!\ - This must be fixed by other way 
      await new Promise(r => setTimeout(r, 2000));

      //console.log("Reader:"); // TO DELETE - Diagnose Purposes
      //console.log(reader);  // TO DELETE - Diagnose Purposes

      const canvas = document.createElement('canvas');

      //console.log("Canvas:"); // TO DELETE - Diagnose Purposes
      //console.log(canvas);  // TO DELETE - Diagnose Purposes

      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);

      var MAX_WIDTH = 800;
      var MAX_HEIGHT = 600;
      var width = img.width;
      var height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      //console.log("canvas width:"); // TO DELETE - Diagnose Purposes
      //console.log(canvas.width);  // TO DELETE - Diagnose Purposes
      //console.log("canvas height:"); // TO DELETE - Diagnose Purposes
      //console.log(canvas.height);  // TO DELETE - Diagnose Purposes
      //console.log("width:"); // TO DELETE - Diagnose Purposes
      //console.log(width);  // TO DELETE - Diagnose Purposes
      //console.log("height:"); // TO DELETE - Diagnose Purposes
      //console.log(height);  // TO DELETE - Diagnose Purposes
      
      var dataurl = canvas.toDataURL("image/png");

      //Post dataurl to the server with AJAX
      //console.log("Data URL:"); // TO DELETE - Diagnose Purposes
      //console.log(dataurl); // TO DELETE - Diagnose purposes

      return dataurl;
    },
  },
};
</script>

<template>
  <v-overlay :model-value="loading" class="align-center justify-center">
    <v-progress-circular
    indeterminate
    size="64">
    </v-progress-circular>
  </v-overlay>

  <v-overlay v-model="overlay" class="align-center justify-center">
    <img v-bind:src="questionImg"/>
  </v-overlay>

    <v-container grid-list-md text-xs-center fluid align="center">
      <v-card class = "pa-2" max-width="600" align ="center">
        <v-container grid-list-md text-xs-center fluid align="center">
          <div>{{exerciseDescription}}</div>
          <div>
            <timer v-if="initExamExerciseTime!=null" :start-time="initExamExerciseTime"></timer>
        </div>
        </v-container>

        <v-img
        :width="600"
        aspect-ratio="16/9"
        cover
        v-bind:src="questionImg"
        @click="overlay = !overlay"
        ></v-img>

        <v-spacer />

        <v-container grid-list-md text-xs-center fluid align="center">
          <p></p>
            <!--
            <v-btn @click="answer" color="primary" elevation="2">
              Resposta <v-icon>mdi-skip-next</v-icon>
            </v-btn>
            <v-btn @click="skip" color="primary" elevation="2">
              Resposta <v-icon>mdi-skip-next</v-icon>
            </v-btn>
            -->
            <div>
              <v-row justify="center">
                <v-col cols="5" class="ml-4 mr-2">
                  <v-btn @click="answer" color="primary" block elevation="2">
                    Resposta&nbsp;<v-icon>mdi-skip-next</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="5" class="ml-4 mr-2">
                  <v-btn @click="skip" color="#D2D2D2" block elevation="2">
                  Saltar Pergunta &nbsp;<v-icon>mdi-skip-next</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <p></p>
            </div>
        </v-container>
        <div id='status-msg' class="login-msg my-4">{{message}}</div>
      </v-card>
    </v-container> 
</template>

<script>
import { onMounted, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";
import Timer from './Timer.vue';

export default {
  name: "ExamQuestionImage",
  components: {
    Timer,
  },
  props: {
    questionData: {
      type: Object, // Define the type of the prop
      required: true, // Set whether the prop is required or optional
    },
  },  
  data() {
    return {
      overlay: false,
    };
  },
  setup(props){ // Include props argument in Setup to be able to acces the props value inside setup
    const loading = ref(true);
    const router = useRouter();
    const store = useStore();
    const { cookies } = useCookies();
    store.dispatch('setCurrentScreen', 'Pergunta Exame');
    let examExerciseDetails = ref(null);
    let questionImg = ref(null);
    const initExamExerciseTime = ref(null);
    let exerciseDescription = ref("Exame ");

    let post_data = {
      examquestionid: parseInt(props.questionData.ongoingExerciseId)
    };

    onMounted(async () => {
      try {
          const exam_question_response = await fetch(
            store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/examquestion",
            {
              //method: "POST",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + cookies.get("jwt"),
              },
              body: JSON.stringify(post_data),
            }
          );
          const exam_question_content = await exam_question_response.json();


          if (exam_question_response.status == 200) {
            
            examExerciseDetails.value = exam_question_content['examdata']['examExerciseDetails'][0];

            questionImg.value = exam_question_content["examdata"]["examQuestionImage"];

            initExamExerciseTime.value = exam_question_content["examdata"]["initExamExerciseTime"];
            
            if(initExamExerciseTime.value == null && props.questionData.ongoingInitExamExerciseTime != null)
            {
              initExamExerciseTime.value = props.questionData.ongoingInitExamExerciseTime;
            }
            else if(initExamExerciseTime.value == null && exam_question_content['examdata']['examExerciseDetails'][0]['initExamExerciseTime'] != null)
            {
              initExamExerciseTime.value = exam_question_content['examdata']['examExerciseDetails'][0]['initExamExerciseTime'];
            }

            //let examExerciseData = exam_question_content['examdata']['examExerciseDetails'][0];

            //console.log(examExerciseDetails.value); // TO DELETE - Diagnose Purposes


            if(examExerciseDetails.value['year'] != null)
            {
              exerciseDescription.value = exerciseDescription.value + " de " + examExerciseDetails.value['year'] + ", ";
            }

            if(examExerciseDetails.value['phase'] != null)
            {
              exerciseDescription.value = exerciseDescription.value + " - " + examExerciseDetails.value['phase'] + "ª Fase. ";
            }

            if(examExerciseDetails.value['exerciseparagraph'] != null && examExerciseDetails.value['exerciseparagraph'] != "")
            {
              exerciseDescription.value = exerciseDescription.value + " Exercício " + examExerciseDetails.value['exerciseparagraph'] + ". ";
            }
            else if (examExerciseDetails.value['exercisenumber'] != null && examExerciseDetails.value['exercisenumber'] != "")
            {
              exerciseDescription.value = exerciseDescription.value + " Exercício " + examExerciseDetails.value['exercisenumber'] + ". ";
            }

            if(examExerciseDetails.value['category'] != null && examExerciseDetails.value['category'] != "")
            {
              exerciseDescription.value = exerciseDescription.value + " (" + examExerciseDetails.value['category'] + ").";
            }

            loading.value = false;
            await store.dispatch("setAuth", true);

          }
          else if (exam_question_response.status == 403) {
          await store.dispatch("setAuth", false);
          await router.push("/login");
        }
         
      } catch (e) {
        await store.dispatch("setAuth", false);
      }
    });

    return {
      loading,
      examExerciseDetails,
      questionImg,
      initExamExerciseTime,
      exerciseDescription,
    };
  },
  methods: {
    answer() {
      // Emit a custom event with a payload (value)
      //this.$emit("goto-exercise-answer", true);
      this.$emit("goto-exercise-answer", this.examExerciseDetails);
      // TODO: Also pass the this.initExamExerciseTime value
    },
    async skip() {
      //console.log("Skip!"); // TO DELETE - Diagnose Purposes

      const { cookies } = useCookies();

      this.loading = true;

      /*
      // /!\ - Fix it: ON FIRST RUN, DOESN'T WORK. e.g.:
      answerParameter: null
      examquestionid: 14
      initExerciseTime: null -> initExamExerciseTime
      mathClassicSessionId: null
      skippedValue: 1
      */

     //console.log(this.examExerciseDetails['mathclassiccessionId']); // TO DELETE - Diagnose Purposes

      const postData = reactive({
        mathClassicSessionId: this.examExerciseDetails['mathclassiccessionId'],
        answerParameter: null,
        initExerciseTime: this.initExamExerciseTime,
        examquestionid: this.$props.questionData.ongoingExerciseId,
        skippedValue: 1
      });

      //console.log(postData); // TO DELETE - Diagnose Purposes

      try {
        const response = await fetch(
          this.$store.state.projectConfigs.siteUrl +
            "/wp-json/jwt-auth/v1/skipexamquestion",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
            body: JSON.stringify(postData),
          }
        );

        const content = await response.json();

        // If status success (different from code 200)
        if (response.status == 200) {
          this.loading = false;
          document.getElementById("status-msg").style.color = "green";
          this.message = content;
          this.newMathClassic();
        }
        // If status unsuccess (code 200)
        else {
          document.getElementById("status-msg").style.color = "red";
          this.message = "Erro: " + content;
          this.loading = false;
        }
      } catch (e) {
        await this.$store.dispatch("setAuth", false);
      }
    },
    newMathClassic() {
      // Emit a custom event without a payload
      this.$emit("new-mathclassic");
      //console.log("new-mathclassic"); // TO DELETE - Diagnose Purposes
    },
  },    
}
</script>

<style scoped>
img { 
    width:100%; 
    height:100%; 
    } 
</style>
<template>
    <v-card class = "px-2 py-8 pl-3" max-width="600" align ="left">
        <v-card-title class="justify-center">Garante que passas no exame.</v-card-title>
        <v-card-text align="center">
            Desbloqueia o <b>Mathack SUPER</b> <span style="color:red;"><b>AGORA</b></span> por <strike>50€</strike> <span style="color:red; font-size:16px;"><b>37€</b></span> até aos exames! <br>
            <br>👉 Sabe que nota podes ter no exame.
            <br>👉 Sabe em que matérias precisas de trabalhar mais.
            <br>👉 Tem ajudas ilimitadas em todos os exercícios.
        </v-card-text>
        <v-row align="center" justify="center">
            <v-img
            src='@/assets/self-confidence-super.png'
            max-width="250px"
            cover
            aspect-ratio="1"
            class="my-n4"
            ></v-img>
        </v-row>
        <v-row align="center" justify="center">
            <v-btn @click="$router.push('/shopmathacksuper')" color="primary">
                Mathack <b>SUPER</b>
            </v-btn>
        </v-row>

    </v-card>    
</template>

<script>

</script>

<style scoped>
    .v-btn { 
        text-transform: none !important; 
    }
</style>

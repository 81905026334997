import { createStore } from 'vuex'
import * as configs from '../config/configsvuex'

export default createStore({
  state: {
    authenticated: false,
    currentScreen: 'Mathack',
    projectConfigs: configs
  },
  getters: {
    getAuth(state) {
      return state.authenticated;
    },
    getCurrentScreen(state) {
      return state.currentScreen;
    },
    getProjectConfigs(state) {
      return state.projectConfigs;
    }
  },
  mutations: {
    SET_AUTH(state, auth){
      // console.log("Previos state: " + this.state.authenticated + " SET_AUTH mutation: " + auth); // TO DELETE - Diagnose Purposes
      state.authenticated = auth;
      // console.log("After state: " + this.state.authenticated); // TO DELETE - Diagnose Purposes
    },
    SET_SCREEN_NAME(state, screenName) {
      state.currentScreen = screenName;
    }
  },
  actions: {
    setAuth(context, auth) {
      context.commit('SET_AUTH', auth);
    },
    setCurrentScreen(context, screenName) {
      context.commit('SET_SCREEN_NAME', screenName);
    }
  },
  modules: {
  }
})

<template>
  <v-overlay :model-value="loading" class="align-center justify-center">
    <v-progress-circular
    indeterminate
    size="64">
    </v-progress-circular>
  </v-overlay>

  <v-container grid-list-md text-xs-center fluid align="center">
    <v-card class="pa-2" max-width="600" align="center">
      <v-card-text v-if="userAccountType !== 'free'">
        Preparado(a) para arrasar nos exames nacionais? 🎓 O Mathack dá-te o controlo total para filtrar exercícios por tema, dificuldade e muito mais. Vais poder acompanhar tudo o que acertaste e o que precisas de rever. Acertaste? Espectáculo! 🌟 Erraste? Sem problema, cada erro é um passo mais perto do sucesso. Com cada tentativa, estás a aproximar-te da nota que desejas no exame! Bora lá? 🔍✏️
      </v-card-text>
      <v-card-text v-if="userAccountType === 'free'">
        Queres treinar para os exames nacionais com exercícios reais? 🎓 Com o Mathack Free, tens à tua disposição uma compilação de questões de exames anteriores para praticar e fortalecer as tuas habilidades matemáticas. Enfrenta cada questão como um desafio e uma chance para aprender mais. Cada passo é um aprendizado no caminho para alcançares os teus objetivos. Prepara-te, pratica e progride ao teu ritmo. Vamos lá?! 🔍✏️
      </v-card-text>

      <v-row no-gutters>
        <v-col>
          <v-container fluid >
            <div class="dropdown">
              <button class="btn btn-light dropdown-toggle" 
              type="button" 
              id="dropdownMenuCategory" 
              data-bs-toggle="dropdown" 
              aria-expanded="false">
              {{value_cat.name}}
              </button>
              <ul class="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuCategory" role="menu">
                <li v-for="cat in categories" :key="cat.id">
                  <a class="dropdown-item" @click="value_cat = cat">{{cat.name}}</a>
                </li>
              </ul>
            </div>
          </v-container>
        </v-col>
        <v-col md="1"></v-col>
        <v-col>
          <v-container fluid >
            <div class="dropdown">
              <button class="btn btn-light dropdown-toggle" 
              type="button" 
              id="dropdownMenuButton1" 
              data-bs-toggle="dropdown" 
              aria-expanded="false">
              Dificuldade: {{value_dif}}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" role="menu">
                <li v-for="option in difficulty_list" :key="option">
                  <a class="dropdown-item" @click="value_dif = option">{{option}}</a>
                </li>
              </ul>
            </div>
          </v-container>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-container fluid >
            <div class="dropdown">
              <button class="btn btn-light dropdown-toggle" 
              type="button" 
              id="dropdownMenuButton1" 
              data-bs-toggle="dropdown" 
              aria-expanded="false">
              Ano Exame: {{value_year}}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" role="menu">
                <li v-for="option in year_list" :key="option">
                  <a class="dropdown-item" @click="value_year = option">{{option}}</a>
                </li>
              </ul>
            </div>
          </v-container>
        </v-col>
        <v-col md="1"></v-col>
        <v-col>
          <v-container fluid >
            <div class="dropdown">
              <button class="btn btn-light dropdown-toggle" 
              type="button" 
              id="dropdownMenuButton1" 
              data-bs-toggle="dropdown" 
              aria-expanded="false">
              Fase: {{value_phase}}
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1" role="menu">
                <li v-for="option in phase_list" :key="option">
                  <a class="dropdown-item" @click="value_phase = option">{{option}}</a>
                </li>
              </ul>
            </div>
          </v-container>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col>
          <v-text-field 
          v-model="inputText" 
          label="A pergunta contem a palavra..." 
          ></v-text-field>
        </v-col>
      </v-row>

      <template v-if="userAccountType !== 'free'">
      <v-row justify="center">
        <v-col sm="3">
          <v-btn
            @click="searchExams"
            color="primary"
            elevation="2"
            class="mx-4"
          >
            <v-icon>mdi-magnify</v-icon>
            &nbsp;Procurar
          </v-btn>
        </v-col>
      </v-row>
      </template>

      <template v-if="userAccountType === 'free'">
      <v-row justify="center">
        <v-col sm="4">
          <v-btn
            @click="searchExams"
            color="primary"
            elevation="2"
            class="mx-4"
            :disabled="userAccountType === 'free'"
          >
            🔒 Procurar
          </v-btn>
        </v-col>
      </v-row>
      </template>

      <v-spacer />

      <v-table>
        <thead>
          <tr>
            <th class="text-center" v-if="false">#</th>
            <th class="text-center">Ano</th>
            <th class="text-center">Exercício</th>
            <th class="text-center">Categoria</th>
            <th class="text-center">Dificuldade</th>
            <th class="text-center">Multi. Escolha</th>
            <th class="text-center">Realizado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in displayedItems" :key="item.id" @click="selectRow(item)" 
          :class="{
            'selected-row': item === selectedRow,
            'selected-hovered-row': item === selectedRow && item === hoveredRow
          }"
          @mouseover="hoverRow(item)"
          @mouseleave="unhoverRow(item)">

            <td align ="center" v-if="false">{{ item.id }}</td>
            <td align ="center">{{ item.year }} ({{ item.phase }})</td>
            <td align ="center" v-if="item.exerciseparagraph == ''">{{ item.exercisenumber }}</td><td align ="center" v-else>{{ item.exercisenumber }}) {{ item.exerciseparagraph }}</td>
            <td align="center" style="font-size: smaller;">
              <template v-if="item.userAccountType === 'free'">
                Exclusivo Super 🔒
              </template>
              <template v-else>
                {{ item.category.join(", ") + (item.categorysec ? ", " + item.categorysec.join(", ") : "") }}
              </template>
            </td>
            <td align="center">{{ item.userAccountType === 'free' ? 'Exclusivo Super 🔒' : item.difficulty }}</td>
            <td align ="center" v-if="item.multiplechoice == 1">Sim</td><td align ="center" v-else>Não</td>
            <!--<td align ="center" v-if="item.lasttimedone !== null">Sim</td><td align ="center" v-else>Não</td>-->
            <!-- The color of the icon will change if last result was correct or incorrect -->
            <td align ="center">
              <v-icon 
                large 
                :color="item.userAccountType === 'free' ? 'grey' : (item.lasttimedone !== null ? (item.lasttimeresult === '1' ? 'green darken-2' : 'red darken-2') : 'grey')"
                :icon="item.lasttimedone !== null ? 'mdi-checkbox-marked-circle' : 'mdi-minus-box'">
              </v-icon>
              <!-- {{ item.userAccountType === 'free' ? '🔒' : '' }} --> <!-- Add Locker icon to column to sign that the result is locked for Super -->
            </td>
          </tr>
        </tbody>
      </v-table>

    <div class="text-center">  
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        :total-visible="5"
        @input="updateDisplayedItems"
      ></v-pagination>
    </div>

    <v-row justify="center">
        <v-col sm="8">
          <!--
          <v-btn
            :disabled="selectedRow===null"
            @click="performExercise"
            color="primary"
            elevation="2"
            class="mx-4"
          >
            <v-icon>mdi-eye</v-icon>
            &nbsp;Seleccionar Exercicio
          </v-btn>
          -->
          
          
            <!-- ... -->
            <ExamExerciseDetailsPopupVue 
            :title="currentPage"
            :content="totalPages"
            v-model:modelValue="showPopup"
            :questionData="selectedExamData"
            @selected-exercise-value="selectedExamExercise"
            ></ExamExerciseDetailsPopupVue>
            <v-btn 
            :disabled="selectedRow===null"
            @click="openPopup"
            color="primary"
            elevation="2"
            class="mx-4"
            > <v-icon>mdi-eye</v-icon>
            &nbsp;Ver detalhes do Exercício</v-btn>
            <!-- ... -->
          
        </v-col>
      </v-row>
      
    </v-card>
  </v-container>
</template>

<script>
import { useCookies } from "vue3-cookies";
import { onMounted, ref, reactive} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ExamExerciseDetailsPopupVue from "./ExamExerciseDetailsPopup.vue";

export default {
  components : {
    ExamExerciseDetailsPopupVue: ExamExerciseDetailsPopupVue
  },
  data() {
    return {
      headers: [
        { text: "id", value: "id" },
        { text: "year", value: "year" },
      ],
      items: [],
      currentPage: 1,
      itemsPerPage: 5,
      difficulty_list: ['Qualquer uma', '1', '2', '3'],
      value_dif: 'Qualquer uma',
      value_cat: {'id': '-1', 'name': 'Seleciona uma categoria'},
      year_list: ['Qualquer um', '2022', '2021', '2020'],
      value_year: 'Qualquer um',
      phase_list: ['Qualquer uma', '1', '2', 'Época especial'],
      value_phase: 'Qualquer uma',
      selectedRow: null, // Store the selected row object
      hoveredRow: null,
      showPopup: false,
    };
  },
  setup() {
    const router = useRouter();
    let loading = ref(true);
    const answers = reactive([]);
    const categories = reactive([]);
    const store = useStore();
    const { cookies } = useCookies();
    let siteUrl = store.state.projectConfigs.siteUrl;
    let selectedExamData = ref(null);
    let userAccountType = ref(null);
    onMounted(async () => {
      // /wp-json/jwt-auth/v1/token/validate
      try {
        //----- Authentication
        const response = await fetch(
          siteUrl + "/wp-json/jwt-auth/v1/examquestion",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
          }
        );
        //-----------------
          if (response.status == 200) {
            await store.dispatch("setAuth", true);
          }
          else if (response.status == 403) {
            await store.dispatch("setAuth", false);
            await router.push("/");
          }
      } 
      catch (e) {
        await store.dispatch("setAuth", false);
        await router.push("/login");
      }

      const config_response = await fetch(
        store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/user-category-config",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + cookies.get("jwt"),
          },  
        }
      );

      const config_content = await config_response.json();

      if (config_response.status == 200){
        loading.value = false;
        categories.push({id: '-1', name: 'Selecione uma categoria'});
        for (let i=0; i < config_content.length; i++){
          let namev = config_content[i]['name'];
          categories.push({id: config_content[i]['idCategory'], name: namev});
        }
      }
      else if (config_response.status == 500) {
        console.log("Couldn't load the category configurations.");
      }
      
    });
    return {
      loading,
      answers,
      categories,
      siteUrl,
      cookies,
      router,
      selectedExamData,
      userAccountType,
      inputText: '', // This property contains the text that the user wants to search for in the question
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage
      return this.items.slice(startIndex, endIndex)
    },
  },
  mounted() {
    this.searchExams();
  },
  methods: {
    async searchExams() {
      const { cookies } = useCookies();

      this.loading = true;

      this.currentPage = 1;

      let post_data = {
        category: parseInt(this.value_cat.id)<1?null:parseInt(this.value_cat.id),
        difficulty: this.value_dif==='Qualquer uma'?null:parseInt(this.value_dif),
        yearExam: this.value_year==='Qualquer um'?null:parseInt(this.value_year),
        phaseExam: this.value_phase==='Qualquer uma'?null:(this.value_phase==='Época especial'?3:parseInt(this.value_phase)),
        // ternary expression if this.inputText is empty pass null, else pass text value
        textInQuestion: this.inputText===''?null:this.inputText,
      };

      try {
        const response = await fetch(
          this.$store.state.projectConfigs.siteUrl +
            "/wp-json/jwt-auth/v1/searchexamquestion",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
            body: JSON.stringify(post_data),
          }
        );
        const data = await response.json();
        this.items = data;
        this.loading = false;
        this.userAccountType = data[0].userAccountType;
      } catch (error) {
        console.error(error);
      }
    },
    updateDisplayedItems() {
      // Handle updating the displayed items based on the current page
    },
    selectRow(item) {
      if (this.selectedRow == null)
      {
        this.selectedRow = item; // Set the selected row object
      }
      else
      {
        this.selectedRow = null;
      }
    },
    performExercise() {
      // Emit a custom event with a payload (value)
      this.$emit("selected-exercise-value", this.selectedRow.id);
      this.selectedRow = null;
    },
    hoverRow(item) {
      this.hoveredRow = item;
    },
    unhoverRow() {
      this.hoveredRow = null;
    },
    async openPopup() {
      const { cookies } = useCookies();

      this.showPopup = true;
      //console.log(this.selectedRow.id); // TO DELETE - Diagnose Purposes
      //console.log(this.items); // TO DELETE - Diagnose Purposes
      // If there is no selected row, nothing should happen. Create condition here.
      this.selectedExamData = this.items.find(items => items.id === this.selectedRow.id);
      //console.log(this.selectedExamData); // TO DELETE - Diagnose Purposes
      this.selectedRow = null;


      try {

        const postData = reactive({
          examquestionid: this.selectedExamData.id,
        });

        await fetch(
          this.$store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/examdetailspopup",
          {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
            body: JSON.stringify(postData)
          }
        );
      } catch (e) {
        console.log(e);
      }

    },
    selectedExamExercise(value) {
      // Do something with the value
      //->this.currentExamData.ongoingExerciseId = value;
      //this.selectedExamExerciseId = value;
      // Emit a custom event with a payload (value)
      this.$emit("selected-exercise-value", value);
      this.showPopup = false;
      this.selectedRow = null;
    },
  },
};
</script>

<style scoped>
  .selected-row {
    background-color: #4780ae60 !important;
  }
  .selected-hovered-row {
    background-color: #4780AE !important;
  }
</style>

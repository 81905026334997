<template>
    <div>
        <v-container grid-list-md text-xs-center fluid align="center">
            <v-card class = "pa-2" max-width="600" align ="left">
                <v-card-title style="font-size: 16px; hyphens: none;">
                    Adorávamos ouvir o que tens a dizer sobre o Mathack. Levamos o teu feedback a sério. 
                    <br>Dá-nos só 5 minutos do teu tempo e responde a estas 8 perguntas. Em troca oferecemos-te mais 2 dias de Mathack Super.
                </v-card-title>
                <v-divider></v-divider>
                <!-- QUESTION -->
                <v-card-text>
                    <span id="questOne" style="font-size: 18px;font-weight:bold">1) Em que alturas costumas usar mais o Mathack?</span>
                    <span style="font-size: 12px"><br>(Escolhe 1 ou mais opções)</span>
                </v-card-text>
                <v-list>
                    <v-list-item-group v-model="qgroup1">
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupOne" value="G:01-Q:A" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Ao computador (ou tablet), quando quero fazer uma sessão de estudo.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupOne" value="G:01-Q:B" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Ao telemóvel, quando quero fazer uma sessão de estudo.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupOne" value="G:01-Q:C" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Em tempos livres ou quando estou sem fazer nada, no telemóvel.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupOne" value="G:01-Q:D" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Em tempos livres ou quando estou sem fazer nada, no computador (ou tablet).
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                    </v-list-item-group>                    
                </v-list>
                <!-- END QUESTION CODE SEGMENT --->
                <!-- NEW QUESTION GROUP 2-->
                <v-divider></v-divider>
                <!-- QUESTION 2 -->
                <v-card-text>
                    <span id="questTwo" style="font-size: 18px;font-weight:bold">2) O que achas dos exercícios do Mathack?</span>
                    <span style="font-size: 12px"><br>(Escolhe 1 opção)</span>
                </v-card-text>
                <v-list>
                    <v-list-item-group v-model="qgroup2">
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupTwo" value="G:02-Q:A" @change="ckboxUpdateGroupTwo" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Prefiro os exercícios do Mathblitz.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupTwo" value="G:02-Q:B" @change="ckboxUpdateGroupTwo" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Prefiro os exercícios do Mathclassic.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupTwo" value="G:02-Q:C" @change="ckboxUpdateGroupTwo" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Sinto que não me preparam para o exame.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupTwo" value="G:02-Q:D" @change="ckboxUpdateGroupTwo" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Em geral acho muito fáceis.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupTwo" value="G:02-Q:E" @change="ckboxUpdateGroupTwo" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Em geral acho muito difíceis.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupTwo" value="G:02-Q:F" @change="ckboxUpdateGroupTwo" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Adoro o formato como está.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupTwo" value="G:02-Q:G" @change="ckboxUpdateGroupTwo" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Outro:
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="groupTwoDesc"
                                label="Descrever..."
                            ></v-text-field>
                        </v-list-item>
                        <!-- Survey Question -->
                    </v-list-item-group>                    
                </v-list>
                <!-- NEW QUESTION GROUP 3--------------------------START CODE SEGMENT-->
                <v-divider></v-divider>
                <!-- GROUP 3 -->
                <v-card-text>
                    <span id="questThree" style="font-size: 18px;font-weight:bold">3) O que achas da funcionalidade de prever a nota do exame?</span>
                    <span style="font-size: 12px"><br>(Escolhe 1 opção)</span>
                </v-card-text>
                <v-list>
                    <v-list-item-group v-model="qgroup3">
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupThree" value="G:03-Q:A" @change="ckboxUpdateGroupThree" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Fantástica 🤩 ajuda-me imenso.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupThree" value="G:03-Q:B" @change="ckboxUpdateGroupThree" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Dá jeito saber, mas não é a principal razão para eu usar o Mathack.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupThree" value="G:03-Q:C" @change="ckboxUpdateGroupThree" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Tenho algumas dúvidas sobre o valor que apresenta.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupThree" value="G:03-Q:D" @change="ckboxUpdateGroupThree" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Não me faz diferença.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupThree" value="G:03-Q:E" @change="ckboxUpdateGroupThree" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Outro:
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="groupThreeDesc"
                                label="Descrever..."
                            ></v-text-field>
                        </v-list-item>
                        <!-- Survey Question -->
                    </v-list-item-group>                    
                </v-list>
                <!-- END QUESTION CODE SEGMENT --->
                <!-- NEW QUESTION GROUP 4--------------------------START CODE SEGMENT-->
                <v-divider></v-divider>
                <!-- GROUP 4 -->
                <v-card-text>
                    <span id="questFour" style="font-size: 18px;font-weight:bold">4) Usas o Mathack como fonte principal de estudo ou como suplemento a outros materiais?</span>
                    <span style="font-size: 12px"><br>(Escolhe 1 opção)</span>
                </v-card-text>
                <v-list>
                    <v-list-item-group v-model="qgroup4">
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupFour" value="G:04-Q:A" @change="ckboxUpdateGroupFour" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Como fonte principal de estudo.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupFour" value="G:04-Q:B" @change="ckboxUpdateGroupFour" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Como suplemento a outros materiais de estudo.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>                    
                </v-list>
                <!-- END QUESTION CODE SEGMENT --->
                <!-- NEW QUESTION GROUP 5--------------------------START CODE SEGMENT-->
                <v-divider></v-divider>
                <v-card-text>
                    <span id="questFive" style="font-size: 18px;font-weight:bold">5) Que alternativas ao Mathack mais fazes uso?</span>
                    <span style="font-size: 12px"><br>(Escolhe 1 ou mais opções)</span>
                </v-card-text>
                <v-list>
                    <v-list-item-group v-model="qgroup5">
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupFive" value="G:05-Q:A" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Vídeos no YouTube.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupFive" value="G:05-Q:B" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Explicador.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupFive" value="G:05-Q:C" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Exames antigos.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupFive" value="G:05-Q:D" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Livros de preparação de exame.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupFive" value="G:05-Q:E" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Manual Escolar.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupFive" value="G:05-Q:F" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Outro software semelhante ao Mathack.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                    </v-list-item-group>                    
                </v-list>
                <!-- END QUESTION CODE SEGMENT --->
                <!-- NEW QUESTION GROUP 6--------------------------START CODE SEGMENT-->
                <v-divider></v-divider>
                <!-- GROUP 6 -->
                <v-card-text>
                    <span id="questSix" style="font-size: 18px;font-weight:bold">6) Se pudesses escolher a próxima funcionalidade a ser incorporada no Mathack, qual escolherias? </span>
                    <span style="font-size: 12px"><br>(Escolhe 1 opção)</span>
                </v-card-text>
                <v-list>
                    <v-list-item-group v-model="qgroup6">
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupSix" value="G:06-Q:A" @change="ckboxUpdateGroupSix" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Mais detalhes na tabela de ranking para ver como te comparas com outros Mat Hackers.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupSix" value="G:06-Q:B" @change="ckboxUpdateGroupSix" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Uma previsão de nota para o Matclassic, baseada nos exercícios de exame que fizeste.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupSix" value="G:06-Q:C" @change="ckboxUpdateGroupSix" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Resolução/Explicações disponíveis para todas as perguntas.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupSix" value="G:06-Q:D" @change="ckboxUpdateGroupSix" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    A opção de te conectares com amigos no Mathack e estudarem juntos dentro da app.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupSix" value="G:06-Q:E" @change="ckboxUpdateGroupSix" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    A capacidade de comentar nas questões do Mathclassic e partilhar insights com a comunidade do Mathack.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupSix" value="G:06-Q:F" @change="ckboxUpdateGroupSix" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Nenhuma, está bem assim.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupSix" value="G:06-Q:G" @change="ckboxUpdateGroupSix" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Outra:
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="groupSixDesc"
                                label="Descrever..."
                            ></v-text-field>
                        </v-list-item>
                        <!-- Survey Question -->
                    </v-list-item-group>                    
                </v-list>
                <!-- END QUESTION CODE SEGMENT --->
                <!-- NEW QUESTION GROUP 4--------------------------START CODE SEGMENT-->
                <v-divider></v-divider>
                <!-- GROUP 7 -->
                <v-card-text>
                    <span id="questSeven" style="font-size: 18px;font-weight:bold">7) O que gostas menos no Mathack?</span>
                    <span style="font-size: 12px"><br>(Escolhe 1 opção)</span>
                </v-card-text>
                <v-list>
                    <v-list-item-group v-model="qgroup7">
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupSeven" value="G:07-Q:A" @change="ckboxUpdateGroupSeven" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    É complicado de usar.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupSeven" value="G:07-Q:B" @change="ckboxUpdateGroupSeven" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Tem poucas funcionalidades.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                                                <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupSeven" value="G:07-Q:C" @change="ckboxUpdateGroupSeven" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    O aspeto gráfico.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                                                <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupSeven" value="G:07-Q:D" @change="ckboxUpdateGroupSeven" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Não é prático.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupSeven" value="G:07-Q:E" @change="ckboxUpdateGroupSeven" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Outra:
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="groupSevenDesc"
                                label="Descrever..."
                            ></v-text-field>
                        </v-list-item>
                        <!-- Survey Question -->
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupSeven" value="G:07-Q:F" @change="ckboxUpdateGroupSeven" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Gosto de tudo igualmente.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                    </v-list-item-group>                    
                </v-list>
                <!-- END QUESTION CODE SEGMENT --->
                <!-- NEW QUESTION GROUP 8--------------------------START CODE SEGMENT-->
                <v-divider></v-divider>
                <!-- GROUP 8 -->
                <v-card-text>
                    <span id="questEight" style="font-size: 18px;font-weight:bold">8) Sentes-te motivado para partilhar o Mathack com os teus amigos ou colegas?</span>
                    <span style="font-size: 12px"><br>(Escolhe 1 opção)</span>
                </v-card-text>
                <v-list>
                    <v-list-item-group v-model="qgroup8">
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupEight" value="G:08-Q:A" @change="ckboxUpdateGroupEight" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Claro! Vou dizer a todas as pessoas que conheço.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupEight" value="G:08-Q:B" @change="ckboxUpdateGroupEight" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Sim, conheço uma pessoa ou outra que deve gostar.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupEight" value="G:08-Q:C" @change="ckboxUpdateGroupEight" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Ainda estou a tentar perceber a utilidade da aplicação.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupEight" value="G:08-Q:D" @change="ckboxUpdateGroupEight" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Nem por isso.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                        <!-- Survey Question -->
                        <v-list-item>
                            <v-list-item-avatar start>
                                <v-checkbox v-model="questionGroupEight" value="G:08-Q:E" @change="ckboxUpdateGroupEight" hide-details></v-checkbox>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap" style="hyphens: none;">
                                    Vou dizer para não usarem 😕.
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!-- Survey Question -->
                    </v-list-item-group>                    
                </v-list>
                <!-- END QUESTION CODE SEGMENT --->

                <!-- SUBMIT BUTTON--------------------------START CODE SEGMENT-->
                <v-divider></v-divider>
                <v-card-text></v-card-text>
                <v-row align="center" justify="center">
                    <v-btn v-if="formContinue==false" @click="submitSurvey" color="primary" elevation = "2">Enviar Respostas</v-btn>
                    <v-btn v-if="formContinue==true" @click="$router.push('/')" color="primary" elevation = "2">Continuar</v-btn>
                </v-row>
                <div id='status-msg' class="login-msg my-4" align="center" justify="center">{{message}}</div>
                <!-- SUBMIT BUTTON--------------------------END CODE SEGMENT-->
                <br><br>Obrigado. <br>A equipa do Mathack.
            </v-card>
        </v-container>
    </div>   
    
    <v-overlay :model-value="loading" class="align-center justify-center">
        <v-progress-circular indeterminate size="64"> </v-progress-circular>
    </v-overlay> 
</template>

<script>
import { onMounted, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";

export default {
    data () {
        return {
            selected: [],
            questionGroupOne: [],
            questionGroupTwo: [],
            groupTwoDesc: '',
            questionGroupThree: [],
            groupThreeDesc: '',
            questionGroupFour: [],
            questionGroupFive: [],
            questionGroupSix: [],
            groupSixDesc: '',
            questionGroupSeven: [],
            groupSevenDesc: '',
            questionGroupEight: [],
        }
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const { cookies } = useCookies();

        let formContinue = ref(false);
        const message = ref(' ');

        let loading = ref(true);

        onMounted(async () => {
        // /wp-json/jwt-auth/v1/token/validate
        try {
                loading.value = true;
                const response = await fetch(
                    store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/trialsurvey",
                    {
                        method: "GET",
                        headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + cookies.get("jwt"),
                        },
                    }
                );
                
                //const content = await response.json();

                // If status unsuccess (different from code 200)
                if (response.status != 200) {
                    await store.dispatch("setAuth", false);
                    //console.log("The status of request in Trial Survey is: " + response.status); // TO DELETE - Diagnose purposes
                    loading.value = false;
                    //if(response.status == 200)  // TO DELETE - To test the form
                    //{   // TO DELETE - To test the form
                    await router.push("/");
                    //}   // TO DELETE - To test the form
                    
                }
                // If status success (code 200)
                else
                {
                    //console.log("The status of request in Home is: " + response.status); // TO DELETE - Diagnose purposes
                    loading.value = false;
                }
            } catch (e) {
                await store.dispatch("setAuth", false);
            }
        });

        return {
            formContinue,
            message,
            loading
        }
    },
    methods: {
        async submitSurvey(){
            const { cookies } = useCookies();

            this.selected = [];
            this.selected = this.selected.concat(this.questionGroupOne, this.questionGroupTwo, this.questionGroupThree, this.questionGroupFour, this.questionGroupFive, this.questionGroupSix, this.questionGroupSeven, this.questionGroupEight);
            //console.log(this.selected);

            this.loading = true;

            document.getElementById('status-msg').style.color = "black";
            this.message = ' ';
            //Validate fields
            // Question One
            let missingFieldFlag = false;
            if (this.questionGroupOne.length == 0){
                missingFieldFlag = true;
                document.getElementById('questOne').style.color = "red";
            }
            else{document.getElementById('questOne').style.color = "black";}
            // Question Two
            if (this.questionGroupTwo.length == 0){
                missingFieldFlag = true;
                document.getElementById('questTwo').style.color = "red";
            }
            else{document.getElementById('questTwo').style.color = "black";}
            // Question Three
            if (this.questionGroupThree.length == 0){
                missingFieldFlag = true;
                document.getElementById('questThree').style.color = "red";
            }
            else{document.getElementById('questThree').style.color = "black";}
            // Question Four
            if (this.questionGroupFour.length == 0){
                missingFieldFlag = true;
                document.getElementById('questFour').style.color = "red";
            }
            else{document.getElementById('questFour').style.color = "black";}
            // Question Five
            if (this.questionGroupFive.length == 0){
                missingFieldFlag = true;
                document.getElementById('questFive').style.color = "red";
            }
            else{document.getElementById('questFive').style.color = "black";}
            // Question Six
            if (this.questionGroupSix.length == 0){
                missingFieldFlag = true;
                document.getElementById('questSix').style.color = "red";
            }
            else{document.getElementById('questSix').style.color = "black";}
            // Question Seven
            if (this.questionGroupSeven.length == 0){
                missingFieldFlag = true;
                document.getElementById('questSeven').style.color = "red";
            }
            else{document.getElementById('questSeven').style.color = "black";}
            // Question Eight
            if (this.questionGroupEight.length == 0){
                missingFieldFlag = true;
                document.getElementById('questEight').style.color = "red";
            }
            else{document.getElementById('questEight').style.color = "black";}


            if(missingFieldFlag == true)
            {
                document.getElementById('status-msg').style.color = "red";
                this.message = 'Por favor revê as tuas respostas. Parece que te faltou preencher alguma.';
                this.loading = false;
                return;
            }
            
            const postData = reactive({
                formQuestionsSelect: this.selected,
                groupTwoDescAnswer: this.groupTwoDesc,
                groupThreeDescAnswer: this.groupThreeDesc,
                groupSixDescAnswer: this.groupSixDesc,
                groupSevenDescAnswer: this.groupSevenDesc,
            });

            
            try {
            const response = await fetch(
                this.$store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/trialsurvey",
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + cookies.get("jwt"),
                    },
                    body: JSON.stringify(postData)
                }
            );

            //const content = await response.json();
            //console.log(postData); // TO DELETE - Diagnose Purposea
            //console.log(response.status); // TO DELETE - Diagnose Purposes

            const content = await response.json();
            
            // If status unsuccess (different from code 200)
            if(response.status == 405){    
                this.loading = false;
                //console.log("Response: " + content); // TO DELETE - Diagnose Purposes
                document.getElementById('status-msg').style.color = "red";
                this.message = '' + content; 
                // Change Button Submit to continue
                this.formContinue = true;
            }
            // If status unsuccess (different from code 200)
            if(response.status != 200){    
                this.loading = false;
                //console.log("Response: " + content); // TO DELETE - Diagnose Purposes
                document.getElementById('status-msg').style.color = "red";
                this.message = '' + content; 
            }
            // If status success (code 200)
            else
            {
                this.loading = false;
                //console.log("Response: " + content); // TO DELETE - Diagnose Purposes
                document.getElementById('status-msg').style.color = "green";
                this.message = '' + content; 
                // Change Button Submit to continue
                this.formContinue = true;
            }
            
            } catch (e) {
                await this.$store.dispatch("setAuth", false);
            }
        },
        ckboxUpdateGroupTwo(e){
            this.questionGroupTwo = [];
            if (e.target.checked) {
                this.questionGroupTwo.push(e.target.value);
            }
        },
        ckboxUpdateGroupThree(e){
            this.questionGroupThree = [];
            if (e.target.checked) {
                this.questionGroupThree.push(e.target.value);
            }
        },
        ckboxUpdateGroupFour(e){
            this.questionGroupFour = [];
            if (e.target.checked) {
                this.questionGroupFour.push(e.target.value);
            }
        },
        ckboxUpdateGroupSix(e){
            this.questionGroupSix = [];
            if (e.target.checked) {
                this.questionGroupSix.push(e.target.value);
            }
        },
        ckboxUpdateGroupSeven(e){
            this.questionGroupSeven = [];
            if (e.target.checked) {
                this.questionGroupSeven.push(e.target.value);
            }
        },
        ckboxUpdateGroupEight(e){
            this.questionGroupEight = [];
            if (e.target.checked) {
                this.questionGroupEight.push(e.target.value);
            }
        },
    },
}

</script>

<style scoped>
    .v-btn { 
        text-transform: none !important; 
    }
    .v-text-field{
        width: 220px;
    }
</style>

<template>
  <div class="text-center">
    <v-dialog class="custom-dialog-class" v-model="dialog" width="auto">
      <v-container grid-list-md text-xs-center fluid align="center">
      <v-card max-width="600" align="left">
        
        <div class="dialog-content" @scroll="handleScroll">
          <table class="table">
            <thead>
              <!-- Table Head is only used to create black line on top of first row -->
              <tr><th colspan="2">Detalhes</th></tr>
            </thead>
            <tbody>
              <tr>
                <td style="vertical-align: middle">Ano do Exame</td>
                <td style="vertical-align: middle">{{ questionData.year }}</td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Fase</td>
                <td style="vertical-align: middle">{{ questionData.phase }}ª Fase</td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Exercício</td>
                <td style="vertical-align: middle">{{ buildExerciseTitle }}</td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Categoria Principal</td>
                <td style="vertical-align: middle">
                  <template v-if="questionData.userAccountType === 'free'">
                    Exclusivo Super 🔒
                  </template>
                  <template v-else>
                    <div>
                      <p class="exercise-categories" v-for="(item, index) in questionData.category" :key="index">{{ item }}</p>
                    </div>
                  </template>
                </td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Categoria Secundária</td>
                <td style="vertical-align: middle">
                  <template v-if="questionData.userAccountType === 'free'">
                    Exclusivo Super 🔒
                  </template>
                  <template v-else>
                    <div>
                      <p class="exercise-categories" v-for="(item, index) in questionData.categorysec" :key="index">{{ item }}</p>
                    </div>
                  </template>
                </td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Grau de dificuldade</td>
                <td style="vertical-align: middle">{{ questionData.userAccountType === 'free' ? 'Exclusivo Super 🔒' : questionData.difficulty }}</td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Multipla Escolha</td>
                <td style="vertical-align: middle" v-if="questionData.multiplechoice == 1">Sim</td><td style="vertical-align: middle" v-else>Não</td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Número de vezes realizado</td>
                <td style="vertical-align: middle">{{ questionData.userAccountType === 'free' ? 'Exclusivo Super 🔒' : questionData.totalTimesDone }}</td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Última vez em</td>
                <td style="vertical-align: middle">{{ questionData.lasttimedone }}</td>
              </tr>
              <tr>
                <td style="vertical-align: middle">Resultado</td>
                <td style="vertical-align: middle">
                  <template v-if="questionData.userAccountType === 'free'">
                    Exclusivo Super 🔒
                  </template>
                  <template v-else>
                    <span v-if="questionData.lasttimeresult == 1">Correto</span>
                    <span v-else-if="questionData.lasttimeresult == 0">Incorreto</span>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="showScrollHint" class="scroll-hint">
            Desliza para ver mais<v-icon>mdi-arrow-down</v-icon>
          </div>
        </div>
        
        <v-card-actions>
          <v-btn color="primary" block @click="performExercise"
            >Realizar Exercício</v-btn
          >
        </v-card-actions>
        <v-card-actions>
        <v-btn color="primary" block @click="dialog = false">Voltar</v-btn>
        </v-card-actions>
      </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    content: String,
    modelValue: Boolean,
    questionData: {
      type: Object, // Define the type of the prop
      required: true, // Set whether the prop is required or optional
    },
  },
  data() {
    return {
      showScrollHint: true
    }
  },
  computed: {
    dialog: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    buildExerciseTitle() {
      // Access the prop values and concatenate them here
      let exerciseDescription = "";

      if (
        this.questionData.exerciseparagraph != null &&
        this.questionData.exerciseparagraph != ""
      ) {
        exerciseDescription =
          exerciseDescription + this.questionData.exerciseparagraph;
      } else if (
        this.questionData.exercisenumber != null &&
        this.questionData.exercisenumber != ""
      ) {
        exerciseDescription =
          exerciseDescription + this.questionData.exercisenumber;
      }

      //console.log(this.questionData.category);  // TO DELETE - Diagnose Purposes
      
      return exerciseDescription;
    },
    formattedText() {
      return this.myArray.length === 1 
        ? this.myArray[0] 
        : this.myArray.join(', ');
    },
  },
  watch: {
    modelValue(newVal) {
      if (newVal) {
        this.showScrollHint = true;
      }
    },
  },
  methods: {
    performExercise() {
      // Emit a custom event with a payload (value)
      this.$emit("selected-exercise-value", this.$props.questionData);
    },
    handleScroll() {
      this.showScrollHint = false;  // hide scroll hint when user starts scrolling
    },
  },
};
</script>
<style scoped>
.exercise-categories {
  margin: 0;
}

.dialog-content {
  max-height: 400px;  /* set a maximum height as per your needs */
  overflow-y: auto;  /* allow vertical scrolling if the content exceeds the max-height */
}

.scroll-hint {
  position: absolute;
  bottom: 105px;  /* position hint above the bottom of the v-card-text */
  width: 100%;
  text-align: center;
  background: rgba(255, 255, 255, 0.8);  /* semi-transparent background */
}

.custom-dialog-class .v-dialog {
  display: flex;
  align-items: center; /* This ensures vertical centering */
  justify-content: center; /* This ensures horizontal centering */
}

.dialog-content {
  max-height: 50vh; /* Use viewport height for better responsiveness */
  overflow-y: auto;
}
</style>

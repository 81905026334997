<template>
  <div>
    {{ formatElapsedTime }}
  </div>
</template>

<script>
export default {
  props: {
    startTime: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      elapsedTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    };
  },
  mounted() {
    this.startTimer();
  },
  computed: {
    formatElapsedTime() {
      const { days, hours, minutes, seconds } = this.elapsedTime;

      let formattedTime = '';

      if (days > 0) {
        formattedTime += `${days}d `;
        formattedTime += `${this.formatTimeUnit(hours)}h `;
        formattedTime += `${this.formatTimeUnit(minutes)}m `;
        formattedTime += `${this.formatTimeUnit(seconds)}s`;
      } else {
        if (hours > 0) {
          formattedTime += `${hours}h `;
          formattedTime += `${this.formatTimeUnit(minutes)}m `;
          formattedTime += `${this.formatTimeUnit(seconds)}s`;
        } else {
          if (minutes > 0) {
            formattedTime += `${minutes}m `;
            formattedTime += `${this.formatTimeUnit(seconds)}s`;
          } else {
            formattedTime += `${seconds}s`;
          }
        }
      }

      return formattedTime;
    },
  },
  methods: {
    formatTimeUnit(unit) {
      return unit < 10 ? `0${unit}` : unit;
    },
    startTimer() {
      const startDateUTC = new Date(this.startTime);

      const startDateLocal = new Date(startDateUTC.getTime() - startDateUTC.getTimezoneOffset() * 60000);

      if (!isNaN(startDateLocal.getTime())) {
        setInterval(() => {
          const currentTime = new Date();
          const timeDiff = currentTime - startDateLocal;

          const seconds = Math.floor(timeDiff / 1000);
          const minutes = Math.floor(seconds / 60);
          const hours = Math.floor(minutes / 60);
          const days = Math.floor(hours / 24);

          this.elapsedTime = {
            days: days > 0 ? days : 0,
            hours: hours > 0 ? hours % 24 : 0,
            minutes: minutes > 0 ? minutes % 60 : 0,
            seconds: seconds % 60,
          };
        }, 1000);
      } else {
        console.error('Invalid start time format!');
      }
    },
  },
};
</script>

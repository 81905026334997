<template>
<div class="form-signin">
    <form @submit.prevent="submit">
        <v-img
          :src="require('../assets/mathack_logo_1500x300.png')"
          alt="" 
          class="my-3"
          contain
          width="300"
          height="100"
        />
        <h1 class="h3 mb-3 fw-normal text-center">Definir nova password</h1>

        <div class="form-floating">
        <input v-model="data.email" type="email" class="form-control" placeholder="name@example.com">
        <label>Email</label>
        </div>
        <div class="form-floating">
        <input v-model="data.pwresetcode" type="text" class="form-control" placeholder="Codigo_Recuperacao">
        <label>Código de Recuperação</label>
        </div>
        <div class="form-floating">
        <input v-model="data.newpassword" type="password" class="form-control" placeholder="Password">
        <label>Password</label>
        <div class="form-floating">
        <input v-model="data.confirm_password" type="password" class="form-control" placeholder="Password">
        <label>Confirmar Password</label>
        </div>
        </div>
        <button class="mt-1 w-100 btn btn-primary text-light" type="submit">Submeter</button>
        <div id='status-msg' class="login-msg my-4">{{message}}</div>
        <p class="mt-5 mb-3 text-muted">&copy; 2020–2022</p>
    </form>
</div>  

<v-overlay :model-value="loading" class="align-center justify-center">
  <v-progress-circular
    indeterminate
    size="64">
  </v-progress-circular>
</v-overlay>
</template>

<script>
import {reactive} from 'vue';   // Import reactive
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "PasswordReset",
    setup() {
        const data = reactive({
            email: '',
            pwresetcode: '',
            newpassword: '',
            confirm_password: ''
        });

        const message = ref(' ');
        const store = useStore();
        const loading = ref(false);

        const submit = async() => {
            
            document.getElementById('status-msg').style.color = "black";
            //Validate fields
            let p_m = data.newpassword.match("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");
            if (!p_m){
                message.value = 'A password tem de ter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minuscula e 1 número.';
                return;
            }
            
            if (data.newpassword != data.confirm_password){
                message.value = 'As duas passwords têm de ser iguais \n';
                return;
            }
            let em = data.email.match("([a-zA-Z0-9\\_\\-\\.]+)@([a-zA-Z]+).(.+)");
            if (!em){
                message.value = 'Por favor insira um email válido';
                return;
            }

            message.value = '';

            let send_data = {
                email: data.email,
                pwresetcode: data.pwresetcode,
                newpassword: data.newpassword
            };

            loading.value = true;
            const response = await fetch(store.state.projectConfigs.siteUrl + '/wp-json/jwt-auth/v1/resetpassword', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify(send_data)
            });

            const content = await response.json();

            //console.log(content);   // TO DELETE - Diagnose Purposes

            // If status success (code 200)
            if(response.status != 200){    
                loading.value = false;             
                document.getElementById('status-msg').style.color = "red";
                //message.value = 'Não foi possível alterar a password.'
                message.value = 'Erro: ' + content;
            }
            else{
                loading.value = false;
                data.email = ''; data.pwresetcode = ''; data.newpassword = ''; data.confirm_password = '';
                document.getElementById('status-msg').style.color = "green";
                message.value = content;
                setTimeout(() => {}, 6000);
            }

        }

        return {
            data,
            submit,
            loading,
            message
        }
    }
}

</script>

<style scoped>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
</style>

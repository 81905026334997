<template>
<div class="form-signin">
    <form @submit.prevent="submit">
        <v-img
          :src="require('../assets/mathack_logo_1500x300.png')"
          alt="" 
          class="my-3"
          contain
          width="300"
          height="100"
        />
        <h1 class="h4 mb-3 fw-normal text-center">Introduz o teu email para recuperares a password</h1>

        <div class="form-floating">
        <input v-model="data.email" type="email" class="form-control" placeholder="name@example.com">
        <label>Email</label>
        </div>
        <button class="mt-1 w-100 btn btn-primary text-light" type="submit">Recuperar</button>
        <div id='status-msg' class="login-msg my-4">{{message}}</div>
        <p class="mt-5 mb-3 text-muted">&copy; 2020–2022</p>
    </form>
</div>  

<v-overlay :model-value="loading" class="align-center justify-center">
  <v-progress-circular
    indeterminate
    size="64">
  </v-progress-circular>
</v-overlay>
</template>

<script>
import {reactive} from 'vue';   // Import reactive
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "ForgotPassword",
    setup() {
        const data = reactive({
            email: ''
        });

        const message = ref(' ');
        const store = useStore();
        const loading = ref(false);

        const submit = async() => {

        loading.value = true;
        const response = await fetch(store.state.projectConfigs.siteUrl + '/wp-json/jwt-auth/v1/forgotpassword', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include',
            body: JSON.stringify(data)
        });

        const content = await response.json();

        // If status success (code 200)
        if(response.status == 200){
            loading.value = false;
            document.getElementById('status-msg').style.color = "green";
            message.value = content;
        }
        // If status 403, check if jwt cookie exists and delete it. place message under login 
        // dialog informing of the error on loging in
        else if(response.status == 403){
            loading.value = false;
            document.getElementById('status-msg').style.color = "red";
            message.value = 'Erro: ' + content;
        }
    }
        
    return {
        data,
        submit,
        loading,
        message
    }
    }
}

</script>

<style scoped>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
</style>

<template>
  <v-container grid-list-md text-xs-center fluid align="center">
    <v-card class="pa-2" max-width="600" align="center">
      <v-card-title class="justify-center"></v-card-title>
      <v-card max-width="400" variant="outlined">
        <v-img
          :src="require('../assets/mathack_logo_1500x300.png')"
          alt=""
          contain
          width="300"
          height="100"
        ></v-img>
        <v-card-text>
          <div class="super-text font-weight-bold">SUPER</div>
        </v-card-text>
        <v-card-title class="justify-center">2023/2024</v-card-title>
        <v-spacer />
        <v-card-text align="center">
          Desbloqueia o <b>Mathack SUPER</b>
          <span style="color: red"><b>AGORA</b></span> por <strike>50€</strike>
          <span style="color: red; font-size: 16px"><b>37€</b></span> até aos
          exames! <br />
          <br />👉 Sabe que nota podes ter no exame. 
          <br />👉 Sabe em que matérias precisas de trabalhar mais. 
          <br />👉 Tem ajudas ilimitadas em todos os exercícios.
          <v-spacer />
          <br />Selecciona o teu método de pagamento e clica em comprar:
        </v-card-text>
        <v-card-title class="justify-center"></v-card-title>

        <v-container>
  <v-btn :class="{ 'selected': paymentMethod === 'MB Way', 'disabled-button': paymentMethod && paymentMethod !== 'MB Way' }"
         @click="selectPaymentMethod('MB Way')"
         elevation="2" 
         class="my-2 highlight-on-select" dark block> 
    <v-img :src="require('../assets/mbway_banner.png')"></v-img>
  </v-btn>
</v-container>

        <v-container>
  <v-btn :class="{ 'selected': paymentMethod === 'Credit Card', 'disabled-button': paymentMethod && paymentMethod !== 'Credit Card' }"
         @click="selectPaymentMethod('Credit Card')"
         elevation="2" 
         class="my-2 highlight-on-select" dark block> 
    <v-img :src="require('../assets/creditcard_banner.png')"></v-img>
  </v-btn>
</v-container>


        <v-container>
            <v-btn :disabled="!paymentMethod" color="primary" elevation="2" class="my-2" @click="postPurchase('mhsuper')" dark block> Comprar </v-btn>
        </v-container>

      </v-card>
      <v-card-title class="justify-center"></v-card-title>
    </v-card>
  </v-container>


  <v-overlay :model-value="loading" class="align-center justify-center">
    <v-progress-circular indeterminate size="64"> </v-progress-circular>
  </v-overlay> 
</template>

<script>
import { onMounted, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";

export default {
    data: () => ({
    paymentMethod: null,
  }),
  setup() {
    const router = useRouter();
    const store = useStore();
    const { cookies } = useCookies();

    let formContinue = ref(false);
    const message = ref(' ');

    let loading = ref(true);

    onMounted(async () => {
      try {
        loading.value = true;
        const response = await fetch(
          store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/shopmhsuper",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
          }
        );
        
        // If status unsuccess (different from code 200)
        if (response.status != 200) {
          await store.dispatch("setAuth", false);

          loading.value = false;
          
          await router.push("/");
        }
        // If status success (code 200)
        else
        {
          loading.value = false;
        }
      } catch (e) {
        await store.dispatch("setAuth", false);
      }
    });
    
    return {
      formContinue,
      message,
      loading
    }
  },
  methods: {
    selectPaymentMethod(method) {
      if(this.paymentMethod === method) {
        // Deselect the current method by setting it to null
        this.paymentMethod = null;
      } else {
        // Select the new method
        this.paymentMethod = method;
        // MB Way
        // Credit Card
        if (this.paymentMethod == 'MB Way')
        {
          this.postPaymentMethod('mbway');
        }

        if (this.paymentMethod == 'Credit Card')
        {
          this.postPaymentMethod('creditcard');
        }
      }
    },
    async postPaymentMethod(method){
      const { cookies } = useCookies();

      const postData = reactive({
        paymethod: method
      });

      this.loading = true;
      try {
        const response = await fetch(
          this.$store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/shopmhsuperpaymethod",
          {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
            body: JSON.stringify(postData)
          }
        );

        if (response.status == 200){
          this.loading = false;         
        }
      } catch (e) {
        console.log(e);
      }
    },
    async postPurchase(product){
      const { cookies } = useCookies();

      const postData = reactive({
        purchase: product
      });

      this.loading = true;
      try {
        const response = await fetch(
          this.$store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/shopmhsuper",
          {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
            body: JSON.stringify(postData)
          }
        );

        if (response.status == 200){
          this.loading = false;  
          this.purchaseConfirmationMessage();       
        }
      } catch (e) {
        console.log(e);
      }
    },
    purchaseConfirmationMessage() {
      // Emit a custom event without a payload
      this.$emit("purchase-confirmation");
    },
},

};
</script>

<style scoped>
.super-text {
  color: #4780ae; /* Replace with your color code */
  font-size: 4em; /* or any size you want */
}


.highlight-on-select {
  transition: box-shadow 0.3s !important;; /* Smooth transition for the shadow */
}

.selected {
  box-shadow: 0 0 8px 2px #4780ae !important;; /* Creates the glow effect */
}

.disabled-button {
  opacity: 0.5; /* Dim the button to look disabled */
  cursor: not-allowed; /* Change cursor to indicate the button is disabled */
  pointer-events: none; /* Prevent clicking on the button */
}

</style>

<template>
<div class="form-signin">
    <form @submit.prevent="submit">

        <h3 class="h3 mb-3 fw-normal">Criar conta</h3>

        <div class="form-floating">
        <input v-model="data.first" type="text" class="form-control" placeholder="Nome">
        <label>Primeiro Nome</label>
        </div>
        <div class="form-floating">
        <input v-model="data.last" type="text" class="form-control" placeholder="Nome">
        <label>Apelido</label>  
        </div>
        <div class="form-floating">
        <input v-model="data.email" type="email" class="form-control" placeholder="name@example.com">
        <label>Email</label>
        </div>
        <div class="form-floating">
        <input v-model="data.password" type="password" class="form-control" placeholder="Password">
        <label>Password</label>
        </div>
        <div class="form-floating">
        <input v-model="data.confirm_password" type="password" class="form-control" placeholder="Password">
        <label>Confirmar Password</label>
        </div>
        <div class="form-check">
        <input v-model="data.check" type="checkbox" class="form-check-input" id="checkPrivacy">
        <label class="form-check-label" for="checkPrivacy">Aceito a <a href="https://mathack.pt/privacy-policy/" target="_blank" rel="noopener noreferrer">Política de Privacidade</a> e a <a href ="https://mathack.pt/cookie-policy/" target="_blank" rel="noopener noreferrer"> Política de Cookies. </a></label>
        </div>
        <button class="mt-1 w-100 btn btn-primary text-light" type="submit" :disabled="data.submitting" >Registar</button>
        <div id='status-msg' class="login-msg my-4">{{message}}</div>
    </form>
</div>
</template>

<script> 
import {onMounted, reactive} from 'vue';  
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "Register",
    setup() {
        const data = reactive({
            first: '',
            last: '',
            email: '',
            password: '',
            confirm_password: '',
            check: false,
            submitting: false
        });

        const message = ref(' ');
        const store = useStore();

        store.dispatch('setCurrentScreen', 'Register');

         onMounted(async () => {
             try {
                 await fetch(
                     store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/register",
                     {
                         method: "GET",
                         headers: {
                             "Content-Type": "application/json",
                        },
                    }
                );
            } catch (e) {
                await store.dispatch("setAuth", false);
            }
         });

        const submit = async() => {

            data.submitting = true;
            
            //Validate fields
            let p_m = data.password.match("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");
            if (!p_m){
                message.value = 'A password tem de ter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minuscula e 1 número.';
                data.submitting = false;
                return;
            }
            let f_m = data.first.match("^[A-Za-z]{1,20}");
            let l_m = data.last.match("^[A-Za-z]{1,20}");
            if(!f_m || !l_m){
                message.value = 'Verifique os seus nomes.\n';
                data.submitting = false;
                return;
            }
            if (data.password != data.confirm_password){
                message.value = 'As duas passwords têm de ser iguais \n';
                data.submitting = false;
                return;
            }
            let em = data.email.match("([a-zA-Z0-9\\_\\-\\.]+)@([a-zA-Z]+).(.+)");
            if (!em){
                message.value = 'Por favor insira um email válido';
                data.submitting = false;
                return;
            }
            if (!data.check){
                message.value = 'Tem de aceitar a nossa Política de Privacidade';
                data.submitting = false;
                return;
            }

            message.value = '';

            let send_data = {
                first: data.first,
                last: data.last,
                email: data.email,
                password: data.password
            };

            const response = await fetch(store.state.projectConfigs.siteUrl + '/wp-json/jwt-auth/v1/create_user', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify(send_data)
            });

            // If status success (code 200)
            if(response.status != 200){                 
                message.value = 'Não foi possível criar a conta.'
            }
            else{
                data.first = ''; data.last = ''; data.email = ''; data.password = ''; data.confirm_password = ''; data.check = false;
                document.getElementById('status-msg').style.color = "green";
                message.value = 'Vais receber um email com um código para validar.';
                setTimeout(() => {}, 6000);
            }

            data.submitting = false;

        }
        return {
            data,
            submit,
            message
        }
    }
}
</script>

<style scoped>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.login-msg {
    color: red;
    height: 15px;
    max-height: 15px;
}

</style>

<template>
  <!-- Show loading animation while loading data-->
  <v-overlay :model-value="loading" class="align-center justify-center">
    <v-progress-circular indeterminate size="64"> </v-progress-circular>
  </v-overlay>

  <v-overlay v-model="overlay" class="align-center justify-center">
    <img v-bind:src="questionImg" />
  </v-overlay>

  <v-container grid-list-md text-xs-center fluid align="center">
    <v-card max-width="600" align="left">
      <v-card-text>{{ data.question }}</v-card-text>
    </v-card>
  </v-container>

  <v-container grid-list-md text-xs-center fluid align="center">
    <v-img
      v-if="data.question !== null"
      :width="600"
      aspect-ratio="16/9"
      cover
      v-bind:src="data.questionImg"
      @click="overlay = !overlay"
    ></v-img>
  </v-container>

  <v-container grid-list-md text-xs-center fluid align="center">
    <v-card class="my-2" max-width="600" align="left">
      <v-card-title>
        <v-row>
          <v-container class="mt-2">
            <v-btn
              @click="submitFirstAnswer"
              block
              :min-height="data.buttonHeight"
            >
              <span id="firstAnswerText" style="hyphens: none">{{
                data.firtsAnswer
              }}</span>
            </v-btn>
          </v-container>
          <v-container>
            <v-btn
              @click="submitSecondAnswer"
              block
              :min-height="data.buttonHeight"
            >
              <span id="secondAnswerText" style="hyphens: none">{{
                data.secondAnswer
              }}</span>
            </v-btn>
          </v-container>
          <v-container>
            <v-btn
              @click="submitThirdAnswer"
              block
              :min-height="data.buttonHeight"
            >
              <span id="thirdAnswerText" style="hyphens: none">{{
                data.thirdAnswer
              }}</span>
            </v-btn>
          </v-container>
          <v-container class="mb-2">
            <v-btn
              @click="submitForthAnswer"
              block
              :min-height="data.buttonHeight"
            >
              <span id="forthAnswerText" style="hyphens: none">{{
                data.forthAnswer
              }}</span>
            </v-btn>
          </v-container>
        </v-row>
      </v-card-title>
      <div id="status-msg" class="login-msg my-4">{{ message }}</div>
    </v-card>
  </v-container>

  <v-container grid-list-md text-xs-center fluid align="center">
    <v-btn @click="submitSkipQuestion" color="#D2D2D2" elevation="2">
      Saltar Pergunta <v-icon>mdi-skip-next</v-icon></v-btn
    >
  </v-container>
</template>

<script>
import { useCookies } from "vue3-cookies";
import { useRouter } from "vue-router";
import { onMounted, ref, reactive } from "vue";
import { useStore } from "vuex";
export default {
  //Setup
  name: "MathClassicMultiChoice",
  // Pass in the props the multiple choice question data
  props: {
    questionData: {
      type: Object, // Define the type of the prop
      required: true, // Set whether the prop is required or optional
    },
  },
  /*
    // Check if component is created
    created() {
        console.log('YourComponent is created');
    },
    // Check if component is mounted
    mounted() {
        console.log('YourComponent is mounted');
    },
    */
  setup(props) {
    // Include props argument in Setup to be able to acces the props value inside setup
    const router = useRouter();
    let loading = ref(true);
    const store = useStore();
    const { cookies } = useCookies();
    //let examExerciseDetails = ref(null);
    //let questionImg = ref(null);
    //let exerciseDescription = ref("Exame ");

    const data = reactive({
      questionId: "",
      question: "",
      firtsAnswer: "",
      firtsAnswerKey: "",
      secondAnswer: "",
      secondAnswerKey: "",
      thirdAnswer: "",
      thirdAnswerKey: "",
      forthAnswer: "",
      forthAnswerKey: "",
      questionImg: null,
      initExamExerciseTime: null,
      mathclassiccessionId: null,
    });

    // Define the mathjax_loader function
    function mathjax_loader() {
      window.MathJax = {
        tex: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
            ["!!", "!!"],
          ],
        },
      };

      (function () {
        var script = document.createElement("script");
        script.src = "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js";
        script.async = true;
        document.head.appendChild(script);
      })();
    }

    // Set the questionId value
    data.questionId = ref(props.questionData.ongoingExerciseId);
    //initExamExerciseTime = ref(props.questionData.ongoingInitExamExerciseTime);

    let post_data = {
      examquestionid: parseInt(props.questionData.ongoingExerciseId),
    };

    // On component mounted

    onMounted(async () => {
      try {
        const exam_question_response = await fetch(
          store.state.projectConfigs.siteUrl +
            "/wp-json/jwt-auth/v1/examquestion",
          {
            //method: "POST",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
            body: JSON.stringify(post_data),
          }
        );

        const exam_question_content = await exam_question_response.json();

        // /!\ - Add ID of exercise to backend or transferred on propos

        if (exam_question_response.status == 200) {
          //console.log(exam_question_content["examdata"]); // TO DELETE - Diagnose Purposes
          data.initExamExerciseTime =
            exam_question_content["examdata"]["examExerciseDetails"][0][
              "initExamExerciseTime"
            ];
          data.mathclassiccessionId =
            exam_question_content["examdata"]["examExerciseDetails"][0][
              "mathclassiccessionId"
            ];
          //console.log(data.initExamExerciseTime); // TO DELETE - Diagnose Purposes
          //console.log(data.mathclassiccessionId); // TO DELETE - Diagnose Purposes
          data.question = await exam_question_content["examdata"][
            "examMultipleChoiceDynamic"
          ][0]["question"];
          data.firtsAnswer = await exam_question_content["examdata"][
            "examMultipleChoiceDynamic"
          ][0]["answers"][0]["answer"];
          data.firtsAnswerKey =
            exam_question_content["examdata"]["examMultipleChoiceDynamic"][0][
              "answers"
            ][0]["key"];
          //console.log(exam_question_content['examdata']['examMultipleChoiceDynamic'][0]['answers'][0]['key']);  // TO DELETE - Diagnose Purposes
          data.secondAnswer = await exam_question_content["examdata"][
            "examMultipleChoiceDynamic"
          ][0]["answers"][1]["answer"];
          data.secondAnswerKey =
            exam_question_content["examdata"]["examMultipleChoiceDynamic"][0][
              "answers"
            ][1]["key"];
          data.thirdAnswer = await exam_question_content["examdata"][
            "examMultipleChoiceDynamic"
          ][0]["answers"][2]["answer"];
          data.thirdAnswerKey =
            exam_question_content["examdata"]["examMultipleChoiceDynamic"][0][
              "answers"
            ][2]["key"];
          data.forthAnswer = await exam_question_content["examdata"][
            "examMultipleChoiceDynamic"
          ][0]["answers"][3]["answer"];
          data.forthAnswerKey =
            exam_question_content["examdata"]["examMultipleChoiceDynamic"][0][
              "answers"
            ][3]["key"];
          if (
            (await exam_question_content["examdata"][
              "examMultipleChoiceDynamic"
            ][0]["multiplechoiceDnamicImage"]) == true
          ) {
            data.questionImg = await exam_question_content["examdata"][
              "examMultipleChoiceDynamic"
            ][0]["multiplechoiceDnamicImageData"];
          } else {
            data.questionImg = null;
          }

          //console.log(data.question); // TO DELETE - Diagnose Purposes

          // Call the mathjax_loader function
          mathjax_loader();

          loading.value = false;
          await store.dispatch("setAuth", true); // Understand why this is needed, else the menu is not shown
        } else if (exam_question_response.status == 403) {
          await store.dispatch("setAuth", false);
          await router.push("/login");
        }
      } catch (e) {
        console.log(e);
        await store.dispatch("setAuth", false);
      }
    });

    return {
      // Return the props and data values to be used in the template
      router, // Router instance for programmatic navigation
      loading, // Loading animation
      data, // Multiple Choice Question Data
    };
  },
  methods: {
    async submitAnswer(givenAnswer, skippedValue) {
      const { cookies } = useCookies();

      const postData = reactive({
        sessionId: this.data.mathclassiccessionId,
        mathClassicSessionId: this.data.mathclassiccessionId,
        questionId: this.data.questionId,
        initExerciseTime: this.data.initExamExerciseTime,
        imageBasedAnswerParameter: null,
        answerParameter: givenAnswer,
        skippedValue: skippedValue,
      });

      //console.log(postData); // TO DELETE - Diagnose Purposes

      this.data.loading = true;
      try {
        const response = await fetch(
          this.$store.state.projectConfigs.siteUrl +
            "/wp-json/jwt-auth/v1/examanswerreview",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
            body: JSON.stringify(postData),
          }
        );

        const content = await response.json();
        if (response.status == 200) {
          this.data.loading = false;

          //console.log(content[0]); // TO DELETE - Diagnose Purposes
          //console.log(content[0]["statusCode"]); // TO DELETE - Diagnose Purposes
          /*
          Response Example:
          {
              "0": {
                "statusCode": 200,
                "message": null,
                "questionId": 3,
                "choosenAnswer": "0,8",
                "correctAnswer": "0,8",
                "evaluation": true,
                "timeDurationSec": 9
            }
          } 
          */

          // /!\ - Fix it or Delete: THIS IS NOT CORRECT
          this.data.answerReviewStatusCode =
            content[0][
              "statusCode"
            ];
          this.data.answerReviewMsg =
            content[0][
              "message"
            ];
          this.data.answerReviewQuestionId =
            content[0][
              "questionId"
            ];
          this.data.answerReviewChoosenAnswer =
            content[0][
              "choosenAnswer"
            ];
          this.data.answerReviewCorrectAnswer =
            content[0][
              "correctAnswer"
            ];
          this.data.answerReviewEvaluation =
            content[0][
              "evaluation"
            ];
          this.data.answerReviewTimeDuration =
            content[0][
              "timeDurationSec"
            ];

            // /!\ Move this block of code below to the abofe IF 200?
            this.data.isAnswerReview = true;

            this.mathClassicMultiChoiceAnswerReview(content[0]);
        }

        this.mathjax_loader(); // Render Latex formulas with Mathjax

      } catch (e) {
        await this.$store.dispatch("setAuth", false);
      }
    },
    // Submit the first answer
    submitFirstAnswer() {
      //console.log("First Answer"); // TO DELETE - Diagnose Purposes
      //console.log(this.data.firtsAnswer);  // TO DELETE - Diagnose Purposes
      //console.log(this.data.firtsAnswerKey); // TO DELETE - Diagnose Purposes
      this.submitAnswer(this.data.firtsAnswerKey, 0);
      //this.$emit("submit-first-answer", this.data.firtsAnswer, this.data.firtsAnswerKey);
    },
    // Submit the second answer
    submitSecondAnswer() {
      //console.log("Second Answer"); // TO DELETE - Diagnose Purposes
      //console.log(this.data.secondAnswer);  // TO DELETE - Diagnose Purposes
      //console.log(this.data.secondAnswerKey); // TO DELETE - Diagnose Purposes
      this.submitAnswer(this.data.secondAnswerKey, 0);    
      //this.$emit("submit-second-answer", this.data.secondAnswer, this.data.secondAnswerKey);
    },
    // Submit the third answer
    submitThirdAnswer() {
      //console.log("Third Answer"); // TO DELETE - Diagnose Purposes
      //console.log(this.data.thirdAnswer);  // TO DELETE - Diagnose Purposes
      //console.log(this.data.thirdAnswerKey); // TO DELETE - Diagnose Purposes
      this.submitAnswer(this.data.thirdAnswerKey, 0);
      //this.$emit("submit-third-answer", this.data.thirdAnswer, this.data.thirdAnswerKey);
    },
    // Submit the forth answer
    submitForthAnswer() {
      //console.log("Forth Answer"); // TO DELETE - Diagnose Purposes
      //console.log(this.data.forthAnswer);  // TO DELETE - Diagnose Purposes
      //console.log(this.data.forthAnswerKey); // TO DELETE - Diagnose Purposes
      this.submitAnswer(this.data.forthAnswerKey, 0);
      //this.$emit("submit-forth-answer", this.data.forthAnswer, this.data.forthAnswerKey);
    },
    async submitSkipQuestion() {
      //console.log("Skip!"); // TO DELETE - Diagnose Purposes

      /*
      // /!\ - Fix it: ON FIRST RUN, DOESN'T WORK. 
      Perhaps is missing calling the endpoint to create the exercise session and return the session id of the session
      e.g.:
      answerParameter: null
      examquestionid: 1
      initExerciseTime: null
      mathClassicSessionId: null
      skippedValue: 1 

      ——————

      answerParameter: null
      examquestionid: 1
      initExerciseTime: "2023-10-02 19:28:05"
      mathClassicSessionId: 1993
      skippedValue: 1

      egory: Array [ "Sucessões - 11º Ano" ]
      categorysec: null
      difficulty: 1
      exercisenumber: 1
      exerciseparagraph: ""
      id: 1 
      lasttimedone: "2023-09-25 19:14:20"
      lasttimeresult: null
      multiplechoice: 1
      multiplechoiceDnamic: true
      phase: 1
      totalTimesDone: 52
      year: 2022
      */

      const { cookies } = useCookies();

      this.loading = true;

      /*
      // /!\ - Fix it: ON FIRST RUN, DOESN'T WORK. e.g.:
      answerParameter: null
      examquestionid: 14
      initExerciseTime: null -> initExamExerciseTime
      mathClassicSessionId: null
      skippedValue: 1
      */

      //console.log(this.data.questionId); // TO DELETE - Diagnose Purposes
      //console.log(this.data.mathclassiccessionId); // TO DELETE - Diagnose Purposes
      //console.log(this.data.initExamExerciseTime); // TO DELETE - Diagnose Purposes

      const postData = reactive({
        mathClassicSessionId: this.data.mathclassiccessionId,
        answerParameter: null,
        initExerciseTime: this.data.initExamExerciseTime,
        examquestionid: this.$props.questionData.ongoingExerciseId,
        skippedValue: 1,
      });

      //console.log("postData:"); // TO DELETE - Diagnose Purposes
      //console.log(postData); // TO DELETE - Diagnose Purposes

      try {
        const response = await fetch(
          this.$store.state.projectConfigs.siteUrl +
            "/wp-json/jwt-auth/v1/skipexamquestion",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
            body: JSON.stringify(postData),
          }
        );

        const content = await response.json();

        // If status success (different from code 200)
        if (response.status == 200) {
          this.loading = false;
          //console.log("new-mathclassic" + " | " + response.status); // TO DELETE - Diagnose Purposes
          document.getElementById("status-msg").style.color = "green";
          this.message = content;
          this.newMathClassic();
        }
        // If status unsuccess (code 200)
        else {
          document.getElementById("status-msg").style.color = "red";
          this.message = "Erro: " + content;
          this.loading = false;
        }
      } catch (e) {
        await this.$store.dispatch("setAuth", false);
      }
    },
    mathClassicMultiChoiceAnswerReview(answerReviewValue) {
      // Emit a custom event without a payload
      //console.log("mathclassic-multi-choice-answer-review"); // TO DELETE - Diagnose Purposes
      //console.log(answerReviewValue); // TO DELETE - Diagnose Purposes
      //this.$emit("mathclassic-multi-choice-answer-review", answerReviewValue);

      // Emit a custom event with a payload (value)
      this.$emit("mathclassic-multi-choice-answer-review", {
      answerReviewValue: answerReviewValue,
      mathclassiccessionId: this.data.mathclassiccessionId,
    });
    },
    newMathClassic() {
      // Emit a custom event without a payload
      //console.log("new-mathclassic"); // TO DELETE - Diagnose Purposes
      this.$emit("new-mathclassic");
    },
  },
};
</script>

<style scoped></style>

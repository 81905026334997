<template>
  <v-app>
    <div class="text-center">
      <v-dialog
        v-model="dialog"
        fullscreen
        :scrim="false"
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ props }">
          <v-btn @click="requestClarification()" color="#D2D2D2" elevation="2" class = "my-2" dark v-bind="props" block> Não percebi a resposta 😵 </v-btn>
        </template>

        <v-card class="mx-auto" max-width="448" style="backgroundColor: #F1F1F1">
          <v-app-bar color="#F1F1F1">
            <v-app-bar-title>
              <v-img
                :src="require('../assets/mathack_logo_1500x300.png')"
                alt=""
                contain
                width="150"
                height="50"
            /></v-app-bar-title>
            <template v-slot:append>
          <!--<v-btn @click="dialog = false" icon="mdi-close" color="#F1F1F1"></v-btn>-->
        </template>
            <v-spacer></v-spacer>
          </v-app-bar>

          <v-main>
              <div>
                  <br/>
              </div>
              <div>
            
               <v-container v-if="clarifyData.idExercise !== null && clarifyData.clarify !== null" grid-list-md text-xs-center fluid align="center">
                <v-card class="overflow-y-auto" max-width="600px" align ="left" :max-height="height-280">
                <!--<v-card class="overflow-y-auto" max-width="600px" align ="left" :max-height="550">-->
                    <v-card-text>{{clarifyData.question}}</v-card-text>
                    
                    <div class="container">
                    <table class="table">
                        <thead>
                        <!-- Table Head is only used to create black line on top of first row -->
                        <tr>
                          <th colspan="2">Solução</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="vertical-align: middle; font-size: 14px; hyphens: none">
                              {{clarifyData.answer}}</td>
                        </tr>
                        </tbody>                        
                    </table>

                    <table class="table">
                      <thead>
                        <!-- Table Head is only used to create black line on top of first row -->
                        <tr>
                          <th colspan="2">Explicação</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style="vertical-align: middle; font-size: 14px; hyphens: none;">                      
                              <!--Lorem ipsum dolor sit amet, semper quis, sapien id natoque elit. Nostra urna at, magna at neque sed sed ante imperdiet, dolor mauris cursus velit, velit non, sem nec. Volutpat sem ridiculus placerat leo, augue in, duis erat proin condimentum in a eget, sed fermentum sed vestibulum varius ac, vestibulum volutpat orci ut elit eget tortor. Ultrices nascetur nulla gravida ante arcu. Pharetra rhoncus morbi ipsum, nunc tempor debitis, ipsum pellentesque, vitae id quam ut mauris dui tempor, aptent non. Quisque turpis. Phasellus quis lectus luctus orci eget rhoncus. Amet donec vestibulum mattis commodo, nulla aliquet, nibh praesent, elementum nulla. Sit lacus pharetra tempus magna neque pellentesque, nulla vel erat. -->
                              {{clarifyData.clarify}}
                          </td>
                        </tr>
                        </tbody>
                    </table>

                    </div>
                    

                </v-card>
               </v-container>

               <v-container v-if="clarifyData.idExercise !== null && clarifyData.clarify === null" grid-list-md text-xs-center fluid align="center">
                <!-- <v-card class = "pa-2" max-width="600px" align ="left"> -->
                <v-card class="overflow-y-auto" max-width="600px" align ="left" :max-height="height">
                    <v-card-text>{{clarifyData.statusMessage}}</v-card-text>
                    <!--<v-card-text>{{clarifyData.idExercise}}</v-card-text>-->
                </v-card>
               </v-container>
               <v-container grid-list-md text-xs-center fluid align="center">
                 <MHSuperAdCard v-if="(clarifyData.userAccountType === 'free' || clarifyData.statusCode === 202)"/>
               </v-container>


               <v-container grid-list-md text-xs-center fluid align="center">
                <!--<v-card class="overflow-y-auto" max-width="600px" align ="center" max-height="150px">-->
        
                  <!-- CLARIFY FEEDBACK BEGIN -->
                  <div v-if="clarifyData.idExercise !== null && clarifyData.clarify !== null">
                    <v-row justify="center">
                        <v-col sm="2">
                            <v-btn @click="submitClarifyLike" v-bind:color="likeClarify == true?'primary':'#D2D2D2'" elevation="2" class="mx-4">
                                <v-icon>mdi-thumb-up</v-icon>
                              </v-btn>                           
                        </v-col>
                                <v-col sm="2">
                            <v-btn @click="submitClarifyDislike" v-bind:color="disLikeClarify == true?'primary':'#D2D2D2'" elevation="2" class="mx-4">                              
                                <v-icon>mdi-thumb-down</v-icon>
                              </v-btn>
                        </v-col>
                    </v-row>
                    <v-row justify="center">
                        <v-col sm="2" justify="center" style="vertical-align: middle; font-size: 14px; hyphens: none;">
                            Compreendi tudo
                        </v-col>
                                <v-col sm="2" justify="center" style="vertical-align: middle; font-size: 14px; hyphens: none;">
                            Fiquei com dúvidas
                        </v-col>
                    </v-row>
                  </div>
                  <!-- CLARIFY FEEDBACK END -->

                  <v-container grid-list-md text-xs-center fluid align="center">
                  <v-card-text></v-card-text>
                  <v-btn class = "mt-n5 mb-2" @click="dialog = false; submitFeedback()" color="primary" elevation="2"
                  :disabled="!disLikeClarify && !likeClarify && clarifyData.idExercise !== null && clarifyData.clarify !== null">
                  Continuar <v-icon>mdi-skip-next</v-icon>
                  </v-btn>
                  <span class ="my-2" v-if="clarifyData.numClarifyRequests<=3 && clarifyData.userAccountType === 'trial'" style="font-size: 14px;"><br>Já pediste {{ (clarifyData.numClarifyRequests>=3?3:clarifyData.numClarifyRequests) }} {{clarifyData.numClarifyRequests==1?'ajuda':'ajudas'}} hoje.<br>Tens um limite de 1 por dia na versão experimental.</span>
                  </v-container>
                <!--</v-card>-->
               </v-container>

              </div>
          </v-main>
        </v-card>
      </v-dialog>
    </div>
  </v-app>

    <v-overlay :model-value="clarifyData.loading" class="align-center justify-center">
    <v-progress-circular indeterminate size="64"> </v-progress-circular>
  </v-overlay>
</template>

<script>
import { ref, defineComponent } from "vue";
import { reactive} from "vue";
import { useCookies } from "vue3-cookies";
import MHSuperAdCard from '@/components/MHSuperAdCard.vue';
//import { useDisplay } from "vuetify";
//import { useStore } from "vuex";

export default defineComponent({
name: "Clarify",
  props: ['questionId'],
  components: {
    MHSuperAdCard
  },
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      likeClarify: false,
      disLikeClarify: false,
    };
  },
   setup(props) { // Include props argument in Setup to be able to acces the props value inside setup

     const clarifyData = reactive({
      statusCode: null,
      statusMessage: null,
      userAccountType: null,
      numClarifyRequests: null,
      idExercise: null,
      question: null,
      answer: null,
      clarify: null,
      loading: false,
     });

     //clarifyData.idExercise = props.questionId;
     console.log("Question ID: " + props.questionId);

     //const { name } = useDisplay()

      let height = ref(220);

      height = window.innerHeight;

      /*
        // name is reactive and
        // must use .value
        switch (name.value) {
          case 'xs': 
            height = 220;
            break;
          case 'sm': 
            height =  400;
            break;
          case 'md': 
            height =  500;
            break;
          case 'lg': 
            height =  600;
            break;
          case 'xl': 
            height =  800;
            break;
          case 'xxl': 
            height =  1200;
            break;
        }
        */


     return {
      clarifyData,
      height
    };
     
   },
   methods: {
     async requestClarification() {
       //console.log("Request Clarification");

      //-----|STATUS|-----
      this.clarifyData.statusCode = null;
      this.clarifyData.statusMessage = null;
      //-----|CLARIFY REQUESTS CONTROL|-----
      this.clarifyData.userAccountType = null;
      this.clarifyData.numClarifyRequests = null;
      //-----|CLARIFY CLARIFICATION|-----
      this.clarifyData.idExercise = null;
      this.clarifyData.question = null;
      this.clarifyData.answer = null;
      this.clarifyData.clarify = null;
      //------|CLARIFY FEEDBACK------
      this.likeClarify = false;
      this.disLikeClarify = false;

       this.clarifyData.loading = true;
       
       const { cookies } = useCookies();
       
       const postData = reactive({
         questionId: this.$props.questionId
        });
        
        try {
          const response = await fetch(
            this.$store.state.projectConfigs.siteUrl  + "/wp-json/jwt-auth/v1/clarify-question",
            {
              method: 'POST',
              headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
            body: JSON.stringify(postData)
          }
        );

        
        const content = await response.json();
        if (response.status == 200 || response.status == 201 || response.status == 202){
          //this.loading = false;  // Stop overlay loading animation       
  
          //console.log(content); // TO DELETE - Diagnose Purposes
          //-----|STATUS|-----
          this.clarifyData.statusCode = content["statusCode"];
          this.clarifyData.statusMessage = content["statusMessage"];
          //-----|CLARIFY REQUESTS CONTROL|-----
          this.clarifyData.userAccountType = content["userAccountType"];
          this.clarifyData.numClarifyRequests = content["numClarifyRequests"];
          //-----|EXERCISE CLARIFICATION|-----
          this.clarifyData.idExercise = content["idExercise"];
          this.clarifyData.question = content["question"];
          this.clarifyData.answer = content["answer"];
          this.clarifyData.clarify = content["clarify"];

          //console.log(content); // TO DELETE - Diagnose Purposes

          this.clarifyData.loading = false;

          // mathjax_loader: Render Latex formulas with Mathjax
          this.mathjax_loader();
        }
      } catch (e) {
        this.clarifyData.loading = false;
        //console.log('Failed');  // TO DELETE - Diagnose Purposes
      }
    },
    async submitFeedback() {
      const { cookies } = useCookies();

      let feedback = 0;

      if (this.disLikeClarify == true)
      {
        feedback = -1;
      }
      
      if (this.likeClarify == true)
      {
        feedback = 1;
      }    

      const postData = reactive({
        idExercise: this.clarifyData.idExercise,
        userFeedback: feedback // Dislike: -1
      });

      try {
        const response = await fetch(
          this.$store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/clarify-feedback",
          {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
            body: JSON.stringify(postData)
          }
        );

        //const content = await response.json();
        //console.log(postData); // TO DELETE - Diagnose Purposea
        //console.log(response.status); // TO DELETE - Diagnose Purposes
        if(response.status == 500){
          console.log(response.status);
        }

         } catch (e) {
        await this.$store.dispatch("setAuth", false);
      }

    },
    submitClarifyLike(){
      this.likeClarify = !this.likeClarify; // TO DELETE - Diagnose Purposes
      if (this.likeClarify== true) {
        this.disLikeClarify = false;
      }
    },
    submitClarifyDislike(){
      this.disLikeClarify = !this.disLikeClarify; // TO DELETE - Diagnose Purposes
      if (this.disLikeClarify == true) {
        this.likeClarify = false;
      }
    },
    mathjax_loader(){
     console.log("mathjax_loader");
      window.MathJax = {
        tex: {
          inlineMath: [['$', '$'], ['\\(', '\\)'],['!!', '!!']]
        }
      };
      
      (function () {
        var script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js';
        script.async = true;
        document.head.appendChild(script);
      })();
    },
   },
});
</script>

<style>
    .dialog-bottom-transition-enter-active,
    .dialog-bottom-transition-leave-active {
        transition: transform 0.2s ease-in-out;
    }

    .v-btn { 
        text-transform: none !important; 
    }
</style>

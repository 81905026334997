<template>
  <v-chart class="chart" :option="option" autoresize/>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {   TitleComponent, TooltipComponent, GridComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";

use([CanvasRenderer, LineChart, GridComponent, TitleComponent, TooltipComponent]);

export default defineComponent({
  name: "StudentGradeChart",
  props: ['chartdata'],
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: "light",
  },
  setup(props) {  // Include props argument in Setup to be able to acces the props value inside setup
    // console.log("Hello!" + props.chartdata);  // TO DELETE - Test Purposes
    const option = ref({
      /*title: {
        text: "ECharts Getting Started Example",
      },*/
      tooltip: {},
      xAxis: {
        //data: ["shirt", "cardigan", "chiffon", "pants", "heels", "socks"], // TO DELETE - Test Purposes
        data: props.chartdata['xAxis'],
      },
      yAxis: {},
      series: [
        {
          name: "Nota Prevista",
          type: "line",
          areaStyle: {},
          //data: [5, 20, 36, 10, 10, 20],  // TO DELETE - Test Purposes
          data: props.chartdata['yAxis'],
          smooth: true,
          color: "#4780AE"
        },
      ],
    });

    return { option };
  },
  // watch: {  // TO DELETE - Test Purposes
  //   chartdata : function(chartdata)   // TO DELETE - Test Purposes
  //   {
  //     console.log("Hello!" + JSON.stringify(chartdata));  // TO DELETE - Test Purposes
  //     console.log("Hello!" + JSON.stringify(chartdata['xAxis']));  // TO DELETE - Test Purposes
  //     console.log("Hello!" + JSON.stringify(chartdata['yAxis']));  // TO DELETE - Test Purposes
  //     this.option.xAxis.data = chartdata['xAxis'];  // TO DELETE - Test Purposes
  //     this.option.yAxis.data = chartdata['yAxis'];  // TO DELETE - Test Purposes
  //     console.log("option: " + JSON.stringify(this.option));  // TO DELETE - Test Purposes
      
  //   }
  // }
});
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
<template>
  <v-container>
    <v-card theme="light" flat tile>
      <v-window v-model="onboarding">
        <v-window-item v-for="n in length" :key="`card-${n}`" :value="n">
          <v-card class="mx-auto px-2" width="344" height="530px">


            <v-card-title> {{contentArr[n-1].title}} </v-card-title>

            <v-img
              :src="require('@/assets/Onboarding_344x200_' + n + '.png')"
              height="200px"
              cover
            ></v-img>

            <v-card-subtitle> </v-card-subtitle>

            <v-card-text class="text-center">{{contentArr[n-1].description}} <br> {{contentArr[n-1].description_two}}</v-card-text>
            <!-- <v-card-text>{{contentArr[n-1].description_two}}</v-card-text> -->
          
            <v-card-actions v-if="n==5" class="justify-center">
            <!--<div v-if="n==3" class="fill-height">-->
              <v-btn block class="startBtn mt-8" color="light" elevation = "2" @click='closeOnboarding' >Quero Começar!</v-btn>
            <!--</div>-->
            </v-card-actions>
          </v-card>
        </v-window-item>
      </v-window>

      <v-card-actions class="justify-space-between">
        <v-btn :disabled="onboarding == 1" variant="plain" icon="mdi-chevron-left" @click="prev"></v-btn>
        <v-item-group v-model="onboarding" class="text-center" mandatory>
          <v-item
            v-for="n in length"
            :key="`btn-${n}`"
            v-slot="{ isSelected, toggle }"
            :value="n"
          >
            <v-btn
              :variant="isSelected ? 'outlined' : 'text'"
              icon="mdi-record"
              color="grey lighten-2"
              @click="toggle"
            ></v-btn>
          </v-item>
        </v-item-group>
        <v-btn :disabled="onboarding == 5" variant="plain" icon="mdi-chevron-right" @click="next"></v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "Onboarding",

  data: () => ({
    length: 5,
    onboarding: 0,
    atBeggining: true,
    atEnd: false,
    contentArr : [
            {
                title: 'Começaste a tua aventura no Mathack!',
                description: 'Pronto para subir a nota?!',
                description_two: 'A partir de agora podes experimentar todas funcionalidades por 1 dia. Depois deste período podes continuar a fazer exercícios mas sem previsão da nota que poderás sempre ter acesso se comprares o modo SUPER. Bom estudo.',
                img: '../assets/Onboarding_2.png'
            },
            {
                title: 'Previsão da Nota',
                description: '1. Esta é a nota que prevemos que irás ter no exame. Cada vez que fazes exercícios esta nota é atualizada. Quanto mais acertares mais vai subindo, se falhares, tens de ir atrás do prejuízo. 😵',
                description_two: '2. Para que tenhas ideia do teu progresso mostramos-te a variação da tua nota durante a última semana. 📈',
                img: ''
            },
            {
                title: 'MathBlitz',
                description: 'Num Mathblitz tens tempo para responder a cada pergunta. É preciso que respondas com a tua intuição, sem papel e caneta.',
                description_two: 'Construímos as perguntas para que as consigas resolver mentalmente e em pouco tempo. Com o MathBlitz em menos de nada já tens os conceitos base todos na ponta da língua. 😛',
                img: ''
            },
            {
                title: 'MathClassic',
                description: 'Mergulha nos exames de outros anos com Mathclassic. 🤿✍️ Estuda ao teu ritmo, com papel e caneta, e domina cada tópico.',
                description_two: 'Os exercícios estão organizados para que possas focar-te no que realmente precisas. Filtra por tema ou dificuldade — a escolha é tua. Revisa o que já treinaste, verifica as respostas e celebra o teu progresso. Pronto para ser um(a) craque nos exames? 🎯📚',
                img: ''
            },
            {
                title: 'Esclarece as tuas dúvidas',
                description: 'Dúvidas? Não há, nem podem haver. 🤔',
                description_two: 'Se ficaste com dúvidas em algum exercício, tens acesso a uma breve explicação da resposta. No modo experimental tens apenas 1 ajuda diária. Com o SUPER são ilimitadas!',
                img: ''
            }
        ],
  }),

  methods: {
    next() {
      this.onboarding =
        this.onboarding + 1 > this.length ? 1 : this.onboarding + 1;
    },
    prev() {
      this.onboarding =
        this.onboarding - 1 <= 0 ? this.length : this.onboarding - 1;
    },
    closeOnboarding() {
     this.$emit('closeOnboarding')
   },
  },
};
</script>

<style scoped>
.startBtn{
  background: #4780AE;
}
</style>
<template>
<div class="form-signin">
    <form @submit.prevent="submit">
        <div align="center">
        <v-img
          :src="require('../assets/mathack_logo_1500x300.png')"
          alt="" 
          class="my-3"
          contain
          width="200"
          height="80"
        />
        </div>
        <h1 class="h4 mb-3 fw-normal text-center"></h1>

        <div>
        <v-text-field
            name="email"
            v-model="data.username"
            label="Email"
            append-inner-icon="mdi-at"
            variant="underlined">
        </v-text-field>
        </div>
        <div>
        <v-text-field
            class = "my-n6"
            name="password"
            v-model="data.password"
            label="Password"
            :append-inner-icon="viewPass ? 'mdi-eye-off' : 'mdi-eye'"
            :type="viewPass ? 'text' : 'password'"
            @click:append-inner="() => (viewPass = !viewPass)"
            variant="underlined">
        </v-text-field>
        </div>
        <button class="mt-3 w-100 btn btn-primary text-light" type="submit">Iniciar sessão</button>
        <div class="col text-center">
            <br>
            <v-btn @click="$router.push('/register')" color="primary" variant="outlined" class="mb-4 w-100">Criar Conta</v-btn>
            <br>
            <router-link to="/forgotpassword">Recuperar password</router-link>
        </div>
        
        <div class="text-danger"><br>{{message}}</div>
        <p class="mt-5 mb-3 text-muted">&copy; 2020–2022</p>
    </form>
</div>

<v-overlay :model-value="loading" class="align-center justify-center">
  <v-progress-circular
    indeterminate
    size="64">
  </v-progress-circular>
</v-overlay>
</template>

<script> 
import {reactive, onMounted} from 'vue';   // Import reactive
import {useRouter} from "vue-router";
import { useCookies } from 'vue3-cookies';
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "Login",
    setup() {
        const data = reactive({
            username: '',
            password: ''
        });

        const viewPass = ref(false);

        const router = useRouter();
        const { cookies } = useCookies();
        const message = ref(' ');
        const store = useStore();
        const loading = ref(false);

        onMounted(async () => {
        try {
            const response = await fetch(
            store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/home",
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + cookies.get("jwt"),
                },
            }
            );

            if (response.status == 200) {
                await store.dispatch("setAuth", true);
                await router.push("/");
            } else if (response.status == 403) {
                await store.dispatch("setAuth", false);
            }
        }
        catch(e){
            store.dispatch('setCurrentScreen', 'Login');
        }
        });



        const submit = async() => {
            loading.value = true;
            const response = await fetch(store.state.projectConfigs.siteUrl + '/wp-json/jwt-auth/v1/token', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify(data)
            });

            const content = await response.json();

            // If status success (code 200)
            if(response.status == 200){
                loading.value = false;   
                // If token response OK, store token and proceed
                if (content.token != null && content.token != ""){
                    cookies.set("jwt", content.token, '3d');
                    // TODO: SET Store True
                    await store.dispatch('setAuth', true);
                    await router.push('/');
                }
                // else show error message
                else
                {
                    loading.value = false;   
                    await store.dispatch('setAuth', false);
                    message.value = `Erro de Login.`;    
                }
            }
            // If status 403, check if jwt cookie exists and delete it. place message under login 
            // dialog informing of the error on loging in
            else if(response.status == 403){
                loading.value = false;   
                await store.dispatch('setAuth', false);
                message.value = `Erro de Login: Credenciais Inválidas`;
                if(content["code"] == '[jwt_auth] incorrect_password')
                {
                    message.value = 'Erro de Login: Credenciais Inválidas';
                }
                if(content["code"] == '[jwt_auth] ip_blocked')
                {

                    //You have exceeded maximum login retries<br /> Please try after 3 minute(s)
                    let initSub = (content["message"].indexOf("try after")) + 10;
                    let endSub = (content["message"].indexOf(" minute(s)"));
                    let minutes = content["message"].substring(initSub, endSub);
                    message.value = 'Conta bloqueada. Aguardar ' + minutes + ' minuto(s)';
                }
            }
        }

        return {
            data,
            submit,
            loading,
            message,
            router,
            viewPass
        }
    }
}
</script>

<style scoped>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
</style>

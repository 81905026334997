<template>
<!-- <Nav/> -->
  <v-app>
    <v-layout>
    <Nav/>
    <v-main>
      <v-sheet
        id="scrolling-techniques-2"
        class="overflow-y-auto"
        color="#F1F1F1"
        style="height: calc(100vh - 64px);"
      >
      <v-container>
      <router-view/>
      </v-container>
      </v-sheet>
    </v-main>
    </v-layout>
  </v-app>
</template>

<script>
import Nav from '@/components/Nav.vue';

export default {

  name: 'App',
  components: {
    Nav  //instantiate it here
  }
}
</script>

<style>

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.v-container.answer_button{
  margin-right: 20px;
  margin-left: 20px;
}

.scrollable-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: scroll;
}

body{
  background: #F1F1F1;
}
</style>



<template>
    <ShopMHSuper v-if="showMHPurchaseConfirmation===false" @purchase-confirmation="showPurchaseConfirmationMessage"/>
    <MHPurchaseConfirmation v-if="showMHPurchaseConfirmation===true" />
</template>

<script>
import ShopMHSuper from "@/components/ShopMHSuper.vue";
import MHPurchaseConfirmation from "@/components/MHPurchaseConfirmation.vue";

import { ref } from "vue";

export default {
    components: {
        ShopMHSuper,
        MHPurchaseConfirmation
  },
  setup(){
    const showMHPurchaseConfirmation = ref(false);
    
    return {
      showMHPurchaseConfirmation
    };
  },
  methods: {
    showPurchaseConfirmationMessage() {
        this.showMHPurchaseConfirmation = true;
    }
  },
}
</script>

<style scoped>

</style>
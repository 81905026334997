<template>
<div class="form-signin">
    <form @submit.prevent="submit">

        <h3 class="h3 mb-6 fw-normal">Validar conta</h3>

        <div class="form-floating">
        <input v-model="data.code" type="text" class="form-control" placeholder="code">
        <label>Código de Validação</label>
        </div>
        <button class="mt-1 w-100 btn btn-primary text-light" type="submit">Validar</button>
        <div id='status-msg' class="login-msg my-4">{{message}}</div>
    </form>
</div>
</template>

<script> 
import {reactive} from 'vue';   // Import reactive
import {useRouter} from "vue-router";
//import { useCookies } from 'vue3-cookies';
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "Validate",
    setup() {
        const data = reactive({
            code: ''
        });

        const router = useRouter();
        //const { cookies } = useCookies();
        const message = ref(' ');
        const store = useStore();

        store.dispatch('setCurrentScreen', 'Validate');
        const submit = async() => {
            
            //Validate fields
            message.value = '';


            const response = await fetch(store.state.projectConfigs.siteUrl + '/wp-json/jwt-auth/v1/validate_user', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                credentials: 'include',
                body: JSON.stringify(data)
            });

            const content = await response.json();

            // If status success (code 200)
            if(response.status != 200){                 
                message.value = content;
                
            }
            else{
                //TODO: pop-up validado e ir para o login
                document.getElementById('status-msg').style.color = "green";
                message.value = content;
                setTimeout(() => {router.push("/login");}, 5000);
                
            }

        }
        return {
            data,
            submit,
            message
        }
    }
}
</script>

<style scoped>
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}

.login-msg {
    color: red;
    height: 15px;
    max-height: 15px;
}

</style>

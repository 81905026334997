<template>
  <v-container grid-list-md text-xs-center fluid align="center">
    <v-card
      class="overflow-y-auto"
      max-width="600px"
      align="left"
      :max-height="height - 280"
    >
      <!--<v-card class="overflow-y-auto" max-width="600px" align ="left" :max-height="550">-->
      <v-card-text>{{ clarifyData.question }}</v-card-text>

      <div class="container">
        <table class="table">
          <thead>
            <!-- Table Head is only used to create black line on top of first row -->
            <tr>
              <th colspan="2">Solução</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style="vertical-align: middle; font-size: 14px; hyphens: none"
              >
                {{ clarifyData.answer }}
              </td>
            </tr>
          </tbody>
        </table>

        <table class="table">
          <thead>
            <!-- Table Head is only used to create black line on top of first row -->
            <tr>
              <th colspan="2">Explicação</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style="vertical-align: middle; font-size: 14px; hyphens: none"
              >
                <!--Lorem ipsum dolor sit amet, semper quis, sapien id natoque elit. Nostra urna at, magna at neque sed sed ante imperdiet, dolor mauris cursus velit, velit non, sem nec. Volutpat sem ridiculus placerat leo, augue in, duis erat proin condimentum in a eget, sed fermentum sed vestibulum varius ac, vestibulum volutpat orci ut elit eget tortor. Ultrices nascetur nulla gravida ante arcu. Pharetra rhoncus morbi ipsum, nunc tempor debitis, ipsum pellentesque, vitae id quam ut mauris dui tempor, aptent non. Quisque turpis. Phasellus quis lectus luctus orci eget rhoncus. Amet donec vestibulum mattis commodo, nulla aliquet, nibh praesent, elementum nulla. Sit lacus pharetra tempus magna neque pellentesque, nulla vel erat. -->
                {{ clarifyData.clarify }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-card>
  </v-container>

  <v-container grid-list-md text-xs-center fluid align="center">
    <!--<v-card class="overflow-y-auto" max-width="600px" align ="center" max-height="150px">-->

    <v-container grid-list-md text-xs-center fluid align="center">
      <v-btn @click="$router.push('/')" color="primary" elevation="2">
        Continuar <v-icon>mdi-skip-next</v-icon>
      </v-btn>
    </v-container>
    <!--</v-card>-->
  </v-container>

  <!-- Colocar botão continuar para ir para o home -->

  <v-overlay
    :model-value="clarifyData.loading"
    class="align-center justify-center"
  >
    <v-progress-circular indeterminate size="64"> </v-progress-circular>
  </v-overlay>
</template>

<script>
//import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { onMounted, reactive } from "vue";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";

export default {
  setup() {
    const clarifyData = reactive({
      statusCode: null,
      statusMessage: null,
      userAccountType: null,
      numClarifyRequests: null,
      idExercise: null,
      question: null,
      answer: null,
      clarify: null,
      loading: false,
    });

    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    let id = route.query["id"];

    //-----|STATUS|-----
    clarifyData.statusCode = null;
    clarifyData.statusMessage = null;
    //-----|CLARIFY REQUESTS CONTROL|-----
    clarifyData.userAccountType = null;
    clarifyData.numClarifyRequests = null;
    //-----|CLARIFY CLARIFICATION|-----
    clarifyData.idExercise = null;
    clarifyData.question = null;
    clarifyData.answer = null;
    clarifyData.clarify = null;

    clarifyData.loading = true;

    const { cookies } = useCookies();

    const postData = reactive({
      idClarifyRequest: id,
    });


    onMounted(async () => {
      try {
        const response = await fetch(
          store.state.projectConfigs.siteUrl +
            "/wp-json/jwt-auth/v1/clarify-reply",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
            body: JSON.stringify(postData),
          }
        );

        const content = await response.json();
        if (
          response.status == 200 ||
          response.status == 201 ||
          response.status == 202
        ) {
          //loading = false;  // Stop overlay loading animation

          //-----|STATUS|-----
          clarifyData.statusCode = content["statusCode"];
          clarifyData.statusMessage = content["statusMessage"];
          //-----|CLARIFY REQUESTS CONTROL|-----
          clarifyData.userAccountType = content["userAccountType"];
          clarifyData.numClarifyRequests = content["numClarifyRequests"];
          //-----|EXERCISE CLARIFICATION|-----
          clarifyData.idExercise = content["idExercise"];
          clarifyData.question = content["question"];
          clarifyData.answer = content["answer"];
          clarifyData.clarify = content["clarify"];

          clarifyData.loading = false;

          window.MathJax = {
            tex: {
              inlineMath: [
                ["$", "$"],
                ["\\(", "\\)"],
                ["!!", "!!"],
              ],
            },
          };

          (function () {
            var script = document.createElement("script");
            script.src =
              "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js";
            script.async = true;
            document.head.appendChild(script);
          })();
        }
        if(response.status == 403){
          await store.dispatch("setAuth", false);
          await router.push("/login");
          clarifyData.loading = false;
        }
      } catch (e) {
        clarifyData.loading = false;
      }
    });
    return {
      id,
      clarifyData,
    };
  },
  methods: {
    mathjax_loader() {
      window.MathJax = {
        tex: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
            ["!!", "!!"],
          ],
        },
      };

      (function () {
        var script = document.createElement("script");
        script.src = "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js";
        script.async = true;
        document.head.appendChild(script);
      })();
    },
  },
};
</script>

<style scoped>
</style>
<template>
    <div>
    <v-container grid-list-md text-xs-center fluid align="center">
      <v-card max-width="600" align ="left">
        <v-card-title></v-card-title>
        <v-container fluid >
          <!--<div class="cat-checkbox" v-for="cat in catconfigs" :key="cat.id">
            <v-checkbox v-model="cat.active" :label="$t(cat.name)" hide-details> 
            </v-checkbox>
          </div>-->
          <!--<v-btn @click="save_cat" :color = "button_color" elevation = "2"> {{save_button}} </v-btn>-->
          <v-expansion-panels>
          <v-expansion-panel v-for="ex in exercises" :key="ex.id">
            <v-expansion-panel-title @click="$render_mathjax()">
              <b> ID: </b> {{ex.id}} | <b> Tema: </b> {{ex.categoryCode}} | <b> Criado em: </b> {{ex.timeCreated}}
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <p>{{ex.question}}</p>
              <v-btn class="ma-2" color="primary" @click="edit_exercise(ex.id)" dark>
                <v-icon dark>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        </v-container>
       </v-card>
    </v-container>

    <v-overlay :model-value="loading || save" class="align-center justify-center">
      <v-progress-circular
        indeterminate
        size="64">
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { onMounted, ref, reactive} from "vue";
import { useRouter } from "vue-router";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";
export default {
  name: "LoadQuestions",
   data: () => ({
      save: false,
      save_button: 'Guardar',
      button_color: 'light'
  }),
  setup() {
    const router = useRouter();
    let loading = ref(true);
    const exercises = reactive([]);
    const store = useStore();
    const { cookies } = useCookies();
    store.dispatch('setCurrentScreen', 'Editar Exercícios');
    let siteUrl = store.state.projectConfigs.siteUrl;
    onMounted(async () => {
      // Authenticate
      try {
        const response = await fetch(

          siteUrl + "/wp-json/jwt-auth/v1/questions",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + cookies.get("jwt"),
            },
          }
        );
        
        if (response.status == 200) {
          await store.dispatch("setAuth", true);
          const exs_response = await fetch(
            store.state.projectConfigs.siteUrl + "/wp-json/jwt-auth/v1/get-edit-exercises",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + cookies.get("jwt"),
              },  
            }
          );
          const exs_content = await exs_response.json();

          if (exs_response.status == 200) {
            loading.value = false;
          
          /* -------------------------------------------------------------------------- */

            for (let i=0; i < exs_content.length; i++){
              let idExercise = exs_content[i]['idExercise'];
              let timeCreated = exs_content[i]['timeCreated'];
              let question = exs_content[i]['question'];
              let categoryCode = exs_content[i]['description'];
              
              exercises.push({id: idExercise, timeCreated: timeCreated, question: question, categoryCode: categoryCode});
            }
          
          /* -------------------------------------------------------------------------- */
          
          }

                   

        } else if (response.status == 403) {
          await store.dispatch("setAuth", false);
          await router.push("/login");
        }
      } catch (e) {
        await store.dispatch("setAuth", false);
      }
    });
    return {
      loading,
      exercises,
      siteUrl,
      cookies
    };
  },
  methods: {
    $t(value) {
      console.log(value);
    },
    refesh_checkbox(){
      return this.catconfigs.name;
    },
    edit_exercise($id){
      this.$router.push({name: 'edit', params: { id: $id}});
    },
    $render_mathjax(){
          window.MathJax = {
        tex: {
          inlineMath: [['$', '$'], ['\\(', '\\)'],['!!', '!!']]
        }
      };
      
      (function () {
        var script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js';
        script.async = true;
        document.head.appendChild(script);
      })();
    }

  }
    
};
</script>